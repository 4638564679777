import { OutputEmitterRef } from '@angular/core';
import { vec2 } from '@tlaukkan/tsm';
import { fromEvent, Subject, merge, combineLatest, of } from 'rxjs';
import {
  filter,
  takeUntil,
  finalize,
  tap,
  switchMap,
  map,
} from 'rxjs/operators';
import { visiblePageTimer } from '@/utils/page-visibility';

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export const ZERO_SPEED_HEIGHT_FRACTION = 0.75;
const STATIC_CONTROL_UPDATE_FREQUENCY = 10.0; //Hz

export type MouseDriveEvent = {
  readonly speedVector: vec2;
};

function forwardYFraction(y: number) {
  return clamp(1 - y / ZERO_SPEED_HEIGHT_FRACTION, -1, 1);
}

const stopDriveEvent = {
  speedVector: new vec2([0, 0]),
};

function isLeftButtonPressed(event: PointerEvent) {
  return event.buttons === 1;
}

export class MouseCanvasDriveEventManager {
  mouseMove: MouseDriveEvent = stopDriveEvent;
  private destroy$ = new Subject();

  constructor(
    private readonly canvas: HTMLCanvasElement,
    private readonly mouseDriveEvent: OutputEmitterRef<MouseDriveEvent>,
  ) {
    const leftMouseButtonDownEvent$ = fromEvent<PointerEvent>(
      this.canvas,
      'pointerdown',
    ).pipe(
      filter((mouseEvent) => isLeftButtonPressed(mouseEvent)),
      tap((mouseEvent) => {
        this.canvas.setPointerCapture(mouseEvent.pointerId);
        this.onMouseMove(mouseEvent);
        this.mouseDriveEvent.emit(this.mouseMove);
      }),
    );

    const ctrlKeyPressed$ = merge(
      fromEvent(document, 'keydown').pipe(
        map((event) => (event as KeyboardEvent).ctrlKey),
      ),
      fromEvent(document, 'keyup').pipe(
        map((event) => (event as KeyboardEvent).ctrlKey),
      ),
      of(false),
    ).pipe(takeUntil(this.destroy$));

    const leftButtonPressed$ = merge(
      leftMouseButtonDownEvent$,
      fromEvent<PointerEvent>(document, 'pointerup'),
    ).pipe(
      takeUntil(this.destroy$),
      map((event) => isLeftButtonPressed(event)),
    );

    const controlAllowed$ = combineLatest(
      ctrlKeyPressed$,
      leftButtonPressed$,
    ).pipe(
      map(
        ([ctrlKeyPressed, leftButtonPressed]) =>
          ctrlKeyPressed || leftButtonPressed,
      ),
    );

    const controlEvents$ = merge(
      fromEvent<PointerEvent>(document, 'pointermove'),
      visiblePageTimer(0, 1000 / STATIC_CONTROL_UPDATE_FREQUENCY),
    ).pipe(
      takeUntil(this.destroy$),
      takeUntil(
        controlAllowed$.pipe(filter((controlAllowed) => !controlAllowed)),
      ),
      takeUntil(fromEvent(window, 'blur')),
      finalize(() => this.stopMouseControl()),
    );

    leftMouseButtonDownEvent$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => controlEvents$),
      )
      .subscribe((event) => {
        if (event instanceof MouseEvent) {
          this.onMouseMove(event);
        }
        this.mouseDriveEvent.emit(this.mouseMove);
      });
  }

  private stopMouseControl() {
    this.mouseMove = stopDriveEvent;
    this.mouseDriveEvent.emit(this.mouseMove);
  }

  private onMouseMove(mouseEvent: MouseEvent) {
    const boundingRect = this.canvas.getBoundingClientRect();
    const xInPx = mouseEvent.clientX - boundingRect.left;
    const yInPx = mouseEvent.clientY - boundingRect.top;
    const x = xInPx / boundingRect.width;
    const y = yInPx / boundingRect.height;

    this.mouseMove = {
      speedVector: new vec2([
        clamp(x * 2 - 1, -1, 1),
        y < ZERO_SPEED_HEIGHT_FRACTION ? forwardYFraction(y) : 0,
      ]),
    };
  }

  destroy() {
    this.destroy$.next(undefined);
  }
}
