import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MapChangesetInfo } from '@cartken/map-types';
import { CdkScrollable } from '@angular/cdk/scrolling';
import {
  MatSelectionList,
  MatListOption,
  MatListItemTitle,
} from '@angular/material/list';

import { MatButton } from '@angular/material/button';

export interface LoadChangesetDialogData {
  changesets: MapChangesetInfo[];
}

@Component({
  selector: 'app-load-changeset-dialog',
  templateUrl: 'load-changeset-dialog.component.html',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatSelectionList,
    MatListOption,
    MatListItemTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class LoadChangesetDialogComponent {
  showCommittedChangesets = false;

  constructor(
    public dialogRef: MatDialogRef<LoadChangesetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LoadChangesetDialogData,
  ) {
    data.changesets = data.changesets
      .filter((changeset) => changeset.committedAsVersion === undefined)
      .sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
  }
}
