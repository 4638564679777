import { MapElement } from '@cartken/map-types';

export function remapReferencedIds(
  mapElement: MapElement,
  idMap: Map<number, number>,
) {
  const { properties } = mapElement;
  if (properties && 'startNodeId' in properties) {
    properties.startNodeId =
      idMap.get(properties.startNodeId) ?? properties.startNodeId;
  }
  if (properties && 'endNodeId' in properties) {
    properties.endNodeId =
      idMap.get(properties.endNodeId) ?? properties.endNodeId;
  }
  if (
    properties &&
    'infrastructureId' in properties &&
    properties.infrastructureId
  ) {
    properties.infrastructureId =
      idMap.get(properties.infrastructureId) ?? properties.infrastructureId;
  }
  if (
    properties &&
    'parentInfrastructureId' in properties &&
    properties.parentInfrastructureId
  ) {
    properties.parentInfrastructureId =
      idMap.get(properties.parentInfrastructureId) ??
      properties.parentInfrastructureId;
  }
  if (properties && 'mutexIds' in properties && properties.mutexIds?.length) {
    properties.mutexIds = properties.mutexIds.map(
      (mutexId) => idMap.get(mutexId) ?? mutexId,
    );
  }
  if (
    properties &&
    'crossing' in properties &&
    properties.crossing?.trafficLightIds?.length
  ) {
    properties.crossing.trafficLightIds =
      properties.crossing.trafficLightIds.map((id) => idMap.get(id) ?? id);
  }
}
