import { Component, Input, OnDestroy } from '@angular/core';
import { RobotActionRequestNotification } from '@/app/core/robots-service/local-logic/action-request-manager';
import { Subject, takeUntil } from 'rxjs';
import { RobotCommunication } from '@/app/core/robots-service/robot-communication';
import { VoidAsyncFunction } from '@/utils/type-utils';
import { updateActiveNotificationIndex } from '@/app/supervised-robots/robot-supervision/update-active-notification-index';

import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { DurationPipe } from '@/app/core/pipes/duration.pipe';

export type NotificationState = {
  activeNotificationIndex: number;
  notificationCount: number;
  message: string;
  buttonLabel: string;
  onButtonClicked: VoidAsyncFunction;
};

@Component({
  selector: 'app-action-notification',
  templateUrl: './action-notification.component.html',
  styleUrl: './action-notification.component.sass',
  imports: [MatIcon, MatButton, DurationPipe],
})
export class ActionNotificationComponent implements OnDestroy {
  private destroy = new Subject<void>();
  private _robotCommunication?: RobotCommunication;

  activeNotificationIndex = 0;
  notifications: RobotActionRequestNotification[] = [];

  @Input()
  set robotCommunication(value: RobotCommunication | undefined) {
    if (!value) {
      this._robotCommunication = undefined;
      this.activeNotificationIndex = 0;
      this.notifications = [];
      return;
    }
    this._robotCommunication = value;
    value.robotActionRequestNotification$
      .pipe(takeUntil(this.destroy))
      .subscribe((notification) => {
        this.activeNotificationIndex = updateActiveNotificationIndex(
          this.notifications,
          notification,
          this.activeNotificationIndex,
        );
        this.notifications = notification;
      });
  }

  onClick() {
    const actionNotification = this.notifications[this.activeNotificationIndex];
    if (actionNotification) {
      this._robotCommunication?.onActionClicked(actionNotification);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  changeActiveNotificationIndex(shiftDirection: number) {
    const activeNotificationIndex =
      this.activeNotificationIndex + shiftDirection;
    this.activeNotificationIndex = Math.max(
      0,
      Math.min(activeNotificationIndex, this.notifications.length - 1),
    );
  }
}
