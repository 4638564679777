import { v } from '@/valibot';
import { IsoDateTime } from '@cartken/map-types';

export type FrameDto = v.InferOutput<typeof FrameDto>;
export const FrameDto = v.looseObject({
  id: v.number(),
  url: v.string(),
  timestamp: IsoDateTime,
  snippetId: v.number(),
  randomOrderingValue: v.number(),
});

export type SnippetDto = v.InferOutput<typeof SnippetDto>;
export const SnippetDto = v.looseObject({
  id: v.number(),
  robotSerialNumber: v.number(),
  bagVersion: v.optional(v.string()),
  startDate: IsoDateTime,
  endDate: IsoDateTime,
  cameraIntrinsicsFront: v.optional(v.string()),
  tileUrl: v.string(),
  videoUrl: v.string(),
  randomOrderingValue: v.number(),
});

export const DatasetDto = v.object({
  id: v.number(),
  name: v.string(),
});

export type CollectionDto = v.InferOutput<typeof CollectionDto>;
export const CollectionDto = v.object({
  id: v.number(),
  creationTimestamp: IsoDateTime,
  name: v.string(),
  locked: v.boolean(),
});

export type DatasetSyncConfigDto = v.InferOutput<typeof DatasetSyncConfigDto>;
export const DatasetSyncConfigDto = v.object({
  id: v.number(),
  datasetName: v.string(),
  labelTypeId: v.number(),
  collectionId: v.number(),
});

export type LabelTypeDto = v.InferOutput<typeof LabelTypeDto>;
export const LabelTypeDto = v.object({
  id: v.number(),
  name: v.string(),
  creationTimestamp: IsoDateTime,
});
