import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
} from '@angular/material/dialog';
import { BackendService } from '../../../app/core/backend.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom, retry } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

const SNACK_BAR_MSG_TIMEOUT = 3 * 1000;

export interface CancelOrderDialogData {
  orderId: string;
}

export interface CancelOrderDialogOutput {
  isDeleted?: boolean;
}

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrl: './cancel-order-dialog.component.sass',
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatButton,
  ],
})
export class CancelOrderDialogComponent {
  cancelReason = '';
  cancelMessage: string;

  constructor(
    public dialogRef: MatDialogRef<
      CancelOrderDialogComponent,
      CancelOrderDialogOutput
    >,
    @Inject(MAT_DIALOG_DATA) public data: CancelOrderDialogData,
    private backendService: BackendService,
    private snackBar: MatSnackBar,
  ) {
    this.cancelMessage = `Enter reason for canceling of the order ${this.data.orderId}`;
  }

  async confirmCancel() {
    try {
      await firstValueFrom(
        this.backendService
          .post(`/orders/${this.data.orderId}/cancel`, {
            reason: this.cancelReason,
          })
          .pipe(retry(5)),
      );
      this.snackBar.open(`Order ${this.data.orderId} is canceled`, undefined, {
        verticalPosition: 'top',
        duration: SNACK_BAR_MSG_TIMEOUT,
      });
      this.dialogRef.close({ isDeleted: true });
      return;
    } catch (e) {
      console.error('Failed to cancel order');
      this.snackBar.open(
        `Failed to cancel order ${this.data.orderId}`,
        undefined,
        { verticalPosition: 'top', duration: SNACK_BAR_MSG_TIMEOUT },
      );
      this.dialogRef.close({ isDeleted: false });
      return;
    }
  }

  close() {
    this.dialogRef.close({ isDeleted: false });
  }
}
