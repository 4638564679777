import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-handover-location-title-splitter',
  templateUrl: './handover-location-title-splitter.component.html',
  styleUrl: './handover-location-title-splitter.component.sass',
})
export class HandoverLocationTitleSplitterComponent {
  @Input()
  title!: string;
}
