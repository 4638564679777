import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';

import { RobotCommunication } from '@/app/core/robots-service/robot-communication';
import { NetworkInterfaceStats } from '@/app/core/robots-service/webrtc/signaling-server-messages';
import { map, takeUntil } from 'rxjs/operators';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrl: './data-upload.component.css',
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    MatButton,
    AsyncPipe,
    DecimalPipe,
  ],
})
export class DataUploadComponent implements OnDestroy {
  ifaceList$?: Observable<NetworkInterfaceStats[]>;

  @ViewChild('dataUploadInterfaceSelection')
  interfaceSelector?: MatSelect;

  @ViewChild('dataUploadDataAmount')
  dataAmountMbField!: ElementRef;

  dataBudgetMb = 0;

  @Input()
  set robotCommunication(robotCommunication: RobotCommunication | undefined) {
    this.updateRobotCommunication(robotCommunication);
  }
  get robotCommunication(): RobotCommunication | undefined {
    return this._robotCommunication;
  }
  private _robotCommunication?: RobotCommunication;
  private readonly unsubscribeRobotConnection$ = new Subject<void>();

  ngOnDestroy() {
    this.updateRobotCommunication(undefined);
  }

  private updateRobotCommunication(robotCommunication?: RobotCommunication) {
    if (robotCommunication === this.robotCommunication) {
      return;
    }
    this.unsubscribeRobotConnection$.next(undefined);
    this._robotCommunication = robotCommunication;
    if (!this.robotCommunication) {
      return;
    }

    this.ifaceList$ = this.robotCommunication?.availableNetworkInterfaces$.pipe(
      map((ifaceList) =>
        ifaceList.filter((iface) => iface.interfaceName != 'eth0.4'),
      ),
      takeUntil(this.unsubscribeRobotConnection$),
    );
  }

  onAddDataBudget() {
    if (!this.interfaceSelector || this.dataBudgetMb === 0) {
      return;
    }

    const networkInterface = this.interfaceSelector.value;

    if (!networkInterface) {
      return;
    }
    const bytes = this.dataBudgetMb * 1e6;
    this.robotCommunication?.addDataUploaderDataBudget(networkInterface, bytes);
  }

  onChangeDataBudgetMb(dataBudgetMb: string) {
    const parsedDataBudgetMb = parseInt(dataBudgetMb);
    if (Number.isNaN(parsedDataBudgetMb)) {
      this.dataBudgetMb = 0;
      return;
    }
    this.dataBudgetMb = parsedDataBudgetMb;
  }

  onResetDataBudget() {
    const networkInterface = this.interfaceSelector?.value;
    if (!networkInterface) {
      return;
    }
    this.robotCommunication?.resetDataUploaderDataBudget(networkInterface);
  }

  getInterfaceComposedName(iface: NetworkInterfaceStats): string {
    if (iface.interfaceDisplayName) {
      return iface.interfaceName + ': ' + iface.interfaceDisplayName;
    }
    return iface.interfaceName;
  }
}
