<app-toolbar>
  <ng-content ngProjectAs="menu">
    <button mat-menu-item [routerLink]="'/robot-operators-overview'">
      Back to Operators View
    </button>
  </ng-content>
</app-toolbar>

<div class="container">
  <div>
    <h1>Blackbox Aggregation for {{ user?.displayName }}</h1>
    <div class="container">
      <mat-form-field appearance="fill">
        <mat-label>Time Zone</mat-label>
        <mat-select [(value)]="selectedTimeZone">
          <mat-option />
          @for (timeZone of supportedTimeZones; track timeZone) {
            <mat-option [value]="timeZone">
              {{ timeZone }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field style="margin-left: 10px">
        <mat-label>Start Time</mat-label>
        <input
          matInput
          type="datetime-local"
          step="1"
          [ngModel]="blackboxRequestStartTime"
          (ngModelChange)="blackboxRequestStartTime = $event"
        />
      </mat-form-field>

      <mat-form-field style="margin-left: 10px">
        <mat-label>End Time</mat-label>
        <input
          matInput
          type="datetime-local"
          step="1"
          [ngModel]="blackboxRequestEndTime"
          (ngModelChange)="blackboxRequestEndTime = $event"
        />
      </mat-form-field>

      <button
        mat-button
        (click)="requestAggregation()"
        style="margin-left: 10px"
      >
        Request aggregation
      </button>

      <button mat-icon-button (click)="onUpdate()" style="margin-left: 10px">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div class="content">
    <div class="aggregations">
      @for (aggregation of aggregations; track aggregation) {
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>
              {{ formatDateInTimezone(aggregation.aggregationStartTime) }}
              <br />
              to <br />
              {{ formatDateInTimezone(aggregation.aggregationEndTime) }}
              <br />
              Requested at
              {{ formatDateInTimezone(aggregation.aggregationRequestTime) }}
            </mat-card-title>
            <mat-card-subtitle
              >{{ aggregation.aggregatedBlackboxRecordings?.length }}
              recordings

              {{ countAvailableVideos(aggregation) }} available <br />
              {{ countUploadingVideos(aggregation) }} uploading <br />
              {{ countRobots(aggregation) }} robots <br />
            </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content> </mat-card-content>
          <mat-card-actions>
            <button mat-button (click)="onPlayClick(aggregation)">Play</button>
          </mat-card-actions>
        </mat-card>
      }
    </div>

    <mat-divider vertical />
    <div class="video-container">
      <video
        id="video"
        autoplay
        muted
        style="max-width: 890px"
        controls
      ></video>
      @if (activeAggregation !== undefined) {
        <div>
          <div>
            {{ activeVideoIndex + 1 }} of
            {{ activeAggregation.aggregatedBlackboxRecordings?.length }}

            <button
              mat-icon-button
              (click)="nextVideoInAggregation()"
              style="margin-left: 10px"
            >
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>

            <button
              mat-icon-button
              (click)="previousVideoInAggregation()"
              style="margin-left: 10px"
            >
              <mat-icon>arrow_back_ios</mat-icon>
            </button>

            <button mat-button (click)="speed1x()" style="margin-left: 10px">
              1x
            </button>
            <button mat-button (click)="speed2x()" style="margin-left: 10px">
              2x
            </button>
            <button mat-button (click)="speed4x()" style="margin-left: 10px">
              4x
            </button>

            <button mat-button (click)="speed8x()" style="margin-left: 10px">
              8x
            </button>
          </div>
          @let activeVideo =
            activeAggregation.aggregatedBlackboxRecordings?.[activeVideoIndex];
          @if (activeVideo) {
            <div>
              Operation Id
              {{ activeVideo.operationId }}
            </div>
            <div>
              Duration
              {{ activeVideo.duration }}
            </div>
            <div>
              {{ activeVideo.robotDisplayName }}
            </div>

            <div>
              Crossing Id
              {{ activeVideo.crossingId }}
            </div>
            <div>
              Supervisor Id Sequence
              {{ activeVideo.supervisorIdSequence }}
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
