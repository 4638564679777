import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { MatIcon } from '@angular/material/icon';

export interface OpenCloseAnimationDialogData {
  animationUrl: string;
}

@Component({
  selector: 'open-close-animation-dialog',
  templateUrl: './open-close-animation-dialog.component.html',
  styleUrl: './open-close-animation-dialog.component.sass',
  imports: [MatIcon],
})
export class OpenCloseAnimationDialogComponent {
  @Input()
  videoPath!: string;

  @Input()
  fallbackMsg!: string;

  @Input()
  initialFrameTime!: number;

  @ViewChild('videoContainer') videoContainer?: ElementRef<HTMLVideoElement>;

  get videoUrlMp4(): string {
    return `${this.videoPath}.mp4`;
  }

  get videoUrlWebm(): string {
    return `${this.videoPath}.webm`;
  }

  isPaused = false;

  videoInit(): void {
    this.playVideo();
  }

  async playVideo(): Promise<void> {
    const videoContainer = this.videoContainer?.nativeElement;
    if (videoContainer !== undefined) {
      try {
        videoContainer.blur();
        await videoContainer.play();
        this.isPaused = videoContainer.paused;
      } catch (e) {
        this.isPaused = true;
        videoContainer.currentTime = this.initialFrameTime;
        // after play is not possible if user did not interact with page
      }
    }
  }
}
