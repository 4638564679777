import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import {
  Operation,
  PinCodeStrategy,
  OrderSchedulerMode,
  Station,
  DisposalOptions,
  DisposalMode,
  CorridorAlignmentPreference,
  OperationType,
  WeeklySchedule,
} from '../operation';
import { UpdateOperation } from '../update-operation';
import { BackendService } from '@/app/core/backend.service';
import {
  ElementType,
  RobotQueueEdgeProperties,
  RobotQueueEdge,
} from '@cartken/map-types';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import moment from 'moment';
import { map } from 'rxjs';
import { v } from '@/valibot';
import { NgTemplateOutlet } from '@angular/common';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { AttributeListInputComponent } from '@/app/core/attribute-list-input/attribute-list-input.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { OperationsService } from '@/app/core/operations-service';
import { WeeklyScheduleEditorComponent } from '../weekly-schedule-editor/weekly-schedule-editor.component';

type EditOperationDialogData = {
  operation: Operation;
  updateOperation: UpdateOperation;
};

type StationWithQueueName = {
  station: Station;
  robotQueueEdgeName: string;
};

type RobotQueueName = {
  name: string | undefined;
  displayName: string | undefined;
};

@Component({
  selector: 'app-edit-operation-dialog',
  templateUrl: './edit-operation-dialog.component.html',
  styleUrl: './edit-operation-dialog.component.sass',
  imports: [
    MatSlideToggle,
    FormsModule,
    AttributeListInputComponent,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    NgTemplateOutlet,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatDivider,
    WeeklyScheduleEditorComponent,
  ],
})
export class EditOperationDialogComponent {
  readonly availablePinCodeStrategies = Object.values(PinCodeStrategy);
  readonly availableOrderSchedulerModes = Object.values(OrderSchedulerMode);
  readonly availableCorridorAlignmentPreferences = Object.values(
    CorridorAlignmentPreference,
  );
  readonly disposalModes = Object.values(DisposalMode);
  readonly neverDisposeMode = DisposalMode.NEVER;

  availableRobotQueues: RobotQueueName[] = [];
  availableRobotQueueNames: string[] = [];
  availableStations: StationWithQueueName[] = [];
  availableTags: string[] = [];
  availableRobots: Robot[] = [];

  busRouteIdToAdd = '';
  busRouteStationIdToAdd = '';

  allOperationAccessGroups: string[] = [];

  readonly timeZones = moment.tz.names();
  readonly DEFAULT_SCHEDULE: WeeklySchedule = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditOperationDialogData,
    private backendService: BackendService,
    private operationsService: OperationsService,
    private robotsBackendService: RobotsBackendService,
  ) {
    data.updateOperation = {
      updateOrderOperationData: {},
    };
    if (data.operation.operationType === OperationType.OrderOperation) {
      data.updateOperation.updateOrderOperationData = {};
      this.getOrCreateDisposalOptions();
    }

    const requestPath = `/map?element-types=${ElementType.ROBOT_QUEUE_EDGE}`;
    const boundingPolygon = data.operation.operationRegion?.coordinates;
    const boundsQuery = boundingPolygon?.length
      ? `&region-polygon=${JSON.stringify(boundingPolygon)}`
      : '';

    this.backendService
      .get(requestPath + boundsQuery)
      .pipe(map((x) => v.parse(v.array(RobotQueueEdge), x)))
      .subscribe((robotEdgeQueues) => {
        if (data.operation.operationType === OperationType.OrderOperation) {
          const flatDisplayNames = robotEdgeQueues
            .filter((element) => !element.deleted)
            .flatMap((robotEdgeQueue) =>
              this.flattenDisplayNames(robotEdgeQueue.properties),
            );
          this.availableRobotQueues = flatDisplayNames.sort((q1, q2) => {
            if (!q1.name || !q2.name) {
              return 1;
            }
            if (q1.displayName && !q2.displayName) {
              return 1;
            }
            if (q2.displayName && !q1.displayName) {
              return -1;
            }
            if (q1.name === q2.name && q1.displayName && q2.displayName) {
              return q1.displayName.localeCompare(q2.displayName, undefined, {
                sensitivity: 'base',
              });
            }
            return q1.name.localeCompare(q2.name);
          });
          this.availableRobotQueueNames = [
            ...new Set(
              this.availableRobotQueues
                .map((robotQueue) => robotQueue.name)
                .filter((x) => x !== undefined),
            ),
          ];
        } else {
          for (const robotQueueEdge of robotEdgeQueues.filter(
            (element) => !element.deleted,
          )) {
            for (const name of robotQueueEdge.properties.names) {
              this.availableStations.push({
                station: {
                  robotQueueEdgeId: robotQueueEdge.id,
                  waitingDuration: 5,
                },
                robotQueueEdgeName: name,
              });
            }
          }
          this.availableStations.sort((q1, q2) => {
            if (!q1.robotQueueEdgeName || !q2.robotQueueEdgeName) {
              return 1;
            }
            if (q1.robotQueueEdgeName && !q2.robotQueueEdgeName) {
              return 1;
            }
            if (q2.robotQueueEdgeName && !q1.robotQueueEdgeName) {
              return -1;
            }
            return q1.robotQueueEdgeName.localeCompare(q2.robotQueueEdgeName);
          });
        }
      });

    this.robotsBackendService.getRobots().subscribe((robots) => {
      this.availableRobots = robots;
    });

    this.operationsService.getAccessGroups().subscribe((accessGroups) => {
      this.allOperationAccessGroups = accessGroups;
    });
  }

  compareRobotQueueHandovers(q1: RobotQueueName, q2: RobotQueueName) {
    return q1.name === q2.name;
  }

  flattenDisplayNames(
    robotQueueEdge: RobotQueueEdgeProperties,
  ): RobotQueueName[] {
    if (!robotQueueEdge.displayNames?.length) {
      return [
        {
          name: robotQueueEdge.names[0],
          displayName: undefined,
        },
      ];
    }
    return robotQueueEdge.displayNames.map((h) => {
      return {
        name: robotQueueEdge.names[0],
        displayName: h,
      };
    });
  }

  updateSurveyUrl(url: string) {
    this.data.updateOperation.updateOrderOperationData.surveyUrl = url;
  }

  updateSupportPhoneNumber(phoneNumber: string) {
    this.data.updateOperation.updateOrderOperationData.supportPhoneNumber =
      phoneNumber || null;
    // || null is necessary here since empty string would not pass validation from class-validator
    // and undefined would no be skipped by JSON.stringify -> patch body would not contain the change
  }

  updatePreferredCountryCodes(preferredCountryCodes: string) {
    this.data.updateOperation.updateOrderOperationData.preferredCountryCodes =
      preferredCountryCodes
        .split(',')
        .map((countryCode) => countryCode.replace(' ', ''))
        .filter((v) => v !== '') || [];
  }

  updateDefaultWebhookUrl(url: string) {
    this.data.updateOperation.updateOrderOperationData.defaultWebhookUrl = url;
  }

  updateQueues() {
    this.data.updateOperation.updateOrderOperationData.waitingQueues =
      this.data.operation.operationData?.waitingQueues;

    this.data.updateOperation.updateOrderOperationData.pickups =
      this.data.operation.operationData?.pickups;
    this.data.updateOperation.updateOrderOperationData.dropoffs =
      this.data.operation.operationData?.dropoffs;
  }

  updateStorageLocationId() {
    this.data.updateOperation.updateOrderOperationData.storageLocationId =
      this.data.operation.operationData?.storageLocationId;
  }

  updateLocationConfiguration() {
    this.data.updateOperation.updateOrderOperationData.allowCustomPickupLocationsWithinBounds =
      this.data.operation.operationData?.allowCustomPickupLocationsWithinBounds;

    this.data.updateOperation.updateOrderOperationData.maxPickupLocationRefinementDistance =
      this.data.operation.operationData?.maxPickupLocationRefinementDistance;

    this.data.updateOperation.updateOrderOperationData.allowCustomDropoffLocationsWithinBounds =
      this.data.operation.operationData?.allowCustomDropoffLocationsWithinBounds;

    this.data.updateOperation.updateOrderOperationData.maxDropoffLocationRefinementDistance =
      this.data.operation.operationData?.maxDropoffLocationRefinementDistance;
  }
  updateWaitingDurations() {
    this.data.updateOperation.updateOrderOperationData.defaultPickupWaitingDurations =
      this.data.operation.operationData?.defaultPickupWaitingDurations;

    this.data.updateOperation.updateOrderOperationData.defaultDropoffWaitingDurations =
      this.data.operation.operationData?.defaultDropoffWaitingDurations;
  }

  updateCompartmentStrategies() {
    if (
      !this.data.updateOperation.updateOrderOperationData
        .pickupCompartmentStrategy
    ) {
      this.data.updateOperation.updateOrderOperationData.pickupCompartmentStrategy =
        {};
    }

    if (
      !this.data.updateOperation.updateOrderOperationData
        .dropoffCompartmentStrategy
    ) {
      this.data.updateOperation.updateOrderOperationData.dropoffCompartmentStrategy =
        {};
    }
    this.data.updateOperation.updateOrderOperationData.pickupCompartmentStrategy.pinCode =
      this.data.operation.operationData?.pickupCompartmentStrategy.pinCode;

    this.data.updateOperation.updateOrderOperationData.pickupCompartmentStrategy.pinCodeStrategy =
      this.data.operation.operationData?.pickupCompartmentStrategy.pinCodeStrategy;

    this.data.updateOperation.updateOrderOperationData.pickupCompartmentStrategy.openCompartmentOnArrival =
      this.data.operation.operationData?.pickupCompartmentStrategy.openCompartmentOnArrival;

    this.data.updateOperation.updateOrderOperationData.dropoffCompartmentStrategy.pinCode =
      this.data.operation.operationData?.dropoffCompartmentStrategy.pinCode;

    this.data.updateOperation.updateOrderOperationData.dropoffCompartmentStrategy.pinCodeStrategy =
      this.data.operation.operationData?.dropoffCompartmentStrategy.pinCodeStrategy;

    this.data.updateOperation.updateOrderOperationData.dropoffCompartmentStrategy.openCompartmentOnArrival =
      this.data.operation.operationData?.dropoffCompartmentStrategy.openCompartmentOnArrival;
  }

  private getOrCreateDisposalOptions(): DisposalOptions {
    const orderOperationData = this.data.operation.operationData;

    if (
      orderOperationData &&
      !orderOperationData?.disposalOptions?.disposalMode
    ) {
      orderOperationData.disposalOptions = {
        disposalMode: DisposalMode.NEVER,
      };
    }

    return (
      orderOperationData?.disposalOptions ?? {
        disposalMode: DisposalMode.NEVER,
      }
    );
  }

  updateDisposalLocation(disposeAtLocationId: string) {
    const disposalOptions = this.getOrCreateDisposalOptions();
    if (!disposeAtLocationId) {
      disposalOptions.disposeAtLocationId = null;
    } else {
      disposalOptions.disposeAtLocationId = disposeAtLocationId;
    }
    this.data.updateOperation.updateOrderOperationData.disposalOptions =
      disposalOptions;
  }

  updateDisposalMode(disposalMode: DisposalMode) {
    const disposalOptions = this.getOrCreateDisposalOptions();
    disposalOptions.disposalMode = disposalMode;
    this.data.updateOperation.updateOrderOperationData.disposalOptions =
      disposalOptions;
  }

  updateMessageConfiguration() {
    this.data.updateOperation.updateOrderOperationData.textMessageConfiguration =
      this.data.operation.operationData?.textMessageConfiguration;
  }

  updateOrderSchedulerMode() {
    this.data.updateOperation.updateOrderOperationData.orderSchedulerMode =
      this.data.operation.operationData?.orderSchedulerMode;
  }

  updateCorridorAlignmentPreference() {
    this.data.updateOperation.updateOrderOperationData.corridorAlignmentPreference =
      this.data.operation.operationData?.corridorAlignmentPreference;
  }

  updateMaxDrivingSecondsToPickups(maxDrivingSeconds: number | undefined) {
    this.data.updateOperation.updateOrderOperationData.maxDrivingSecondsToPickups =
      maxDrivingSeconds || 0;
  }

  updateMaxDrivingSecondsToDropoffs(maxDrivingSeconds: number | undefined) {
    this.data.updateOperation.updateOrderOperationData.maxDrivingSecondsToDropoffs =
      maxDrivingSeconds || 0;
  }

  getNamesForRobotId(robotId: string): string {
    const robot = this.availableRobots.find((r) => r.id === robotId);
    return robot
      ? `Cart ${robot.serialNumber} ( ${robot.shortName || ''})`
      : robotId;
  }
}
