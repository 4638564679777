import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  CreateRobotIssueDialogComponent,
  CreateRobotIssueError,
  RobotIssue,
} from '@/app/core/robot-issues';
import { BackendService } from '@/app/core/backend.service';
import {
  RobotsReportComponent,
  RobotsReportData,
} from './robots-report-dialog.componen';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { OperationsService } from '@/app/core/operations-service';

export interface CreateRobotAwxJobTracker {
  interfaceStrategy: string;
  robotId: string;
  awxJobTemplate?: AwxJobTemplate;
  versionTag?: string;
}

export interface MassActionData {
  selectedRobots: Robot[];
  updateState(): void;
}

export enum AwxJobTemplate {
  COMBINED_UPDATE = 'Robot | Update',
  UPDATE_JETSON = 'Jetson | Update',
  UPDATE_PI = 'Pi | Update',
}

export enum InterfaceStrategy {
  FASTEST = 'Fastest',
  PREFER_WIFI = 'PreferWifi',
  PREFER_MODEM1 = 'PreferModem1',
  PREFER_MODEM2 = 'PreferModem2',
  PREFER_MODEM3 = 'PreferModem3',
  FORCE_WIFI = 'ForceWifi',
  FORCE_MODEM1 = 'ForceModem1',
  FORCE_MODEM2 = 'ForceModem2',
  FORCE_MODEM3 = 'ForceModem3',
}

export class AwxUpdateData {
  availableJobTemplates: string[] = Object.values(AwxJobTemplate);
  selectedJobTemplate: AwxJobTemplate | undefined;
  availableInterfaceStrategies: string[] = Object.values(InterfaceStrategy);
  selectedInterfaceStrategy: InterfaceStrategy | undefined;
  versionTag: string | undefined;
}

class OperationIdSelection {
  operationIds: string[] = [];
  selectedOperationId?: string;
}

@Component({
  selector: 'app-mass-action-dialog',
  templateUrl: './mass-action-dialog.component.html',
  styleUrl: './mass-action-dialog.component.sass',
  imports: [
    MatButton,
    MatTooltip,
    MatIcon,
    MatDivider,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    FormsModule,
    MatMiniFabButton,
    MatDialogActions,
    MatDialogClose,
  ],
})
export class MassActionDialogComponent {
  awxUpdateData = new AwxUpdateData();
  operationIdSelection = new OperationIdSelection();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInput: MassActionData,
    private backendService: BackendService,
    private operationsService: OperationsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.operationsService.getOperations().subscribe((operations) => {
      this.operationIdSelection.operationIds = operations.map(
        (operation) => operation.id,
      );
    });
  }

  unlockRobots(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/open-compartments`, {})
        .toPromise();
    });
  }

  lockRobots(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/close-compartments`, {})
        .toPromise();
    });
  }

  readyForOrder(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/set-ready-for-orders`, {
          readyForOrders: true,
        })
        .toPromise();
    });
  }

  notReadyForOrder(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .post(`/robots/${robotId}/set-ready-for-orders`, {
          readyForOrders: false,
        })
        .toPromise();
    });
  }

  setOperationId(): void {
    this.forEachRobot(async (robotId) => {
      await this.backendService
        .patch(`/robots/${robotId}`, {
          assignedOperationId: this.operationIdSelection.selectedOperationId,
        })
        .toPromise();
    });
  }

  sendAwxLaunchCommand(): void {
    if (!this.launchUpdatePossible()) {
      return;
    }
    const body = {
      createRobotAwxJobTrackers: this.dialogInput.selectedRobots.map(
        (robot) => {
          return <CreateRobotAwxJobTracker>{
            robotId: robot.id,
            versionTag: this.awxUpdateData.versionTag,
            awxJobTemplate: this.awxUpdateData.selectedJobTemplate,
            interfaceStrategy: this.awxUpdateData.selectedInterfaceStrategy,
          };
        },
      ),
    };

    this.backendService.post('/awx/create-tracked-jobs', body).subscribe(() =>
      this.snackBar.open('Awx job tracker created!', 'Got it', {
        duration: 5000,
      }),
    );
  }

  launchUpdatePossible(): boolean {
    const { selectedJobTemplate, versionTag } = this.awxUpdateData;
    return !!selectedJobTemplate && !!versionTag;
  }

  openRobotsReport() {
    this.dialog.open<RobotsReportComponent, RobotsReportData>(
      RobotsReportComponent,
      {
        data: {
          selectedRobots: this.dialogInput.selectedRobots,
        },
      },
    );
  }
  createClickupTickets() {
    const snackOptions: MatSnackBarConfig = { verticalPosition: 'top' };

    this.dialog
      .open(CreateRobotIssueDialogComponent, {
        hasBackdrop: true,
        data: {
          robotIds: this.dialogInput.selectedRobots.map((robot) => robot.id),
        },
      })
      .afterClosed()
      .subscribe(
        async (
          robotIssues:
            | Promise<RobotIssue[] | CreateRobotIssueError[]>
            | undefined,
        ) => {
          if (!robotIssues) return;

          const loader = this.snackBar.open(
            'Submitting issue...',
            '',
            snackOptions,
          );

          try {
            const issues = await robotIssues;
            this.snackBar.open(
              `${issues.length} Issues have been reported!
              `,
              'Close',
              snackOptions,
            );
          } catch (thrown: HttpErrorResponse | unknown) {
            if (thrown instanceof HttpErrorResponse)
              this.snackBar.open(
                `Failed to report issues: ${
                  (thrown.error as { message: string }).message
                }`,
                'Close',
                snackOptions,
              );
          } finally {
            loader.dismiss();
          }
        },
      );
  }

  private forEachRobot(action: (robotId: string) => Promise<void>) {
    Promise.all(
      this.dialogInput.selectedRobots.map(async (robot) => {
        try {
          await action(robot.id);
        } catch (e) {
          this.snackBar.open(`Failed to run action for ${robot.id}`);
          console.error(e);
        }
      }),
    )
      .then(() => {
        this.dialogInput.updateState();
      })
      .catch((e) => console.warn(e));
  }
}
