import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PickIconComponent } from './pick-icon.component';

@Component({
  selector: 'app-pick-label',
  templateUrl: './pick-label.component.html',
  styleUrl: './pick-label.component.sass',
  imports: [PickIconComponent],
})
export class PickLabelComponent {
  @Input()
  isPicked = false;

  @Input()
  label = '';

  @Output()
  clicked: EventEmitter<void> = new EventEmitter<void>();
}
