<app-action-notification
  style="display: contents"
  [robotCommunication]="robotCommunication"
/>
@if (robotCommunication) {
  @if (
    (robotCommunication.connected$ | async) &&
    (robotCommunication.isOverlayMapEnabled$ | async)
  ) {
    <app-mini-map
      class="mini-map-container"
      [robotCommunication]="robotCommunication"
      [shouldRotate]="true"
      [shouldFollow]="true"
      [isCustomGpsClickEnabled]="false"
      [isDirectionsClickEnabled]="false"
      [shouldShowMapProviderSelection]="false"
      [shouldShowMiniMapActions]="false"
      [shouldShowZoomControl]="false"
      [shouldUIButtonsBeEnables]="false"
      [shouldShowPrecisePositionWarning]="false"
      [refreshTrigger]="
        activeControl && (robotCommunication.connected$ | async)
      "
    />
  }

  <app-robot-video
    [active]="activeControl"
    [manualMouseControl]="robotCommunication.isManualMouseControl$ | async"
    [attentionStatusList]="attentionStatusList"
    [manualControl]="manualControl$ | async"
    [robotState]="robotCommunication.robotState$ | async"
    [robotAttentionReport]="robotCommunication.robotAttentionReport$ | async"
    [video]="robotCommunication.videoElement"
    (mouseDriveEvent)="updateManualControl($event)"
    (lastMouseRelativePosition)="updateLastMouseRelativePosition($event)"
  />

  <app-robot-text-layout
    [active]="activeControl"
    [robotStateReport]="robotStateReport$ | async"
    [userClaimReport]="userClaimReport$ | async"
    [attentionStatusList]="attentionStatusList"
    [statusStrings]="statusStrings$ | async"
    [robotState]="robotCommunication.robotState$ | async"
    [isCharging]="isBatteryCharging$ | async"
    [batteryPercentage]="(batteryPercentage$ | async) ?? 100"
    [supervisionState]="supervisionState$ | async"
    [operationEmergencyStopActive]="operationEmergencyStopActive$ | async"
    (statusClick)="hideStatus($event)"
  />

  <div class="status-bar">
    @if (robotCommunication.robotState$ | async; as robotState) {
      @if (robotState.issues.length > 0) {
        <app-robot-issues-widget [issues]="robotState.issues" />
      }
    }

    @if (robotCommunication.isServingOrder$ | async) {
      <mat-icon class="order-icon">shopping_basket</mat-icon>
    }

    <div class="order-info">
      <order-overlay-info [order]="robotCommunication.orders$ | async" />
    </div>

    <app-robot-blocking
      [blockedByCurrentUserForMillis]="
        robotCommunication.isBlockedForMillis$ | async
      "
    />

    @if (microphoneEnabled$ | async) {
      <mat-icon class="order-icon">mic</mat-icon>
    }
    <app-unsupervised-autonomy-interaction
      [state]="robotCommunication.unsupervisedAutonomyState$ | async"
    />
  </div>
  <div class="action-bar">
    @if (routeCorridorConfirmationState$ | async; as state) {
      <app-corridor-confirmation
        [state]="state"
        (confirmRouteCorridorEdgeId)="confirmRouteCorridorEdgeId($event)"
        (revokeRouteCorridorConfirmation)="
          revokeRouteCorridorConfirmation($event)
        "
      />
    }

    @if (
      robotCommunication.routeToEndDistance$ | async;
      as distanceToEndOfRoute
    ) {
      <app-arrive-at-route-end
        [distanceToEndOfRoute]="distanceToEndOfRoute"
        (arrive)="arriveAtStop()"
      />
    }

    @if (robotCommunication.robotState$ | async; as robotState) {
      <app-infrastructure-transaction
        [robotState]="robotState"
        (abortInfrastructureTransaction)="abortInfrastructureTransaction()"
      />
    }
  </div>
}

@if (overlayIcon) {
  <div class="icon-overlay-container">
    <mat-icon class="icon-overlay">{{ overlayIcon }}</mat-icon>
  </div>
}

@if (isConnecting$ | async) {
  <div class="robot-loading">
    <mat-spinner />
  </div>
}
