import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Operation } from '@/app/operations/operation';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { UpdateRobot } from './update-robot';
import { BackendService } from '@/app/core/backend.service';
import { retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { AttributeListInputComponent } from '@/app/core/attribute-list-input/attribute-list-input.component';
import { MatButton } from '@angular/material/button';
import { OperationsService } from '@/app/core/operations-service';

interface EditRobotDialogData {
  robot: Robot;
  operations: Operation[];
}

@Component({
  selector: 'app-edit-robot-dialog',
  templateUrl: './edit-robot-dialog.component.html',
  styleUrl: './edit-robot-dialog.component.sass',
  imports: [
    CdkScrollable,
    MatDialogContent,
    MatSlideToggle,
    FormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    MatInput,
    AttributeListInputComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class EditRobotDialogComponent {
  updateRobot: UpdateRobot = {};
  operationIds: string[] = [];

  constructor(
    private backendService: BackendService,
    private operationsService: OperationsService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: EditRobotDialogData,
  ) {
    this.operationsService.getOperations().subscribe({
      next: (operations: Operation[]) => {
        this.operationIds = operations.map((operation) => operation.id);
      },
      error: (error) => {
        // pass
      },
    });
  }

  setRobotReadyForOrders(readyForOrders?: boolean) {
    this.backendService
      .post(`/robots/${this.data.robot.id}/set-ready-for-orders`, {
        readyForOrders: readyForOrders ?? false,
      })
      .pipe(retry(5))
      .subscribe({
        error: () =>
          this.openSnackBarWithConfirmation(
            'Failed to update robot ready for order status!',
          ),
      });
  }

  private openSnackBarWithConfirmation(message: string) {
    this.snackBar.open(
      `Cart ${this.data.robot.serialNumber}\n${message}`,
      'Got it!',
      {
        panelClass: ['snackbar'],
        verticalPosition: 'top',
      },
    );
  }

  setUberVehicleId(vehicleId: string) {
    this.updateRobot.uberVehicleId = vehicleId === '' ? null : vehicleId;
  }
}
