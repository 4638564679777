import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { DirectionsComponent } from './directions.component';
import { MatButton } from '@angular/material/button';
import { RouteDto } from '@/app/core/robots-service/backend/types';

@Component({
  selector: 'directions-dialog',
  template: `
    <directions
      (newDirections)="newDirections($event)"
      [currentRoute]="data.currentRoute"
      [currentLocation]="data.currentLocation"
      [currentGoal]="data.currentGoal"
    />
    <div mat-dialog-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button
        mat-button
        [mat-dialog-close]="directions"
        [disabled]="directions === undefined"
        cdkFocusInitial
      >
        Accept
      </button>
    </div>
  `,
  imports: [DirectionsComponent, MatDialogActions, MatButton, MatDialogClose],
})
export class DirectionsDialogComponent {
  directions?: RouteDto;
  constructor(
    public dialogRef: MatDialogRef<DirectionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  newDirections(directions: RouteDto | undefined) {
    this.directions = directions;
  }
  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
