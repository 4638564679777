import { Component, Input, Output, EventEmitter } from '@angular/core';

import { clone } from 'ramda';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

export type QuickAddItem<T> = {
  itemName: string;
  isSelected: boolean;
  payload: T;
};

export type QuickAddDialogData = {
  selectedRobotIds: string[];
};

@Component({
  selector: 'app-quick-add-remove-dialog',
  templateUrl: './quick-add-remove-dialog.component.html',
  styleUrl: './quick-add-remove-dialog.component.sass',
  imports: [MatIconButton, MatIcon, MatButton],
})
export class QuickAddRemoveDialogComponent<T> {
  _items: QuickAddItem<T>[] = [];

  @Input()
  set items(values: QuickAddItem<T>[] | null) {
    this._items = values
      ? clone(values).sort((item1, item2) =>
          item1.itemName.localeCompare(item2.itemName),
        )
      : [];
  }

  @Output()
  onUpdate = new EventEmitter<QuickAddItem<T>[]>();

  @Output()
  onClose = new EventEmitter<void>();

  constructor() {}

  toggleRobot(item: QuickAddItem<T>) {
    item.isSelected = !item.isSelected;
  }

  close() {
    this.onClose.emit();
  }

  update() {
    this.onUpdate.emit(this._items);
  }
}
