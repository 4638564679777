import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Finalizable } from '@/utils/finalizable';
import { map, takeUntil } from 'rxjs/operators';
import { completeAll } from '@/utils/complete-all';

export class ManualMouseControlState extends Finalizable {
  private _manualMouseControlEnabled$ = new BehaviorSubject(false);
  readonly isManualMouseControl$: Observable<boolean>;

  constructor(
    isInControl$: Observable<boolean>,
    isInstructorModeActive$: Observable<boolean>,
  ) {
    super();
    this.isManualMouseControl$ = combineLatest([
      isInControl$,
      isInstructorModeActive$,
      this._manualMouseControlEnabled$,
    ]).pipe(
      takeUntil(this.finalized$),
      map(
        ([isInControl, isInstructor, isManual]) =>
          (isInControl && isManual) || isInstructor,
      ),
    );
  }

  enableManualMouseControl(isManualMouseControl: boolean) {
    this._manualMouseControlEnabled$.next(isManualMouseControl);
  }

  protected async onFinalize(): Promise<void> {
    completeAll(this._manualMouseControlEnabled$);
  }
}
