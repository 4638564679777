import { Component, OnInit } from '@angular/core';
import { ServerUpdatesService } from './core/server-updates.service';
import { ErrorSystemOverlayComponent } from './core/error-system/error-system-overlay.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  imports: [ErrorSystemOverlayComponent, RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(private swUpdate: ServerUpdatesService) {}

  ngOnInit() {
    this.swUpdate.checkForUpdates();
  }

  title = 'frontend';
}
