@if (breakingIssues().length > 0) {
  <mat-icon class="issues-icon">priority_high</mat-icon>
  <div class="issues-list">
    {{ '' // TODO: Get the Organization ID from the Backend }}
    @for (issue of breakingIssues(); track issue.id) {
      @if (issue.customId !== undefined) {
        <a
          class="issue"
          href="https://app.clickup.com/t/9004035169/{{ issue.customId }}"
          target="_blank"
        >
          <b>[{{ issue.customId }}]</b> {{ issue.name }}
        </a>
      } @else {
        <span class="issue">
          <b>[{{ issue.id }}]</b> {{ issue.name }}
        </span>
      }
    }
  </div>
}
