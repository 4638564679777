import { Component } from '@angular/core';
import { LineStringGeometry } from '@cartken/map-types';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';

import { MatTabGroup, MatTab, MatTabContent } from '@angular/material/tabs';
import { MapRecordingsComponent } from './map-recordings.component';
import { MappingRobotsComponent } from './mapping-robots.component';
import { MappingAutomationComponent } from './mapping-automation.component';
import { MatDivider } from '@angular/material/divider';
import { MapComponent } from '../core/map/map.component';

export interface ColoredMarker {
  position: google.maps.LatLng;
  color: string;
  orientation?: number;
}

export interface ColoredPolyline {
  polyline: LineStringGeometry;
  color: string;
}

export interface Geometry {
  polylines?: ColoredPolyline[];
  markers?: ColoredMarker[];
  bounds?: google.maps.LatLngBounds;
}

export interface Overlay {
  imageUrl: string;
  bounds: google.maps.LatLngBoundsLiteral;
}

@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrl: './mapping.component.sass',
  imports: [
    ToolbarComponent,
    MatTabGroup,
    MatTab,
    MatTabContent,
    MapRecordingsComponent,
    MappingRobotsComponent,
    MappingAutomationComponent,
    MatDivider,
    MapComponent,
  ],
})
export class MappingComponent {
  readonly googleMapOptions = {
    center: { lat: 37.740667, lng: -122.201146 },
    zoom: 18,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    tilt: 0,
    clickableIcons: false,
    fullscreenControl: false,
    streetViewControl: false,
    disableDoubleClickZoom: true,
  };

  googleMap?: google.maps.Map;
  markers: google.maps.Marker[] = [];
  overlay?: google.maps.GroundOverlay = undefined;

  constructor() {}

  onGoogleMap(googleMap: google.maps.Map): void {
    this.googleMap = googleMap;
  }

  showGeometry(geometry: Geometry): void {
    this.googleMap?.data.forEach((feature) =>
      this.googleMap?.data.remove(feature),
    );
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];
    this.googleMap?.data.setStyle(
      (feature: google.maps.Data.Feature) =>
        ({
          strokeColor: feature.getProperty('color'),
        }) as google.maps.Data.StyleOptions,
    );
    geometry.polylines?.forEach((polyline) => {
      this.googleMap?.data.addGeoJson({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: polyline.polyline,
            properties: {
              color: polyline.color,
            },
          },
        ],
      });
    });
    geometry.markers?.forEach((marker) =>
      this.markers.push(
        new google.maps.Marker({
          zIndex: this.markers.length,
          position: marker.position,
          map: this.googleMap,
          icon:
            marker.orientation !== undefined
              ? {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  fillOpacity: 1,
                  fillColor: marker.color,
                  strokeWeight: 1.5,
                  scale: 5,
                  rotation: marker.orientation,
                }
              : {
                  path: 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z',
                  anchor: new google.maps.Point(12, 17),
                  fillOpacity: 1,
                  fillColor: marker.color,
                  scale: 1.5,
                },
        }),
      ),
    );
    if (geometry.bounds !== undefined) {
      this.googleMap?.fitBounds(geometry.bounds);
    } else {
      const bounds = new google.maps.LatLngBounds();
      this.googleMap?.data.forEach((feature) =>
        feature
          ?.getGeometry()
          ?.forEachLatLng((latlng) => bounds.extend(latlng)),
      );
      if (!bounds.isEmpty()) {
        this.googleMap?.fitBounds(bounds);
      }
    }
  }

  showOverlay(overlay: Overlay) {
    if (this.overlay !== undefined) {
      this.overlay.setMap(null);
      this.overlay = undefined;
    }
    this.overlay = new google.maps.GroundOverlay(
      overlay.imageUrl,
      overlay.bounds,
      {
        map: this.googleMap,
      },
    );
  }
}
