import { Injectable } from '@angular/core';
import {
  CreateOrReplaceMapChangeset,
  ElementType,
  MapChangesetConflict,
  MapChangeset,
  MapChangesetInfo,
  MapElement,
  OperationRegion,
  Versions,
} from '@cartken/map-types';
import { EMPTY, lastValueFrom, throwError } from 'rxjs';
import { v } from '@/valibot';

import { closePolygon } from '@/utils/geo-tools';
import { BackendService } from './backend.service';
import { ErrorService } from './error-system/error.service';
import { vParsePretty } from '@/utils/valibot-parse-pretty';

export interface BlockageUpdateDto {
  mapElementId: number;
  isBlocked: boolean;
  blockedUntil: Date;
}

function generateHistoryLookupUrl(
  boundingPolygon: number[][][],
  version: number | undefined,
): string {
  const url = `/map/history?region-polygon=${JSON.stringify(boundingPolygon)}`;
  if (version === undefined) {
    return url;
  }
  return url + `&version=${version}`;
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private backendService: BackendService,
    private errorService: ErrorService,
  ) {}

  async loadOperationRegions(version?: number): Promise<OperationRegion[]> {
    let url = '/map/history?element-types=' + ElementType.OPERATION_REGION;
    if (version !== undefined) {
      url += `&version=${version}`;
    }
    const result = await lastValueFrom(this.backendService.get(url));
    const operationRegions = vParsePretty(v.array(OperationRegion), result);
    return operationRegions.filter((region) => !region.deleted);
  }

  async loadMapElements(
    boundingCoordinates: number[][],
    version: number | undefined,
  ): Promise<MapElement[]> {
    if (
      boundingCoordinates[0] !==
      boundingCoordinates[boundingCoordinates.length - 1]
    ) {
      closePolygon(boundingCoordinates);
    }
    if (boundingCoordinates.length < 4) {
      return [];
    }
    const url = generateHistoryLookupUrl([boundingCoordinates], version);
    const result = await lastValueFrom(this.backendService.get(url));
    return vParsePretty(v.array(MapElement), result);
  }

  async deployMapVersion(version: number): Promise<void> {
    return await lastValueFrom(
      this.backendService.post<void>(`/map/deploy-version`, { version }),
    );
  }

  async getMapVersions(): Promise<Versions> {
    const result = await lastValueFrom(
      this.backendService.get(`/map/versions`),
    );
    return vParsePretty(Versions, result);
  }

  async loadMapChangesetInfos(): Promise<MapChangesetInfo[]> {
    const result = await lastValueFrom(
      this.backendService.get(`/map/changesets`),
    );
    return vParsePretty(v.array(MapChangesetInfo), result);
  }

  async loadMapChangeset(id: string): Promise<MapChangeset> {
    const result = await lastValueFrom(
      this.backendService.get(`/map/changesets/${id}`),
    );
    return vParsePretty(MapChangeset, result);
  }

  async createMapChangeset(
    changeset: CreateOrReplaceMapChangeset,
  ): Promise<MapChangeset> {
    const result = await lastValueFrom(
      this.backendService.post(`/map/changesets`, changeset),
    );
    return vParsePretty(MapChangeset, result);
  }

  async replaceMapChangeset(
    id: string,
    changeset: CreateOrReplaceMapChangeset,
  ): Promise<MapChangeset> {
    const result = await lastValueFrom(
      this.backendService.put(`/map/changesets/${id}`, changeset),
    );
    return vParsePretty(MapChangeset, result);
  }

  async commitMapChangeset(id: string): Promise<MapChangeset> {
    const result = await lastValueFrom(
      this.backendService.post<MapChangeset>(
        `/map/changesets/${id}/commit`,
        {},
        (error) => {
          if (error.status === 409) {
            this.errorService.reportError(
              `Found changeset conflicts, you need to rebase`,
            );
            return EMPTY;
          }
          return throwError(() => error);
        },
      ),
    );
    return vParsePretty(MapChangeset, result);
  }

  async getMapChangesetConflicts(id: string): Promise<MapChangesetConflict[]> {
    const result = await lastValueFrom(
      this.backendService.get(`/map/changesets/${id}/conflicts`),
    );
    return vParsePretty(v.array(MapChangesetConflict), result);
  }

  async deleteChangeset(id: string): Promise<boolean> {
    try {
      await lastValueFrom(this.backendService.delete(`/map/changesets/${id}`));
      return true;
    } catch (e) {
      this.errorService.reportError(`Could not delete change set.`);
      return false;
    }
  }

  async updateBlockages(
    blockageUpdates: BlockageUpdateDto[],
    description: string,
  ): Promise<boolean> {
    try {
      await lastValueFrom(
        this.backendService.put(`/map/update-blockages`, {
          blockageUpdates,
          description,
        }),
      );
      return true;
    } catch (e) {
      this.errorService.reportError(`Could not update blockages.`);
      return false;
    }
  }
}
