@if (revokeConfirmationAvailable) {
  <div (click)="revokeCorridor()" class="backgroud-icon-with-distance">
    <mat-icon class="active-confirmation-icon">delete</mat-icon>
    <div class="confirmation-text">Revoke confirmation</div>
  </div>
}

@if (edgeIdToConfirm) {
  <div (click)="confirmCorridor()" class="backgroud-icon-with-distance">
    <mat-icon class="active-confirmation-icon">check</mat-icon>
    <div class="confirmation-text">Confirm corridor</div>
  </div>
}

@if (!edgeIdToConfirm && distanceToConfirmation) {
  <div class="backgroud-icon-with-distance">
    <mat-icon class="confirmation-icon">check</mat-icon>
    <div class="distance-text">
      {{ distanceToConfirmation | number: '1.0-0' }}m
    </div>
  </div>
}
