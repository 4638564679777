import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RobotCommunication } from '../robots-service/robot-communication';
import { MiniMapComponent } from '../mini-map/mini-map.component';
import { MatButton } from '@angular/material/button';

export interface MapConfirmationDialogData {
  message: string;
  robotCommunication: RobotCommunication;
}

@Component({
  selector: 'app-map-confirmation-dialog-dialog',
  templateUrl: './map-confirmation-dialog.component.html',
  styleUrl: './map-confirmation-dialog.component.sass',
  imports: [MiniMapComponent, MatButton],
})
export class MapConfirmationDialog {
  message: string;

  constructor(
    private dialogRef: MatDialogRef<MapConfirmationDialogData, boolean>,
    @Inject(MAT_DIALOG_DATA) readonly data: MapConfirmationDialogData,
  ) {
    this.message = data.message;
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }
}
