@let isNotInControl = (isNotInControl$ | async) ?? true;

@if (instructorModeActive$ | async) {
  <div class="instructor-mode"></div>
}

@if (isClaimControlEnabled) {
  <mat-slide-toggle
    [ngModel]="robotCommunication?.isInControl$ | async"
    (change)="setInControl($event.checked, $event)"
  >
    Claim Control
  </mat-slide-toggle>
}

@if (isManualControlEnabled) {
  <mat-slide-toggle
    [ngModel]="robotCommunication?.isManualMouseControl$ | async"
    [disabled]="isNotInControl"
    (change)="enableManualMouseControl($event.checked)"
  >
    Manual Control
  </mat-slide-toggle>
}

@if (autonomyControlType === 'AUTONOMY_TOGGLE') {
  <mat-slide-toggle
    [ngModel]="this.robotCommunication?.isAutonomyEnabled$ | async"
    (change)="toggleIsAutonomyEnabled()"
    [disabled]="isNotInControl"
  >
    Autonomy
  </mat-slide-toggle>
}

@if (isCurbClimbingControlEnabled) {
  <mat-slide-toggle
    [ngModel]="this?.robotCommunication?.isCurbClimbingOn$ | async"
    [disabled]="isNotInControl"
    (change)="this.robotCommunication?.enableCurbClimbingMode($event.checked)"
  >
    Curb Climbing
  </mat-slide-toggle>
}

@if (isReadyForOrderControlEnabled) {
  <mat-slide-toggle
    [checked]="robotCommunication?.readyForOrders$ | async"
    (change)="setRobotReadyForOrder($event.checked)"
    [disabled]="isNotInControl"
  >
    Ready For Orders
  </mat-slide-toggle>
}

@if (hazardLightControlType === 'HAZARD_LIGHTS_DROPDOWN') {
  <mat-form-field>
    <mat-label>Hazard Lights</mat-label>
    <mat-select
      [ngModel]="hazardLightState$ | async"
      [disabled]="isNotInControl"
      (selectionChange)="sendLightingCommand($event.value)"
    >
      @for (state of HAZARD_LIGHTS_STATES; track state) {
        <mat-option [value]="state">
          {{ state }} ({{ hazardLightState$ | async }})
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}

@if (isNetworkInterfaceControlEnabled) {
  <mat-form-field class="network-switch">
    <mat-label>Network Interface</mat-label>
    <mat-select
      [panelWidth]="null"
      (selectionChange)="selectNetworkInterface($event.value)"
      [value]="robotCommunication?.selectedNetworkInterface$ | async"
    >
      @for (
        iface of robotCommunication?.availableNetworkInterfaces$ | async;
        track iface.interfaceName
      ) {
        <mat-option [value]="iface.interfaceName">
          @if (iface.interfaceName) {
            <span
              >{{ iface.interfaceDisplayName || iface.interfaceName }}
              @if (iface.minPing) {
                <span>
                  ({{ iface.minPing | number: '1.1-1' }}&lt;{{
                    iface.avgPing | number: '1.1-1'
                  }}&lt;{{ iface.maxPing | number: '1.1-1' }}ms |
                  {{ iface.packageLoss | number: '1.0-0' }}% )</span
                >
              }
            </span>
          }

          @if (!iface.interfaceName) {
            <span>No Preference</span>
          }
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
}

@if (hazardLightControlType === 'HAZARD_LIGHT_TOGGLE') {
  <button
    class="mat-button-aspect-ratio hazard-lights-button"
    [disabled]="isNotInControl"
    mat-mini-fab
    [matTooltip]="'Enable hazard lights'"
    color="primary"
    (click)="toggleHazardLightMode()"
    [class.active]="(hazardLightState$ | async) !== 'Auto'"
  >
    <mat-icon
      class="hazard-lights-button-icon"
      svgIcon="hazard-lights-triangles"
    />
  </button>
}

@if (autonomyControlType === 'PARKING_MODE') {
  <button
    class="mat-button-aspect-ratio"
    [disabled]="isNotInControl"
    mat-mini-fab
    [matTooltip]="'Autonomy Off'"
    color="primary"
    (click)="toggleIsAutonomyEnabled()"
    [class.active]="(robotCommunication?.isAutonomyEnabled$ | async) !== true"
  >
    <mat-icon>local_parking</mat-icon>
  </button>
}

@if (isLidClockControlEnabled && legacyLidControlActive) {
  <button
    class="mat-button-aspect-ratio"
    [disabled]="isNotInControl"
    mat-mini-fab
    [matTooltip]="isLocked ? 'Unlock' : 'Lock'"
    color="primary"
    (click)="toggleLock()"
    [class.active]="!isLocked"
  >
    @if (isLocked) {
      <mat-icon>lock_open</mat-icon>
    }
    @if (!isLocked) {
      <mat-icon>lock</mat-icon>
    }
  </button>
}

@if (isBlockageReportEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    [matTooltip]="'Report blockage'"
    color="primary"
    (click)="openReportBlockageDialog()"
  >
    <mat-icon>block</mat-icon>
  </button>
}

@if (isSnapshotControlEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Take a snapshot [S]'"
    (click)="triggerSnapshot()"
  >
    <mat-icon>add_a_photo</mat-icon>
  </button>
}

@if (
  isPowerSavingControlEnabled && (isNotAutonomicPowerSavingControl$ | async)
) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle power saving mode'"
    [disabled]="isNotInControl"
    (click)="togglePowerSaving()"
    [class.active]="robotCommunication?.isPowerSaving$ | async"
  >
    <mat-icon>bolt</mat-icon>
  </button>
}

@if (isAutomaticPowerControlEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Toggle automatic power saving'"
    [disabled]="isNotInControl"
    (click)="toggleAutomaticPowerSaving()"
    [class.active]="robotCommunication?.isAutomaticPowerSaving$ | async"
  >
    <mat-icon> energy_savings_leaf</mat-icon>
  </button>
}

@if (isNavigationToMaintenanceEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [matTooltip]="'Open in maintenance mode'"
    (click)="openMaintenanceUi()"
  >
    <mat-icon>build</mat-icon>
  </button>
}

@if (isRelocalizeEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [disabled]="isNotInControl"
    [matTooltip]="'Relocalize'"
    (click)="relocalize()"
  >
    <mat-icon>mode_of_travel</mat-icon>
  </button>
}

@if (isCalibrateEndstopEnabled) {
  <button
    class="mat-button-aspect-ratio"
    mat-mini-fab
    color="primary"
    [disabled]="isNotInControl"
    [matTooltip]="'Calibrate Endstops / IMU'"
    (click)="calibrateEndstop()"
  >
    <mat-icon>play_for_work</mat-icon>
  </button>
}

<ng-content />

@if (robotSoundAvailable$ | async) {
  <div class="robot-audio">
    @if (audioLevelActive$ | async) {
      <button
        class="mat-button-aspect-ratio"
        mat-mini-fab
        color="primary"
        [matTooltip]="'Enable Speaker'"
        [class.active]="true"
        (click)="toggleRobotAudio()"
      >
        <mat-icon>volume_up</mat-icon>
      </button>
      <app-audio-level-overlay
        [robotCommunication]="robotCommunication"
        class="audio-button-plot"
      />
    } @else {
      <button
        class="mat-button-aspect-ratio"
        mat-mini-fab
        color="primary"
        [matTooltip]="
          (speakerActive$ | async) ? 'Disable Speaker' : 'Enable Audio Level'
        "
        [class.active]="speakerActive$ | async"
        (click)="toggleRobotAudio()"
      >
        <mat-icon>volume_up</mat-icon>
      </button>
    }
  </div>
}

<div class="spacer"></div>

<button mat-mini-fab color="secondary" [matMenuTriggerFor]="actionsMenu">
  <mat-icon>menu</mat-icon>
</button>

<mat-menu
  #actionsMenu="matMenu"
  class="more-actions-menu"
  xPosition="before"
  yPosition="above"
>
  @if (isHighBeamsControlEnabled) {
    <button
      mat-menu-item
      [disabled]="isNotInControl"
      [class.active]="isHighBeamsEnabled"
      (click)="toggleHighBeams()"
    >
      <mat-icon svgIcon="high-beam" /> High Beams
    </button>
  }

  @if (isArrivedControlEnabled) {
    <button
      mat-menu-item
      [disabled]="(robotCommunication?.enableArrivedButton$ | async) !== true"
      (click)="arrivedAtStop()"
    >
      <mat-icon>flag</mat-icon> Arrived
    </button>
  }

  @if (isSirenControlEnabled) {
    <button
      mat-menu-item
      [disabled]="isNotInControl"
      [class.active]="isSirenOn"
      (click)="toggleSiren()"
    >
      <mat-icon>notification_important</mat-icon> Toggle Siren
    </button>
  }

  @if (isCompartmentControlEnabled && !legacyLidControlActive) {
    <button
      mat-menu-item
      [disabled]="isNotInControl"
      (click)="openCompartmentsDialog()"
    >
      <mat-icon>widgets</mat-icon> Handle Compartments
    </button>
  }

  @if (isOverlayMapControlEnabled) {
    <button
      mat-menu-item
      (click)="toggleIsOverlayMapEnabled()"
      [class.active]="robotCommunication?.isOverlayMapEnabled$ | async"
    >
      <mat-icon>map</mat-icon> Enable overlay map
    </button>
  }

  @if (isIssueReportingEnabled) {
    @if (robotCommunication?.isAutonomyEnabled$ | async) {
      <button
        mat-menu-item
        matTooltip="Please STOP the robot before submitting an issue!"
        disabled
      >
        <mat-icon>priority_high</mat-icon> Submit an Issue
      </button>
    } @else {
      <button mat-menu-item (click)="openRobotIssue()">
        <mat-icon>priority_high</mat-icon> Submit an Issue
      </button>
    }
  }

  <ng-content select="menu" />
</mat-menu>
