import { Component, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrl: './placeholder.component.sass',
  imports: [MatIcon],
})
export class PlaceholderComponent {
  iconName = input.required<string>();
  text = input.required<string>();
}
