import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  InfrastructureTransactionStatus,
  Robot,
} from '@/app/core/robots-service/backend/robot.dto';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-infrastructure-transaction',
  templateUrl: './infrastructure-transaction.component.html',
  styleUrl: './infrastructure-transaction.component.sass',
  imports: [MatIcon, NgClass, MatFabButton, MatTooltip],
})
export class InfrastructureTransactionComponent {
  status = '';
  error = false;
  @Output() abortInfrastructureTransaction = new EventEmitter<void>();

  @Input()
  set robotState(state: Robot | undefined) {
    this.error =
      state?.infrastructureTransaction?.status ===
      InfrastructureTransactionStatus.ERROR;

    if (this.error) {
      const infrastructureStopMatch =
        state?.infrastructureTransaction?.infrastructureId &&
        state?.infrastructureTransaction?.infrastructureId ===
          state?.scheduledStops?.[0]?.infrastructureId;

      if (infrastructureStopMatch) {
        this.status =
          state?.infrastructureTransaction?.abortedWithError ?? 'Unknown Error';
      } else {
        this.error = false;
        this.status = '';
      }
    } else {
      this.status =
        state?.infrastructureTransaction?.status?.replace(
          /([A-Z][a-z])/g,
          ' $1',
        ) ?? '';
    }
  }
}
