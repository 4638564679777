<div class="column">
  <div class="weekday-name">&nbsp;</div>
  <div class="timeline">
    @for (mins of timelineTicksMins; track mins) {
      <div
        class="tick"
        [style]="{
          '--time-day-percent': totalMinutesToDayPercent(mins),
        }"
      >
        {{ minutesToTimeString(mins) }}
      </div>
    }
  </div>
</div>
@for (weekday of weekdays; track weekday) {
  <div class="column">
    <div class="weekday-name">{{ weekdayDisplayName(weekday) }}</div>
    <div class="timeline" (pointerdown)="addRangeHandler($event, { weekday })">
      @let ranges = weeklySchedule()[weekday];
      @for (range of ranges; track $index) {
        <div
          class="event"
          [class.invalid]="!expectedRobotCountIsValid(range)"
          [style]="eventStyleVars(range)"
          (pointerdown)="moveRangeHandler($event, { weekday, index: $index })"
          (click)="
            focusRange.emit({ weekday, index: $index });
            $event.stopImmediatePropagation()
          "
        >
          @if (range.expectedRobotsInOps !== undefined) {
            <span class="robot-count">{{ range.expectedRobotsInOps }}</span>
          } @else {
            <span class="robot-count">∞</span>
          }
          <div
            class="event-resize-handle start"
            (pointerdown)="
              moveRangeStartHandler($event, { weekday, index: $index })
            "
          ></div>
          <div
            class="event-resize-handle end"
            (pointerdown)="
              moveRangeEndHandler($event, { weekday, index: $index })
            "
          ></div>
          <button
            class="delete-range-button"
            (pointerdown)="$event.stopImmediatePropagation()"
            (click)="
              removeRange.emit({ weekday, index: $index });
              $event.stopImmediatePropagation()
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      }
      @let previewRange_ = previewRange();
      @if (previewRange_ && previewRange_.weekday === weekday) {
        <div
          class="preview-event"
          [style]="eventStyleVars(previewRange_.range)"
          [class.invalid]="
            !rangeIsValid(ranges, previewRange_.range, previewRange_.index)
          "
        ></div>
      }
      @if (weekday === todayWeekday()) {
        <div
          class="now-line"
          [style]="{ '--now-day-percent': timeNowDayPercent() }"
        ></div>
      }
    </div>
  </div>
}
