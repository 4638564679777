import { effect, untracked } from '@angular/core';

/**
 * Angular utility for running a function on animation frame.
 * It automatically cleans up when component unmounts.
 */
export function onAnimationFrame(fn: () => void) {
  effect((onCleanup) => {
    let animationFrameId = -1;
    const render = () => {
      // untracked because we don't want to subscribe to signals
      // inside the animation frame callback. This would cause
      // any signal read inside the callback to rerun this whole effect.
      untracked(fn);
      animationFrameId = requestAnimationFrame(render);
    };
    render();
    onCleanup(() => {
      cancelAnimationFrame(animationFrameId);
    });
  });
}
