import { Component, EventEmitter, Input, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Operation } from '../../operations/operation';
import { OperationsService } from '../operations-service';
import { MatDialog } from '@angular/material/dialog';
import { SelectOperationDialogComponent } from '../select-operation-dialog/select-operation-dialog.component';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-select-operation-button',
  templateUrl: './select-operation-button.component.html',
  styleUrl: './select-operation-button.component.sass',
  imports: [MatIcon],
})
export class SelectOperationButtonComponent {
  @Input() selectedOperationId?: string;
  @Output() selectedOperationChange = new EventEmitter<Operation | undefined>();

  constructor(
    private operationsService: OperationsService,
    private dialog: MatDialog,
  ) {}

  async selectOperation() {
    const operations = await firstValueFrom(
      this.operationsService.getOperations(),
    );
    const dialogRef = this.dialog.open(SelectOperationDialogComponent, {
      width: '40rem',
      data: { operations, selectedOperationId: this.selectedOperationId },
    });

    dialogRef.afterClosed().subscribe((operation?: Operation) => {
      if (operation) {
        this.selectedOperationId = operation?.id;
        this.selectedOperationChange.emit(operation);
      }
    });
  }
}
