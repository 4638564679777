<div class="attention-statuses">
  @if ((robotStateReport()?.length ?? 0) > 0) {
    <div class="attention-status robot-state-report">
      {{ robotStateReport() }}
    </div>
  }

  @if (operationEmergencyStopActive()) {
    <div class="attention-status emergency-stop-active">
      {{
        'Emergency Stop Activated: Autonomous mode is disabled. Please park safely and promptly. '
      }}
    </div>
  }

  @if ((supervisionState()?.length ?? 0) > 0) {
    <div class="attention-status robot-state-report">
      {{ supervisionState() }}
    </div>
  }

  @if ((userClaimReport()?.length ?? 0) > 0) {
    <div class="attention-status robot-state-report">
      {{ userClaimReport() }}
    </div>
  }

  @if (robotState()?.awxUpdateActive) {
    <div class="attention-status robot-state-report">
      {{
        'Robot is currently being updated. Please contact Cartken before putting it into operation.'
      }}
    </div>
  }

  @for (attentionStatus of attentionStatusListTexts; track $index) {
    <div
      class="attention-status"
      [class.red-text]="attentionStatus.type === 'error'"
      [class.yellow-text]="attentionStatus.type === 'warning'"
      (click)="statusClick.emit(attentionStatus.originalMessage)"
    >
      <span class="attention-status-text">
        {{ attentionStatus.text }}
      </span>
      <span class="mute-text">
        <mat-icon>snooze</mat-icon> Click to snooze
      </span>
    </div>
  }
</div>

@if (robotState(); as robotState) {
  <div class="robot-data-overview">
    @if (robotState.pictureUrl) {
      <img
        src="{{ robotState.pictureUrl }}"
        class="google-profile-img"
        [ngStyle]="{
          'background-color': robotState.displayColor
            ? robotState.displayColor
            : '#f8961d',
        }"
      />
    }
    <div class="robot-info">
      <div class="robot-name">
        @if (
          robotState.shortName &&
          robotState.shortName !== robotState?.serialNumber?.toFixed(0)
        ) {
          {{ robotState.serialNumber }} ({{ robotState.shortName }})
        } @else {
          #{{ robotState.serialNumber }}
        }
      </div>
      <app-battery-status
        [batteryPercentage]="batteryPercentage()"
        [charging]="!!isCharging()"
      />
      <div
        class="speed"
        [class.autonomous-speed]="robotState.controlType === 'Autonomy'"
      >
        <mat-icon>speed</mat-icon>
        {{ robotState.velocity | number: '1.1-1' }}
        <div class="unit-m-s">
          m
          <hr />
          s
        </div>
      </div>
    </div>
    <div class="robot-status">
      @for (statusString of statusStrings(); track statusString) {
        <div>
          {{ statusString }}
        </div>
      }
    </div>
    @if (robotState.orderDetected) {
      <img
        class="order-detected-icon"
        height="50"
        src="assets/scale_white_24dp.svg"
      />
    }
  </div>
}
