import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogActions,
} from '@angular/material/dialog';
import {
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RobotQueueEdgeHandover } from '@/app/operations/operation';
import { CreateOrderDto } from '@/app/core/order/order';
import { LatLng } from 'spherical-geometry-js';
import { BackendService } from '@/app/core/backend.service';
import { firstValueFrom } from 'rxjs';
import { ErrorService } from '@/app/core/error-system/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
  MatError,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MatDivider } from '@angular/material/divider';
import { SelectionDropboxComponent } from '@/app/core/selection-dropbox/selection-dropbox.component';
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatButton } from '@angular/material/button';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { OperationsService } from '@/app/core/operations-service';

const SNACK_BAR_MSG_TIMEOUT = 3 * 1000;

function createErrorMessage(httpError: HttpErrorResponse) {
  return httpError.error?.message?.match(/^[\w ]+:/i)
    ? httpError.error.message
    : `Order creation failed: ${httpError.error.message}`;
}

function extractHandoverNames(queueEdgeList?: RobotQueueEdgeHandover[]) {
  if (!queueEdgeList) {
    return [];
  }
  return queueEdgeList.map(
    (queueEdge) => queueEdge.displayName ?? queueEdge.name,
  );
}

function parseLatLonString(latLonString: string): LatLng | undefined {
  const latLon = latLonString.split(/, ?/);
  if (latLon.length !== 2) {
    return undefined;
  }
  // override check of undefined, length is checked
  const lat = parseFloat(latLon[0]!);
  const lon = parseFloat(latLon[1]!);
  if (isNaN(lat) || isNaN(lon)) {
    return undefined;
  }
  return new LatLng(lat, lon);
}

export interface RobotIdentifier {
  id?: string;
  robotId?: string;
  shortName?: string;
  name?: string;
}

export interface CreateOrderDialogData {
  operationId: string;
  useExternalId?: boolean;
  usePhoneNumber?: boolean;
  phone?: string;
}

export interface CreateOrderDialogResult {
  isCreated: boolean;
}

enum NotificationMethod {
  TEXT_MESSAGES = 'TextMessages',
  PHONE_CALLS = 'PhoneCalls',
}

@Component({
  selector: 'app-create-order-dialog',
  templateUrl: './create-order-dialog.component.html',
  styleUrl: './create-order-dialog.component.sass',
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatDivider,
    SelectionDropboxComponent,
    NgxMatInputTelComponent,
    ReactiveFormsModule,
    MatIcon,
    MatSuffix,
    MatError,
    MatSelect,
    MatOption,
    MatSlideToggle,
    MatDialogActions,
    MatButton,
  ],
})
export class CreateOrderDialogComponent implements AfterViewInit, OnInit {
  @ViewChild('pickupLocation') pickupLocationElement!: ElementRef;
  @ViewChild('dropoffLocation') dropoffLocationElement!: ElementRef;

  order: CreateOrderDto = {
    operationId: '',
    pickupHandover: {},
    dropoffHandover: {},
  };
  phoneFormControl: FormControl;
  phoneNotificationMethod = NotificationMethod.TEXT_MESSAGES;
  readonly phoneNotificationMethods = Object.values(NotificationMethod);
  emailFormControl: FormControl;
  pickupAddress = '';
  dropoffAddress = '';
  testOrder = false;

  selectedPickup?: string;
  selectedDropoff?: string;

  pickups: RobotQueueEdgeHandover[] = [];
  pickupNames: string[] = [];

  dropoffs: RobotQueueEdgeHandover[] = [];
  dropoffNames: string[] = [];

  preferredCountryCodes: string[] = [];
  allowCustomPickupLocationsWithinBounds = true;
  allowCustomDropoffLocationsWithinBounds = true;
  robots: Robot[] = [];
  pickupImmediatelyEnabled = false;
  operationName = '';

  operationId = '';
  constructor(
    public dialogRef: MatDialogRef<
      CreateOrderDialogComponent,
      CreateOrderDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: CreateOrderDialogData,
    private backendService: BackendService,
    private operationsService: OperationsService,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
  ) {
    this.phoneFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern('[- +()0-9]{6,}'),
    ]);
    if (this.data.phone) {
      this.phoneFormControl.setValue(this.data.phone);
    }

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  async ngOnInit(): Promise<void> {
    const operation = await firstValueFrom(
      this.operationsService
        .getOperationById(this.data.operationId)
        .pipe(
          this.errorService.handleStreamErrors(
            `Operation with name '${this.data.operationId}' could not be retrieved.`,
          ),
        ),
    );
    this.pickupImmediatelyEnabled =
      operation.operationData?.pickupImmediatelyEnabled ?? false;

    this.operationName = operation.displayName ?? operation.id;
    this.operationId = operation.id;

    this.pickups = operation.operationData?.pickups ?? [];
    this.pickupNames = extractHandoverNames(operation.operationData?.pickups);
    const pickup = this.pickupNames[0];
    if (pickup !== undefined) {
      this.selectPickup(pickup);
    }

    this.dropoffs = operation.operationData?.dropoffs ?? [];
    this.dropoffNames = extractHandoverNames(this.dropoffs);

    const dropoff = this.dropoffNames[0];
    if (dropoff !== undefined) {
      this.selectDropoff(dropoff);
    }

    this.preferredCountryCodes = operation.operationData
      ?.preferredCountryCodes ?? ['us'];

    this.allowCustomPickupLocationsWithinBounds =
      operation.operationData?.allowCustomPickupLocationsWithinBounds ?? false;
    this.allowCustomDropoffLocationsWithinBounds =
      operation.operationData?.allowCustomDropoffLocationsWithinBounds ?? false;

    this.robots = await firstValueFrom(
      this.backendService
        .get<Robot[]>(`/robots?assigned_operation_id=${this.data.operationId}`)
        .pipe(
          this.errorService.handleStreamErrors(
            'Can not get updated robots status',
          ),
        ),
    );
  }

  ngAfterViewInit() {
    if (this.allowCustomPickupLocationsWithinBounds) {
      const pickupAutocomplete = new google.maps.places.Autocomplete(
        this.pickupLocationElement.nativeElement,
      );
      pickupAutocomplete.addListener('place_changed', () => {
        this.handoverLocationChanged(
          pickupAutocomplete.getPlace(),
          true /*isPickup*/,
        );
      });
    }
    if (this.allowCustomDropoffLocationsWithinBounds) {
      const dropoffAutocomplete = new google.maps.places.Autocomplete(
        this.dropoffLocationElement.nativeElement,
      );

      dropoffAutocomplete.addListener('place_changed', () => {
        this.handoverLocationChanged(
          dropoffAutocomplete.getPlace(),
          false /*isPickup*/,
        );
      });
    }
  }

  private handoverLocationChanged(
    place: google.maps.places.PlaceResult,
    isPickup: boolean,
  ) {
    let location = place.geometry?.location;
    if (!location) {
      const latLon = parseLatLonString(
        isPickup
          ? this.pickupLocationElement.nativeElement.value
          : this.dropoffLocationElement.nativeElement.value,
      );
      if (!latLon) {
        return;
      }
      location = new google.maps.LatLng(latLon.lat(), latLon.lng());
    }
    const createHandover = isPickup
      ? this.order.pickupHandover
      : this.order.dropoffHandover;
    createHandover.latitude = location.lat();
    createHandover.longitude = location.lng();
    createHandover.address = place.formatted_address;
    createHandover.displayName = place.formatted_address;
  }

  async onCreateClick() {
    if (this.phoneFormControl.value && !this.phoneFormControl.valid) {
      return;
    }

    if (this.emailFormControl.value && !this.emailFormControl.valid) {
      return;
    }

    if (this.phoneFormControl.value && this.phoneFormControl.valid) {
      this.order.dropoffHandover.phone = this.phoneFormControl.value;
      if (this.phoneNotificationMethod === NotificationMethod.TEXT_MESSAGES) {
        this.order.dropoffHandover.phoneNotifications = true;
      }
      if (this.phoneNotificationMethod === NotificationMethod.PHONE_CALLS) {
        this.order.dropoffHandover.phoneCallNotifications = true;
      }
    }

    if (this.emailFormControl.value && this.emailFormControl.valid) {
      this.order.dropoffHandover.email = this.emailFormControl.value;
      this.order.dropoffHandover.emailNotifications = true;
    }

    if (this.testOrder) {
      this.order.testOrder = true;
    }

    try {
      await firstValueFrom(
        this.backendService.post('/orders', {
          ...this.order,
          operationId: this.operationId,
        }),
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        const errorMessage = createErrorMessage(e);
        this.errorService.reportError(errorMessage);
        this.dialogRef.close({ isCreated: false });
      }
      this.errorService.reportError('Failed to created order');
      console.error('Unknown error on order creation', e);
      throw e;
    }

    this.snackBar.open('Order was created', undefined, {
      verticalPosition: 'top',
      duration: SNACK_BAR_MSG_TIMEOUT,
    });
    this.dialogRef.close({ isCreated: true });
  }

  onCancelClick() {
    this.dialogRef.close(undefined);
  }

  selectPickup(selectedPickupName: string) {
    const selectedPickup = this.pickups.find(
      (d) =>
        d.displayName === selectedPickupName || d.name === selectedPickupName,
    );
    this.order.pickupHandover.locationId = selectedPickup?.name;
    this.selectedPickup = selectedPickupName;
  }

  selectDropoff(selectedDropoffName: string) {
    const selectedDropoff = this.dropoffs.find(
      (d) =>
        d.displayName === selectedDropoffName || d.name === selectedDropoffName,
    );
    this.order.dropoffHandover.locationId = selectedDropoff?.name;
    this.selectedDropoff = selectedDropoffName;
  }

  checkIfCanBeConfirmed(): boolean {
    const pickupSelectedByLatLon =
      this.order.pickupHandover.latitude !== undefined &&
      this.order.pickupHandover.longitude !== undefined;
    const dropoffSelectedByLatLon =
      this.order.dropoffHandover.latitude !== undefined &&
      this.order.dropoffHandover.longitude !== undefined;
    const pickupSelectedByLocationId =
      this.order.pickupHandover.locationId !== undefined;
    const dropoffSelectedByLocationId =
      this.order.dropoffHandover.locationId !== undefined;

    return (
      (pickupSelectedByLatLon || pickupSelectedByLocationId) &&
      (dropoffSelectedByLatLon || dropoffSelectedByLocationId)
    );
  }
}
