import { Component, input, signal } from '@angular/core';
import { RobotCommunication } from '@/app/core/robots-service/robot-communication';

import { PlaceholderComponent } from '@/app/core/placeholder/placeholder.component';
import { StatusTreeComponent } from '@/app/robots/robot-operation/status-tree/status-tree.component';

@Component({
  selector: 'app-optional-status-tree',
  templateUrl: './optional-status-tree.component.html',
  styleUrl: './optional-status-tree.component.sass',
  imports: [PlaceholderComponent, StatusTreeComponent],
})
export class OptionalStatusTreeComponent {
  robotCommunication = input.required<RobotCommunication>();
  isOpen = signal(false);
}
