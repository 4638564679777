import { Viewport } from '@deck.gl/core';
import {
  Bounds,
  TileBoundingBox,
  ZRange,
} from '@deck.gl/geo-layers/dist/tileset-2d';
import { Ellipsoid } from '@math.gl/geospatial';

export type TileIndex = { x: number; y: number; z: number };

const { min, max, ceil, floor } = Math;

// gets the bounding box of a viewport
function getBoundingBox(
  viewport: Viewport,
  zRange: ZRange | undefined,
  extent: Bounds,
): Bounds {
  let bounds: Bounds;
  if (zRange?.length === 2) {
    const [minZ, maxZ] = zRange;
    const bounds0 = viewport.getBounds({ z: minZ });
    const bounds1 = viewport.getBounds({ z: maxZ });
    bounds = [
      min(bounds0[0], bounds1[0]),
      min(bounds0[1], bounds1[1]),
      max(bounds0[2], bounds1[2]),
      max(bounds0[3], bounds1[3]),
    ];
  } else {
    bounds = viewport.getBounds();
  }
  return [
    max(bounds[0], extent[0]),
    max(bounds[1], extent[1]),
    min(bounds[2], extent[2]),
    min(bounds[3], extent[3]),
  ];
}

export function tileToBoundingBox(
  index: TileIndex,
  enuCoordinateOrigin: [number, number],
  tileSize: number,
): TileBoundingBox {
  const x = index.x * tileSize;
  const y = index.y * tileSize;

  const [west, south] = getLatLngAltFromEnu(
    [...enuCoordinateOrigin, 0],
    [x, y, 0],
  );
  const [east, north] = getLatLngAltFromEnu(
    [...enuCoordinateOrigin, 0],
    [x + tileSize, y + tileSize, 0],
  );

  return { west, south, east, north };
}

export function getLatLngAltFromEnu(
  enuOrigin: [number, number, number],
  enuOffsetMeters: [number, number, number],
): [number, number, number] {
  const ecefOrigin = Ellipsoid.WGS84.cartographicToCartesian(enuOrigin);
  const ecefTenu = Ellipsoid.WGS84.eastNorthUpToFixedFrame(ecefOrigin);
  const pointEcef = ecefTenu.transform(enuOffsetMeters);
  return Ellipsoid.WGS84.cartesianToCartographic(pointEcef) as [
    number,
    number,
    number,
  ];
}

export function getLngLatToMeterFactors([lng, lat]: [
  number,
  number,
  number?,
]): [number, number] {
  const [lng1] = getLatLngAltFromEnu([lng, lat, 0], [1, 0, 0]);
  const [, lat1] = getLatLngAltFromEnu([lng, lat, 0], [0, 1, 0]);
  return [1 / (lng1 - lng), 1 / (lat1 - lat)];
}

// Returns all tile indices in the current viewport. If the current zoom level is smaller
// than minZoom, return an empty array.
export function getTileIndices({
  viewport,
  minZoom,
  zRange,
  extent,
  tileSize,
}: {
  viewport: Viewport;
  maxZoom?: number;
  minZoom?: number;
  zRange?: ZRange;
  extent?: Bounds | null;
  tileSize?: number;
  zoomOffset?: number;
}) {
  if (!extent || !tileSize) {
    return [];
  }
  if (
    typeof minZoom === 'number' &&
    Number.isFinite(minZoom) &&
    viewport.zoom < minZoom
  ) {
    return [];
  }

  const [extentLng, extentLat] = extent;
  const [minLng, minLat, maxLng, maxLat] = getBoundingBox(
    viewport,
    zRange,
    extent,
  );

  const [lngFactor, latFactor] = getLngLatToMeterFactors([
    extentLng,
    extentLat,
  ]);

  const minLngIndex = floor(((minLng - extentLng) * lngFactor) / tileSize);
  const maxLngIndex = ceil(((maxLng - extentLng) * lngFactor) / tileSize);
  const minLatIndex = floor(((minLat - extentLat) * latFactor) / tileSize);
  const maxLatIndex = ceil(((maxLat - extentLat) * latFactor) / tileSize);

  const tileIndices: TileIndex[] = [];
  for (let x = minLngIndex; x < maxLngIndex && tileIndices.length < 200; x++) {
    for (
      let y = minLatIndex;
      y < maxLatIndex && tileIndices.length < 200;
      y++
    ) {
      tileIndices.push({ x, y, z: 0 });
    }
  }
  return tileIndices;
}
