import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { partition } from 'ramda';
import { BackendService } from '@/app/core/backend.service';
import {
  ConfirmationDialogData,
  ConfirmationDialog,
} from '@/app/core/confirmation-dialog/confirmation-dialog.component';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { isOnline } from '@/app/robots/robot-operation/robot-operator-view/robot-quick-add-dialog.component';
import { MatChipSet, MatChipOption } from '@angular/material/chips';

import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { MatSortHeader } from '@angular/material/sort';
import { BatteryStatusComponent } from '@/app/core/battery-status/battery-status.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { ConnectivityStatusComponent } from '@/app/core/connectivity-status/connectivity-status.component';
import { PrettyTimePipe } from '@/app/core/pipes/pretty-time.pipe';

const ERROR_SNACK_BAR_DURATION = 2000;

function hasNotArrived({ scheduledStops = [], arrivedAtStop }: Robot) {
  return scheduledStops.length > 0 && !arrivedAtStop;
}

function hasOperator({ controllingRobotOperatorId }: Robot) {
  return !!controllingRobotOperatorId;
}

function hasNoOperator(robot: Robot) {
  return !hasOperator(robot);
}

function isStanding(robot: Robot) {
  return robot.stopState?.isBlocked;
}

function getOperatorDisplayName(robot: Robot) {
  if (!robot.controllingRobotOperatorId) {
    return '';
  }
  return (
    robot.connectedRobotOperators?.find(
      (op) => op.id === robot.controllingRobotOperatorId,
    )?.displayName ?? ''
  );
}

interface RobotTableRow extends Robot {
  hasOrder: boolean;
  operatorDisplayName: string;
  batteryPercentage: number;
}

function robotToTableRow(robot: Robot): RobotTableRow {
  return {
    ...robot,
    hasOrder: robot.assignedOrderIds.length > 0,
    operatorDisplayName: getOperatorDisplayName(robot),
    batteryPercentage: robot.batteryPercentage ?? 0,
  };
}

class LazyRobotSet {
  private robotAddedTimestamps = new Map<string, number>();

  constructor(private setAcceptanceThresholdMillis: number) {}

  updateAndGetRobots(currentRobots: Robot[]): Robot[] {
    for (const [robotId] of this.robotAddedTimestamps) {
      if (!currentRobots.some((robot) => robot.id === robotId)) {
        this.robotAddedTimestamps.delete(robotId);
      }
    }

    for (const currentRobot of currentRobots) {
      if (!this.robotAddedTimestamps.has(currentRobot.id)) {
        this.robotAddedTimestamps.set(currentRobot.id, Date.now());
      }
    }

    return currentRobots.filter(
      (robot) =>
        Date.now() - (this.robotAddedTimestamps.get(robot.id) ?? Date.now()) >
        this.setAcceptanceThresholdMillis,
    );
  }
}

enum SelectedRobots {
  None = '',
  Online = 'online',
  Offline = 'offline',
  ReadyForOrders = 'readyForOrders',
  Blocked = 'blocked',
  NeedOperator = 'needOperator',
}

const COLUMNS_TO_DISPLAY_BLOCKED = [
  'robotView',
  'displayName',
  'batteryPercentage',
  'connectivity',
  'hasOrder',
  'blockedSince',
  'readyForOrders',
];

const COLUMNS_TO_DISPLAY_DEFAULT = [
  'robotView',
  'displayName',
  'batteryPercentage',
  'connectivity',
  'hasOrder',
  'readyForOrders',
];

@Component({
  selector: 'app-operation-robots-stats',
  templateUrl: './operation-robots-stats.component.html',
  styleUrl: './operation-robots-stats.component.sass',
  imports: [
    MatChipSet,
    MatChipOption,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    BatteryStatusComponent,
    MatSlideToggle,
    FormsModule,
    MatIcon,
    MatTooltip,
    ConnectivityStatusComponent,
    MatRowDef,
    MatRow,
    PrettyTimePipe,
  ],
})
export class OperationRobotsStatsComponent {
  allRobots: Robot[] = [];
  onlineRobots: Robot[] = [];
  offlineRobots: Robot[] = [];
  readyForOrdersRobots: Robot[] = [];
  blockedRobots: Robot[] = [];
  needOperatorRobots: Robot[] = [];
  displayedRobots: RobotTableRow[] = [];

  needOperatorRobotTracker = new LazyRobotSet(11 * 1000);

  selected: SelectedRobots = SelectedRobots.None;
  offlineRobotInOps = false;

  columnsToDisplay = COLUMNS_TO_DISPLAY_DEFAULT;

  constructor(
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  @Input()
  set robots(robots: Robot[]) {
    this.allRobots = robots;
    const now = Date.now();
    [this.onlineRobots, this.offlineRobots] = partition(
      isOnline(now),
      this.allRobots,
    );

    this.offlineRobotInOps = this.offlineRobots.some(
      (robot) => robot.readyForOrders,
    );

    this.readyForOrdersRobots = this.allRobots.filter(
      ({ readyForOrders }) => readyForOrders,
    );

    this.blockedRobots = this.onlineRobots.filter(
      ({ stopState }) => stopState?.isBlocked === true,
    );

    const currentlyNeedOperatorRobots = this.onlineRobots
      .filter(hasNoOperator)
      .filter(hasNotArrived)
      .filter(isStanding);

    this.needOperatorRobots = this.needOperatorRobotTracker.updateAndGetRobots(
      currentlyNeedOperatorRobots,
    );
    this.updateDisplayedRobots();
  }

  private updateDisplayedRobots() {
    this.columnsToDisplay = COLUMNS_TO_DISPLAY_DEFAULT;
    switch (this.selected) {
      case SelectedRobots.None:
        this.displayedRobots = [];
        break;

      case SelectedRobots.Online:
        this.displayedRobots = this.onlineRobots.map((r) => robotToTableRow(r));
        break;

      case SelectedRobots.Offline:
        this.displayedRobots = this.offlineRobots.map((r) =>
          robotToTableRow(r),
        );
        break;

      case SelectedRobots.ReadyForOrders:
        this.displayedRobots = this.readyForOrdersRobots.map((r) =>
          robotToTableRow(r),
        );
        break;

      case SelectedRobots.Blocked:
        this.displayedRobots = this.blockedRobots.map((r) =>
          robotToTableRow(r),
        );
        this.columnsToDisplay = COLUMNS_TO_DISPLAY_BLOCKED;
        break;

      case SelectedRobots.NeedOperator:
        this.displayedRobots = this.needOperatorRobots.map((r) =>
          robotToTableRow(r),
        );
        break;
    }
  }

  toggleSelection(selectedRobots: string) {
    const newlySelectedOption = selectedRobots as SelectedRobots;
    if (newlySelectedOption === this.selected) {
      this.selected = SelectedRobots.None;
    } else {
      this.selected = newlySelectedOption;
    }
    this.updateDisplayedRobots();
  }

  transform(image: Buffer) {
    return this.sanitizer.bypassSecurityTrustUrl(
      'data:image/jpeg;base64,' + image.toString('base64'),
    );
  }

  toggleRobotReadyForOrder(robot: Robot) {
    const isReady = !robot.readyForOrders;
    const robotName = `Cart ${robot.serialNumber}`;
    const confirmationDialogMessage = isReady
      ? `Set robot ${robotName} ready for order`
      : `Set robot ${robotName} not ready for order`;

    this.dialog
      .open<ConfirmationDialog, ConfirmationDialogData>(ConfirmationDialog, {
        data: {
          message: confirmationDialogMessage,
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (!isConfirmed) {
          return;
        }
        try {
          await this.backendService
            .post(`/robots/${robot.id}/set-ready-for-orders`, {
              readyForOrders: isReady,
            })
            .toPromise();
        } catch (e) {
          const errorMessage = `Failed to set ${robotName} ready for orders state to '${isReady}'`;
          console.error(errorMessage, e);
          this.snackBar.open(errorMessage, undefined, {
            duration: ERROR_SNACK_BAR_DURATION,
          });
        }
      });
  }
}
