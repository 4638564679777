import * as v from 'valibot';
import { PolygonGeometry } from '../geometry';
import { mapElementType, ElementType } from './base';
import {
  LocalizationMapTilesProperties,
  OperationRegionProperties,
  SlippyTilesProperties,
} from './properties';

export const OperationRegion = mapElementType(
  ElementType.OPERATION_REGION,
  OperationRegionProperties,
  PolygonGeometry,
);
export type OperationRegion = v.InferOutput<typeof OperationRegion>;

export const LocalizationMapTiles = mapElementType(
  ElementType.LOCALIZATION_MAP_TILES,
  LocalizationMapTilesProperties,
  PolygonGeometry,
);
export type LocalizationMapTiles = v.InferOutput<typeof LocalizationMapTiles>;

export const SlippyTiles = mapElementType(
  ElementType.SLIPPY_TILES,
  SlippyTilesProperties,
  PolygonGeometry,
);
export type SlippyTiles = v.InferOutput<typeof SlippyTiles>;

export const TileElement = v.variant('elementType', [
  LocalizationMapTiles,
  SlippyTiles,
]);
export type TileElement = v.InferOutput<typeof TileElement>;
