import moment from 'moment';
import 'moment-timezone';
import { Component, computed, input, output, signal } from '@angular/core';
import {
  rangeIsValid,
  momentToTotalMinutes,
  minutesToTimeString,
  closestAvailableSlot,
} from './operation-time-range-utils';
import { MatIcon } from '@angular/material/icon';
import { clamp } from '@/utils/clamp';
import { createDragHandler } from '@/utils/create-drag-handler';
import { getWeekday, Weekday, weekdayDisplayName, weekdays } from '../weekday';
import { DAY_MINS, OperationTimeRange, WeeklySchedule } from '../operation';

function totalMinutesToDayPercent(totalMinutes: number) {
  return `${((100 * totalMinutes) / DAY_MINS).toFixed(2)}%`;
}

function roundMinutesToHalfHourSteps(minutes: number) {
  return Math.round(minutes / 30) * 30;
}

function pointerEventToMinutes(event: PointerEvent) {
  const target = event.target;
  if (!(target instanceof HTMLElement)) {
    throw new Error(`Target element is not HTMLElement in 'getDayRatio'.`);
  }
  const timelineElement = target.closest('.timeline');
  if (!(timelineElement instanceof HTMLElement)) {
    throw new Error(`Did not find .timeline element in 'getDayRatio'.`);
  }
  const { top, height } = timelineElement.getBoundingClientRect();
  const minutes = (DAY_MINS * (event.clientY - top)) / height;
  return clamp(0, DAY_MINS, roundMinutesToHalfHourSteps(minutes));
}

@Component({
  standalone: true,
  selector: 'app-weekly-calendar',
  templateUrl: './weekly-calendar.component.html',
  styleUrl: './weekly-calendar.component.sass',
  imports: [MatIcon],
})
export class WeeklyCalendarComponent {
  readonly weekdays = weekdays;

  timeZone = input.required<string>();
  maxRobotsInOps = input.required<number | null | undefined>();
  weeklySchedule = input.required<WeeklySchedule>();
  previewRange = signal<
    | { weekday: Weekday; index: number | undefined; range: OperationTimeRange }
    | undefined
  >(undefined);

  addRange = output<{ weekday: Weekday; range: OperationTimeRange }>();
  editRange = output<{
    weekday: Weekday;
    index: number;
    range: OperationTimeRange;
  }>();
  focusRange = output<{ weekday: Weekday; index: number }>();
  removeRange = output<{ weekday: Weekday; index: number }>();

  expectedRobotCountIsValid(range: OperationTimeRange) {
    return (
      (range.expectedRobotsInOps ?? Infinity) <=
      (this.maxRobotsInOps() ?? Infinity)
    );
  }

  readonly addRangeHandler = createDragHandler<{ weekday: Weekday }>(
    (ev, { weekday }) => {
      if (ev.target !== ev.currentTarget) {
        // user did not pointerdown on the timeline element itself
        // but a child. The issue is that we don't stopPropagation
        // of pointerdown, only clicks.
        return;
      }
      let startMins = pointerEventToMinutes(ev);
      let endMins = startMins;
      this.previewRange.set({
        weekday,
        index: undefined,
        range: { startMins, endMins, expectedRobotsInOps: undefined },
      });
      return {
        onMove: (ev) => {
          endMins = pointerEventToMinutes(ev);
          startMins = Math.min(startMins, endMins);
          const previewRange = this.previewRange();
          if (!previewRange) {
            return;
          }
          this.previewRange.set({
            weekday,
            index: undefined,
            range: {
              startMins,
              endMins,
              expectedRobotsInOps: this.maxRobotsInOps() ?? undefined,
            },
          });
        },
        onDone: () => {
          const range: OperationTimeRange = {
            startMins,
            endMins,
            expectedRobotsInOps: this.maxRobotsInOps() ?? undefined,
          };
          if (rangeIsValid(this.weeklySchedule()[weekday], range, undefined)) {
            this.addRange.emit({
              weekday,
              range,
            });
          }
          this.previewRange.set(undefined);
        },
      };
    },
  );

  readonly moveRangeHandler = createDragHandler<{
    weekday: Weekday;
    index: number;
  }>((ev, { weekday, index }) => {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      console.warn(
        `OperationTimeRange not found for ${weekday} at index ${index}`,
      );
      return;
    }

    const initialMins = pointerEventToMinutes(ev);
    this.previewRange.set({
      weekday,
      index,
      range,
    });

    return {
      onMove: (ev) => {
        const diffMins = pointerEventToMinutes(ev) - initialMins;
        const moveMins = clamp(
          -range.startMins,
          DAY_MINS - range.endMins,
          diffMins,
        );
        const query: OperationTimeRange = {
          startMins: range.startMins + moveMins,
          endMins: range.endMins + moveMins,
          expectedRobotsInOps: range.expectedRobotsInOps,
        };
        const ranges = this.weeklySchedule()[weekday];
        const closestFreeRange = closestAvailableSlot(ranges, query, index);
        this.previewRange.set({
          weekday,
          index,
          range: closestFreeRange ?? query,
        });
      },
      onDone: () => {
        const range = this.previewRange();
        if (
          range &&
          rangeIsValid(this.weeklySchedule()[weekday], range.range, index)
        ) {
          this.editRange.emit({ ...range, index });
        }
        this.previewRange.set(undefined);
      },
    };
  });

  readonly moveRangeStartHandler = createDragHandler<{
    weekday: Weekday;
    index: number;
  }>((ev, { weekday, index }) => {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      console.warn(
        `OperationTimeRange not found for ${weekday} at index ${index}`,
      );
      return;
    }

    const initialMins = pointerEventToMinutes(ev);
    this.previewRange.set({
      weekday,
      index,
      range,
    });

    return {
      onMove: (ev) => {
        const diffMins = pointerEventToMinutes(ev) - initialMins;
        this.previewRange.set({
          weekday,
          index,
          range: {
            ...range,
            startMins: clamp(0, range.endMins, range.startMins + diffMins),
          },
        });
      },
      onDone: () => {
        const range = this.previewRange();
        if (
          range &&
          rangeIsValid(this.weeklySchedule()[weekday], range.range, index)
        ) {
          this.editRange.emit({ ...range, index });
        }
        this.previewRange.set(undefined);
      },
    };
  });

  readonly moveRangeEndHandler = createDragHandler<{
    weekday: Weekday;
    index: number;
  }>((ev, { weekday, index }) => {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      console.warn(
        `OperationTimeRange not found for ${weekday} at index ${index}`,
      );
      return;
    }

    const initialMins = pointerEventToMinutes(ev);
    this.previewRange.set({
      weekday,
      index,
      range,
    });

    return {
      onMove: (ev) => {
        const diffMins = pointerEventToMinutes(ev) - initialMins;
        this.previewRange.set({
          weekday,
          index,
          range: {
            ...range,
            endMins: clamp(range.startMins, DAY_MINS, range.endMins + diffMins),
          },
        });
      },
      onDone: () => {
        const range = this.previewRange();
        if (
          range &&
          rangeIsValid(this.weeklySchedule()[weekday], range.range, index)
        ) {
          this.editRange.emit({ ...range, index });
        }
        this.previewRange.set(undefined);
      },
    };
  });

  readonly timelineTicksMins = Array.from({ length: 24 }).map((_, i) => i * 60);
  readonly totalMinutesToDayPercent = totalMinutesToDayPercent;
  readonly minutesToTimeString = minutesToTimeString;
  readonly rangeIsValid = rangeIsValid;
  readonly weekdayDisplayName = weekdayDisplayName;

  todayWeekday = computed(() =>
    getWeekday(moment(new Date()).tz(this.timeZone()).day()),
  );
  timeNowDayPercent = computed(() =>
    totalMinutesToDayPercent(
      momentToTotalMinutes(moment.tz(new Date(), this.timeZone())),
    ),
  );

  eventStyleVars(range: OperationTimeRange) {
    return {
      '--start-day-percent': totalMinutesToDayPercent(range.startMins),
      '--end-day-percent': totalMinutesToDayPercent(range.endMins),
    };
  }
}
