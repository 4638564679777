import { Component, computed } from '@angular/core';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MapElementManager } from '../../map-elements/map-element-manager';
import { isTileElement, TileElement } from '@cartken/map-types';
import { MatIcon } from '@angular/material/icon';
import { produce } from 'immer';
import { MatButton } from '@angular/material/button';
import { VisualizationManager } from '../../visualization/visualization-manager';
import { getBoundingBox } from '../../map-elements/bounding-box-helpers';

function tileTypeDisplayName(element: TileElement) {
  switch (element.elementType) {
    case 'SlippyTiles':
      return 'slippy';
    case 'LocalizationMapTiles':
      return 'localization';
  }
  // Reachable if we introduce a new type, but the person is using
  // old frontend, for example.
  return 'unknown type';
}

@Component({
  standalone: true,
  selector: 'app-edit-tile-component',
  templateUrl: './edit-tile.component.html',
  styleUrl: './edit-tile.component.sass',
  imports: [MatCard, MatCardTitle, MatCardContent, MatButton, MatIcon],
})
export class EditTileComponent {
  readonly tileTypeDisplayName = tileTypeDisplayName;

  mapElementManager!: MapElementManager;
  visualizationManager!: VisualizationManager;

  tiles = computed(() =>
    this.mapElementManager
      .displayedMapElements()
      .filter(isTileElement)
      .sort((a, b) =>
        // sort asc by url because this effectively sorts them by creation date
        // and kinda groups different locations
        b.properties.tilesBaseUrl.localeCompare(a.properties.tilesBaseUrl),
      ),
  );

  selectTile(id: number) {
    const element = this.mapElementManager.getMapElement(id);
    if (!element || !isTileElement(element)) {
      return;
    }
    if (element === this.mapElementManager.selectedMapElement()) {
      // deselect if same element is already selected
      this.mapElementManager.selectElementById(undefined);
      return;
    }
    this.mapElementManager.selectElementById(id);
    const bounds = getBoundingBox(element);
    if (bounds) {
      this.visualizationManager.fitBounds(bounds);
    }
  }

  deleteTile(id: number) {
    const element = this.mapElementManager.getMapElement(id);
    if (!element || !isTileElement(element)) {
      return;
    }
    this.mapElementManager.history.addChange([
      produce(element, (draft) => {
        draft.deleted = true;
      }),
    ]);
  }
}
