import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ElementType } from '@cartken/map-types';
import { LayerName } from '../visualization/visualization-styles';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { MatDivider } from '@angular/material/divider';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatButton } from '@angular/material/button';

export interface VisibilityEntry {
  displayName: string;
  elementTypes: ElementType[];
  visible: boolean;
}

export interface LocalizationMapVisibility {
  visible: boolean;
  opacity: number;
}

export interface VisibilityDialogInput {
  visibilityEntries: VisibilityEntry[];
  slippyTilesOpacities: Record<LayerName, number>;
}

@Component({
  selector: 'app-visibility-dialog',
  templateUrl: './visibility-dialog.component.html',
  styleUrl: './visibility-dialog.component.sass',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatSlideToggle,
    FormsModule,
    MatDivider,
    MatSlider,
    MatSliderThumb,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class VisibilityDialogComponent {
  slippyTilesNames = Object.values(LayerName);

  constructor(
    public dialogRef: MatDialogRef<VisibilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public visibility: VisibilityDialogInput,
  ) {}
}
