import { v } from '@/valibot';
import { RobotEdge, RobotQueueEdge } from '@cartken/map-types';
import { BackendService } from '@/app/core/backend.service';
import { GlobalPose } from '@/app/core/robots-service/webrtc/types';
import { RouteDto } from '@/app/core/robots-service/backend/types';
import { map } from 'rxjs';

const flagIcon = {
  url: 'assets/flag.svg',
  scaledSize: new google.maps.Size(40, 40),
  anchor: new google.maps.Point(9, 36),
};

export class MapOverlay {
  private _route: RouteDto | undefined;
  private robotMapLayer: any;
  private robotMapMarker = new google.maps.Marker();
  private _robotGlobalPose?: GlobalPose;

  private readonly robotIcon = {
    scale: 5,
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    strokeWeight: 2,
    strokeColor: '#B40404',
    rotation: 0,
  };

  get route(): RouteDto | undefined {
    return this._route;
  }

  set route(route: RouteDto | undefined) {
    this.setRoute(route);
  }

  get robotGlobalPose(): GlobalPose | undefined {
    return this._robotGlobalPose;
  }

  set robotGlobalPose(robotGlobalPose: GlobalPose | undefined) {
    this.setRobotGlobalPose(robotGlobalPose);
  }

  private flagMarker: google.maps.Marker;

  constructor(
    private map: google.maps.Map,
    private backendService: BackendService,
    private showMapLayer: boolean,
  ) {
    this.setMapStyle();
    this.map.addListener('idle', () => {
      if (showMapLayer) {
        this.reloadMapLayer();
      } else {
        this.refreshOverlay();
      }
    });
    this.flagMarker = new google.maps.Marker({
      map: this.map,
      icon: flagIcon,
    });
  }

  setRoute(route: RouteDto | undefined) {
    this._route = route;
    this.refreshOverlay();
  }

  fitBoundsToRoute() {
    if (
      !this.route ||
      !this.route.geometry ||
      this.route.geometry.length === 0
    ) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    this.route.geometry.forEach((p) =>
      bounds.extend({ lat: p.latitude, lng: p.longitude }),
    );
    this.map.fitBounds(bounds);
  }

  setRobotGlobalPose(globalPose?: GlobalPose) {
    this._robotGlobalPose = globalPose;
    if (!this._robotGlobalPose) {
      this.robotMapMarker.setMap(null);
      this.map.setCenter(new google.maps.LatLng(0, 0));
      this.map.setZoom(1);
      return;
    }
    const latLng = new google.maps.LatLng(
      this._robotGlobalPose.latitude,
      this._robotGlobalPose.longitude,
    );
    this.map.panTo(latLng);
    this.robotMapMarker.setPosition(latLng);
    if (this._robotGlobalPose.heading) {
      this.robotIcon.rotation = this._robotGlobalPose.heading;
    } else {
      this.robotIcon.rotation = 0;
    }
    this.robotMapMarker.setIcon(this.robotIcon);
    this.robotMapMarker.setMap(this.map);
    if ((this.map.getZoom() ?? 0) <= 11) {
      this.map.setZoom(17);
    }
  }

  private setMapStyle() {
    if (!this.map) {
      return;
    }
    this.map.data.setStyle((feature: google.maps.Data.Feature) => {
      if (feature.getProperty('isRoute')) {
        return { strokeColor: 'lime', strokeWeight: 4 };
      }
      let color = 'LightGray';
      let strokeWeight = 2;
      if (feature.getProperty('isIntersection')) {
        color = 'orange';
        strokeWeight = 5;
        if (feature.getProperty('hasTrafficLightControl')) {
          color = 'red';
          strokeWeight = 6;
        }
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 2,
          strokeColor: 'blue',
          fillColor: 'blue',
        },
      };
    });
  }

  private reloadMapLayer() {
    const bounds = this.map.getBounds();
    if (!bounds) {
      return;
    }
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const neLat = ne.lat();
    const neLng = ne.lng();
    const swLat = sw.lat();
    const swLng = sw.lng();

    const regionPolygon = [
      [
        [swLng, swLat],
        [neLng, swLat],
        [neLng, neLat],
        [swLng, neLat],
        [swLng, swLat],
      ],
    ];
    const backendPath = `/map?region-polygon=${JSON.stringify(
      regionPolygon,
    )}&element-types=RobotEdge,RobotQueueEdge`;
    this.backendService
      .get(backendPath)
      .pipe(
        map((x) =>
          v.parse(
            v.array(v.variant('elementType', [RobotEdge, RobotQueueEdge])),
            x,
          ),
        ),
      )
      .subscribe((mapElements) => {
        this.robotMapLayer = {
          type: 'FeatureCollection',
          features: mapElements
            .filter((mapElement) => !mapElement.deleted)
            .map((mapElement) => ({
              ...mapElement,
              type: 'Feature',
            })),
        };
        this.refreshOverlay();
      });
  }

  private refreshOverlay() {
    // clear current data layer
    this.map.data.forEach((feature) => this.map.data.remove(feature));

    if (this.showMapLayer && this.robotMapLayer) {
      this.map.data.addGeoJson(this.robotMapLayer);
    }
    if (!this._route?.geometry) {
      return;
    }
    this.map.data.addGeoJson({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: { isRoute: true },
          geometry: {
            type: 'LineString',
            coordinates: this._route.geometry.map((point) => {
              return [point.longitude, point.latitude];
            }),
          },
        },
      ],
    });

    const lastPoint = this._route.geometry.at(-1);
    if (lastPoint !== undefined) {
      this.flagMarker.setPosition(
        new google.maps.LatLng(lastPoint.latitude, lastPoint.longitude),
      );
    }
  }
}
