import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

export interface SaveChangesetDialogData {
  title?: string;
  description?: string;
}

@Component({
  selector: 'app-save-changeset-dialog',
  templateUrl: 'save-changeset-dialog.component.html',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class SaveChangesetDialogComponent {
  selectedOperationRegionId = '';

  constructor(
    public dialogRef: MatDialogRef<SaveChangesetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SaveChangesetDialogData,
  ) {}
}
