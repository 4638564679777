import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CollectionDto, FrameDto, SnippetDto } from '../ml-data-types';

import { MetadataComponent } from './metadata.component';
import { GridImgItemComponent } from './grid-img-item.component';

type DataItem = FrameDto | SnippetDto | CollectionDto;

@Component({
  selector: 'app-selected-item',
  templateUrl: './selected-item.component.html',
  styleUrl: './selected-item.component.sass',
  imports: [MetadataComponent, GridImgItemComponent],
})
export class SelectedItemComponent {
  @Input()
  selectedItem?: DataItem = undefined;

  @Input()
  metadataItem?: DataItem = undefined;

  @Input()
  previewImage?: string = undefined;

  @Input()
  previewVideo?: string = undefined;

  @Input()
  snippetFrames: FrameDto[] = [];

  @Output()
  selectItem = new EventEmitter<FrameDto | SnippetDto>();
}
