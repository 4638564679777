import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-create-custom-field-map-element-dialog',
  templateUrl: './create-custom-field-map-element-dialog.component.html',
  styleUrl: './create-custom-field-map-element-dialog.component.sass',
  imports: [FormsModule, MatButton],
})
export class CreateCustomFieldMapElementDialogComponent {
  key = '';
  value = '';

  constructor(
    private readonly dialogRef: MatDialogRef<CreateCustomFieldMapElementDialogComponent>,
  ) {}

  async onSubmit() {
    this.dialogRef.close({
      key: this.key,
      value: this.value,
    });
  }
}
