import { Component, input, Input, output } from '@angular/core';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { RobotSystemStatus } from '@/app/core/robots-service/backend/types';
import { DecimalPipe, NgStyle } from '@angular/common';
import { BatteryStatusComponent } from '@/app/core/battery-status/battery-status.component';
import { MatIcon } from '@angular/material/icon';

type RobotSystemStatusText = {
  type: string;
  text: string;
  originalMessage: string;
};

@Component({
  selector: 'app-robot-text-layout',
  templateUrl: './robot-text-layout.component.html',
  styleUrl: './robot-text-layout.component.sass',
  imports: [BatteryStatusComponent, MatIcon, DecimalPipe, NgStyle],
})
export class RobotTextLayoutComponent {
  readonly active = input.required<boolean>();
  readonly batteryPercentage = input.required<number>();
  readonly isCharging = input.required<boolean | undefined>();
  readonly operationEmergencyStopActive = input.required<boolean | undefined>();
  readonly robotState = input.required<Robot | undefined>();
  readonly robotStateReport = input.required<string | undefined>();
  readonly statusStrings = input.required<string[] | undefined>();
  readonly supervisionState = input.required<string | undefined>();
  readonly userClaimReport = input.required<string | undefined>();

  readonly statusClick = output<string>();

  attentionStatusListTexts?: RobotSystemStatusText[];

  @Input()
  set attentionStatusList(attentionStatusList: RobotSystemStatus[]) {
    const attentionStatusListTexts = attentionStatusList.map(
      (status): RobotSystemStatusText => ({
        text: `${(status.type ?? '')?.toLocaleUpperCase()} [${status.name}] ${
          status.message
        }`,
        originalMessage: status.message,
        type: status.type ?? 'attention',
      }),
    );
    this.attentionStatusListTexts = this.sortAttentionStatusList(
      attentionStatusListTexts,
    );
  }

  private sortAttentionStatusList(
    attentionStatusList: RobotSystemStatusText[],
  ): RobotSystemStatusText[] {
    return attentionStatusList.sort(
      (s1: RobotSystemStatusText, s2: RobotSystemStatusText) => {
        const str1 = s1.text;
        const str2 = s2.text;
        return str1.localeCompare(str2);
      },
    );
  }
}
