<mat-card-title>Edit Tiles</mat-card-title>
<mat-card-content class="tiles">
  @for (tile of tiles(); track tile.id) {
    <mat-card
      class="tile-card"
      [class.active]="mapElementManager.selectedMapElementId() === tile.id"
      (click)="selectTile(tile.id)"
    >
      <div class="tile-title">
        {{ tile.properties.name }} ({{ tileTypeDisplayName(tile) }})
        <button mat-button (click)="deleteTile(tile.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="tile-properties">
        <span>id: {{ tile.id }}</span>
        <span>{{ tile.properties.tilesBaseUrl }}</span>
      </div>
    </mat-card>
  }
</mat-card-content>
