import { vec2 } from '@tlaukkan/tsm';
import {
  MouseDriveEvent,
  ZERO_SPEED_HEIGHT_FRACTION,
} from '../common/mouse-canvas-events';

const { floor, min } = Math;

const YELLOW = 'rgba(255,255,0,1)';
export class RobotCanvas {
  private readonly canvasContext: CanvasRenderingContext2D;
  private readonly observer = new ResizeObserver((entries) => {
    const entry = entries[0]?.devicePixelContentBoxSize[0];
    if (!entry) {
      return;
    }
    this.canvas.width = entry.inlineSize;
    this.canvas.height = entry.blockSize;
    // immediately draw the video, so we don't get black frame while resizing
    this.drawVideo();
  });

  constructor(
    private readonly canvas: HTMLCanvasElement,
    private readonly video: HTMLVideoElement,
  ) {
    const canvasContext = this.canvas.getContext('2d');
    if (!canvasContext) {
      throw new Error(`Could not get Canvas2D context.`);
    }
    this.canvasContext = canvasContext;
    this.observer.observe(canvas);
  }

  destroy() {
    this.observer.disconnect();
  }

  private drawVideo() {
    const { width, height } = this.canvas;
    this.canvasContext.clearRect(0, 0, width, height);
    this.canvasContext.drawImage(this.video, 0, 0, width, height);
  }

  private drawManualControlCoordinateLines() {
    const { width, height } = this.canvas;
    this.canvasContext.save();
    this.canvasContext.beginPath();
    this.canvasContext.setLineDash([5, 6]);
    // -0.5 aligns the dash with pixels for crisp rendering
    this.canvasContext.moveTo(0, -height - 0.5);
    this.canvasContext.lineTo(0, height);
    this.canvasContext.moveTo(-width - 0.5, 0);
    this.canvasContext.lineTo(width, 0);
    this.canvasContext.strokeStyle = YELLOW;
    this.canvasContext.stroke();
    this.canvasContext.restore();
  }

  private drawManualControlVector(mouseDriveEvent: MouseDriveEvent) {
    const { width, height } = this.canvas;
    const speedVector = mouseDriveEvent.speedVector ?? new vec2([0, 0]);

    const x = speedVector.x / 2;
    const y = min(
      -speedVector.y * ZERO_SPEED_HEIGHT_FRACTION,
      ZERO_SPEED_HEIGHT_FRACTION,
    );

    const xInPx = floor(x * width);
    const yInPx = floor(y * height);

    this.canvasContext.save();
    this.canvasContext.strokeStyle = YELLOW;

    // Draw line to control dot.
    this.canvasContext.beginPath();
    this.canvasContext.setLineDash([]);
    this.canvasContext.moveTo(0, 0);
    this.canvasContext.lineTo(xInPx, yInPx);
    this.canvasContext.stroke();

    // Draw manual control dot.
    this.canvasContext.fillStyle = YELLOW;
    this.canvasContext.beginPath();
    this.canvasContext.arc(xInPx, yInPx, 8, 0, 2 * Math.PI, true);
    this.canvasContext.fill();
    this.canvasContext.restore();
  }

  private drawMouseControlView(mouseDriveEvent: MouseDriveEvent) {
    const { width, height } = this.canvas;
    const xDivider = floor(width / 2);
    const yDivider = floor(height * ZERO_SPEED_HEIGHT_FRACTION);

    this.canvasContext.save();
    // 0.5 makes the lines crisp!
    this.canvasContext.translate(xDivider - 0.5, yDivider - 0.5);
    this.drawManualControlCoordinateLines();
    this.drawManualControlVector(mouseDriveEvent);
    this.canvasContext.restore();
  }

  draw(
    active: boolean,
    manualMouseControl: boolean,
    mouseDriveEvent: MouseDriveEvent,
  ) {
    this.drawVideo();

    if (active && manualMouseControl) {
      this.drawMouseControlView(mouseDriveEvent);
    }
  }
}
