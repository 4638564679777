import moment from 'moment-timezone';
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BackendService } from '@/app/core/backend.service';
import { Order, OrderStatus, HandoverType } from '@/app/core/order/order';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of, Subject, fromEvent } from 'rxjs';
import {
  startWith,
  switchMap,
  catchError,
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { Operation, OperationType } from '@/app/operations/operation';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { OrderStatsDialog, StatsData } from './order-stats-dialog.component';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { isDefined } from '@/utils/typeGuards';
import { OrderDetailsDialogComponent } from '../order-details-dialog/order-details-dialog.component';
import { millisBetween } from '@/utils/millis-between';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

import { MatOption } from '@angular/material/core';
import { SelectionDropboxComponent } from '@/app/core/selection-dropbox/selection-dropbox.component';
import {
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDatepicker,
} from '@angular/material/datepicker';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DurationPipe } from '@/app/core/pipes/duration.pipe';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { OperationsService } from '@/app/core/operations-service';

interface HydratedOrder extends Order {
  localTime: string;
  assignedRobotDisplayName: string;
  pickups: string[];
  pickupsDisplayName: string[];
  pickupsLocationId: string[];
  pickupsAddress: string[];
  dropoffs: string[];
  dropoffsDisplayName: string[];
  dropoffsLocationId: string[];
  dropoffsAddress: string[];
  orderDuration: number;
  inWaitingForRobot?: number;
  inDrivingToPickup?: number;
  inWaitingForPickup?: number;
  inDrivingToDropoff?: number;
  inWaitingForDropoff?: number;
}

function millisecondsToDurationString(ms: number) {
  const totalSeconds = Math.round(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const padZeros = (num: number) => num.toString().padStart(2, '0');
  return `${padZeros(hours)}:${padZeros(minutes)}:${padZeros(
    totalSeconds % 60,
  )}`;
}

function accumulateMillisInOrderStatus(
  order: Order,
  status: OrderStatus,
  handoverType?: HandoverType,
) {
  let millis = 0;
  for (let i = 0; i < order.statusLog.length - 1; ++i) {
    const statusEntry = order.statusLog[i];
    if (statusEntry?.status !== status) {
      continue;
    }
    if (
      handoverType &&
      handoverType !== order.handovers[statusEntry.handoverIndex]?.handoverType
    ) {
      continue;
    }
    millis += millisBetween(
      new Date(statusEntry.timestamp),
      new Date(order.statusLog[i + 1]!.timestamp),
    );
  }
  return millis;
}

@Component({
  selector: 'app-orders-view',
  templateUrl: './orders-view.component.html',
  styleUrl: './orders-view.component.sass',
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    MatIcon,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    SelectionDropboxComponent,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatMiniFabButton,
    MatProgressSpinner,
    MatTable,
    MatTableExporterModule,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    DurationPipe,
  ],
})
export class OrdersViewComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'operationId',
    'orderId',
    'testOrder',
    'created',
    'status',
    'robotId',
    'pickups',
    'dropoffs',
    'duration',
    'waiting-for-robot',
    'driving-to-pickup',
    'waiting-for-pickup',
    'driving-to-dropoff',
    'waiting-for-dropoff',
  ];
  data = new MatTableDataSource<HydratedOrder>([]);

  resultsLength = 0;
  pageSizeOptions: number[] = [10, 25, 100];
  isLoadingResults = true;
  isGeneratingStats = false;
  testOrderFilter = '';

  operations: string[] = [];
  robots: Robot[] = [];

  readonly orderStatuses = Object.values(OrderStatus);
  readonly timeZones = [
    'America/New_York',
    'America/Los_Angeles',
    'Asia/Tokyo',
    'Europe/Berlin',
  ];

  startDate?: Date;
  endDate?: Date;
  operation?: string = '';
  status?: string;
  timeZone?: string;
  _newFilterData$ = new Subject();
  newFilterData$ = this._newFilterData$.asObservable();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('textSearchInput') textSearchInput!: ElementRef;

  constructor(
    private backendService: BackendService,
    private operationsService: OperationsService,
    private robotsBackendService: RobotsBackendService,
    public orderDialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.loadOperations();
    // If the user changes the filter, reset back to the first page.
    this.newFilterData$.subscribe(() => {
      this.paginator.pageIndex = 0;
    });

    fromEvent(this.textSearchInput.nativeElement, 'keyup')
      .pipe(
        filter((v) => !!v),
        debounceTime(250),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.refresh();
      });

    this.robotsBackendService.getRobots().subscribe((robots) => {
      this.robots = robots;
      merge(this.paginator.page, this.newFilterData$)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.backendService
              .getWithHeader(this.buildQueryString(/*isBulkRequest*/ false))
              .pipe(catchError(() => of(undefined)));
          }),
          map((data: HttpResponse<Order[]> | undefined) => {
            // Flip flag to show that loading has finished.
            this.isLoadingResults = false;

            if (data === undefined) {
              return [];
            }

            // Only refresh the result length if there is new data. In case of rate
            // limit errors, we do not want to reset the paginator to zero, as that
            // would prevent users from re-triggering requests.
            this.resultsLength = Number(data.headers.get('x-total-count'));
            return data.body;
          }),
          filter(isDefined),
        )
        .subscribe((orders: Order[]) => {
          this.data = new MatTableDataSource(
            orders.map((order) => this.hydrateOrder(order)),
          );
        });
    });
  }

  hydratedOrderToCsvRow(
    order: HydratedOrder,
    fields: string[],
    durationFields: string[],
  ): string {
    const values = fields.map((field) => {
      const value = order[field as keyof HydratedOrder];
      if (Array.isArray(value)) {
        return `"${value.join(' | ')}"`;
      }

      if (durationFields.includes(field) && typeof value === 'number') {
        return `"${millisecondsToDurationString(value)}"`;
      }

      return `"${value}"`;
    });
    const row = values.join('\t');

    return row + '\r\n';
  }

  async exportOrders() {
    const allOrders = (await this.loadAllOrders()).map((order) =>
      this.hydrateOrder(order),
    );

    let csvContent = '';

    const fields = [
      'localTime',
      'id',
      'externalId',
      'operationId',
      'assignedRobotId',
      'assignedRobotName',
      'assignedRobotDisplayName',
      'status',
      'currentHandoverIndex',
      'pickups',
      'pickupsDisplayName',
      'pickupsLocationId',
      'pickupsAddress',
      'dropoffs',
      'dropoffsDisplayName',
      'dropoffsLocationId',
      'dropoffsAddress',
      'orderDuration',
      'inWaitingForRobot',
      'inDrivingToPickup',
      'inWaitingForPickup',
      'inDrivingToDropoff',
      'inWaitingForDropoff',
      'failureReason',
      'rejectionReason',
      'rejectionDescription',
    ];

    const durationFields = [
      'orderDuration',
      'inWaitingForRobot',
      'inDrivingToPickup',
      'inWaitingForPickup',
      'inDrivingToDropoff',
      'inWaitingForDropoff',
    ];

    csvContent += fields.join('\t') + '\r\n';
    for (const order of allOrders) {
      csvContent += this.hydratedOrderToCsvRow(order, fields, durationFields);
    }

    const encodedUri = encodeURIComponent(csvContent);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodedUri,
    );

    downloadLink.setAttribute('download', 'orders.csv');
    document.body.appendChild(downloadLink); // Required for FF
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private hydrateOrder(order: Order): HydratedOrder {
    const robot = this.robots.find(
      (robot) => robot.id == order.assignedRobotId || '',
    );
    // override unchecked array index access warning, since array length is checked later
    const lastStatusLog = order.statusLog[order.statusLog.length - 1]!;
    const firstStatusLog = order.statusLog[0]!;
    if (order.statusLog.length === 0) {
      throw new Error(`Status log is empty ${order.id}`);
    }
    const orderDuration =
      new Date(lastStatusLog.timestamp).valueOf() -
      new Date(firstStatusLog.timestamp).valueOf();
    const pickups = order.handovers.filter(
      (h) => h.handoverType === HandoverType.PICKUP,
    );
    const dropoffs = order.handovers.filter(
      (h) => h.handoverType === HandoverType.DROPOFF,
    );

    const hydratedOrder: HydratedOrder = {
      ...order,
      assignedRobotDisplayName: `Cart ${robot?.serialNumber}`,
      pickups: pickups.map(
        (h) => h.displayName || h.locationId || h.address || 'UNKNOWN',
      ),
      pickupsAddress: pickups.map((pickup) => pickup.address ?? 'UNKNOWN'),
      pickupsDisplayName: pickups.map(
        (pickup) => pickup.displayName ?? 'UNKNOWN',
      ),
      pickupsLocationId: pickups.map(
        (pickup) => pickup.locationId ?? 'UNKNOWN',
      ),
      dropoffs: dropoffs.map(
        (h) => h.displayName || h.locationId || h.address || 'UNKNOWN',
      ),
      dropoffsDisplayName: dropoffs.map(
        (dropoff) => dropoff.displayName ?? 'UNKNOWN',
      ),
      dropoffsAddress: dropoffs.map((dropoff) => dropoff.address ?? 'UNKNOWN'),
      dropoffsLocationId: dropoffs.map(
        (dropoff) => dropoff.locationId ?? 'UNKNOWN',
      ),
      localTime: this.getLocalOrderTime(firstStatusLog.timestamp, 'llll'),
      orderDuration,
    };
    this.computeInStatusTimes(hydratedOrder);
    return hydratedOrder;
  }

  private buildQueryString(isBulkRequest = false) {
    let queryString = '/orders?';

    if (isBulkRequest) {
      queryString += 'bulk=true';
    } else {
      queryString += `per_page=${this.paginator.pageSize}&page=${this.paginator.pageIndex}`;
    }
    if (this.operation && this.operation !== undefined) {
      queryString += `&operation_id=${this.operation}`;
    }
    if (this.endDate) {
      queryString += `&before=${this.adjustDateForTimezoneQuery(this.endDate)}`;
    }
    if (this.startDate) {
      queryString += `&after=${this.adjustDateForTimezoneQuery(
        this.startDate,
      )}`;
    }
    if (this.status && this.status.length) {
      queryString += `&status=${this.status}`;
    }
    if (this.testOrderFilter) {
      queryString += `&test_orders=${this.testOrderFilter}`;
    }
    if (this.textSearchInput.nativeElement.value) {
      queryString += `&text_match=${this.textSearchInput.nativeElement.value}`;
    }
    return queryString;
  }

  refresh() {
    this._newFilterData$.next(undefined);
  }

  showOrderDetails(order: Order) {
    this.orderDialog.open(OrderDetailsDialogComponent, {
      data: order,
    });
  }

  private adjustDateForTimezoneQuery(date: Date) {
    return moment
      .tz(
        {
          year: date.getFullYear(),
          month: date.getMonth(),
          date: date.getDate(),
        },
        this.timeZone ?? moment.tz.guess(),
      )
      .toDate()
      .toISOString();
  }

  async showStats() {
    this.isGeneratingStats = true;
    const ordersArray = await this.loadAllOrders();

    const statsData: StatsData = {
      orders: ordersArray.flat(),
      timeZone: this.timeZone || moment.tz.guess(),
      robots: this.robots,
    };
    const ref = this.orderDialog.open(OrderStatsDialog, {
      data: statsData,
    });
    ref.afterClosed().subscribe(() => (this.isGeneratingStats = false));
  }

  private getLocalOrderTime(timestamp: string, format?: string) {
    const t = this.timeZone
      ? moment(timestamp).tz(this.timeZone)
      : moment(timestamp);
    if (format) {
      return t.format(format);
    }
    return t.format();
  }

  private computeInStatusTimes(order: HydratedOrder) {
    order.inWaitingForRobot = order.inDrivingToPickup =
      accumulateMillisInOrderStatus(order, OrderStatus.CREATED) +
      accumulateMillisInOrderStatus(order, OrderStatus.ASSIGNED);
    order.inDrivingToPickup = accumulateMillisInOrderStatus(
      order,
      OrderStatus.DRIVING_TO_HANDOVER,
      HandoverType.PICKUP,
    );
    order.inDrivingToDropoff = accumulateMillisInOrderStatus(
      order,
      OrderStatus.DRIVING_TO_HANDOVER,
      HandoverType.DROPOFF,
    );
    order.inWaitingForPickup = accumulateMillisInOrderStatus(
      order,
      OrderStatus.WAITING_FOR_HANDOVER,
      HandoverType.PICKUP,
    );
    order.inWaitingForDropoff = accumulateMillisInOrderStatus(
      order,
      OrderStatus.WAITING_FOR_HANDOVER,
      HandoverType.DROPOFF,
    );
  }

  private loadOperations() {
    this.operationsService
      .getOperations()
      .subscribe((operations: Operation[]) => {
        this.operations = operations
          .filter(
            (operation) =>
              operation.operationType === OperationType.OrderOperation,
          )
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((operation) => operation.id);
      });
  }

  onTimeZoneChanged(event: MatSelectChange) {
    if (!this.timeZone) {
      const matSelect: MatSelect = event.source;
      matSelect.writeValue(undefined);
      this.timeZone = undefined;
    }
    this._newFilterData$.next(undefined);
  }

  onStartDateChanged(date: Date) {
    this.startDate = date;
    this._newFilterData$.next(undefined);
  }

  onEndDateChanged(date: Date) {
    const d = new Date(date);
    d.setHours(d.getHours() + 24);
    this.endDate = d;
    this._newFilterData$.next(undefined);
  }

  operationIdChanged(operationId?: string) {
    this.operation = operationId ?? '';
    this._newFilterData$.next(undefined);
  }

  orderStatusChanged(event: MatSelectChange) {
    if (this.status === '') {
      const matSelect: MatSelect = event.source;
      matSelect.writeValue(undefined);
      this.status = undefined;
    }
    this._newFilterData$.next(undefined);
  }

  testOrderFilteringChanged() {
    this._newFilterData$.next(undefined);
  }

  private async loadAllOrders(): Promise<Order[]> {
    const orders = await this.backendService
      .get(this.buildQueryString(/*isBulkRequest*/ true))
      .toPromise();

    return orders ?? [];
  }
}
