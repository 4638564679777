import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

import { MatOption } from '@angular/material/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';
import { allOperationTypes, CreateOperationData } from '../operation';

// there is always going to be at least one operation type
const defaultOperationType = allOperationTypes[0]!;

export interface CreateOperationDialogInput {
  availableRegions: string[];
}

@Component({
  selector: 'app-create-operation-dialog',
  templateUrl: './create-operation-dialog.component.html',
  styleUrl: './create-operation-dialog.component.sass',
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    MatSelect,
    MatOption,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class CreateOperationDialogComponent {
  readonly availableOperationTypes = allOperationTypes;
  operation: CreateOperationData = {
    id: '',
    accessGroups: [],
    operationType: defaultOperationType,
    operationData: {},
  };

  constructor(
    public dialogRef: MatDialogRef<CreateOperationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInput: CreateOperationDialogInput,
  ) {}

  onCancelClick() {
    this.dialogRef.close(undefined);
  }
}
