import { Component, Input } from '@angular/core';
import { orderStatusToString } from '@/app/operations/utils';

import { Handover, Order, OrderStatus } from '@/app/core/order/order';
import { formatCreated, formatIds } from '@/app/core/order/order-utils';

import { PrettyTimePipe } from '@/app/core/pipes/pretty-time.pipe';

export type OrderOverview = {
  id: string;
  status: string;
  created: Date;
  pickupName: string;
  pickupEta?: string;
  dropoffName: string;
  dropoffEta?: string;
  pickupRequestedAt?: string;
  dropoffRequestedAt?: string;
  compartmentId?: string;
};

function handoverName(handover?: Handover) {
  return handover?.displayName ?? 'UNKNOWN';
}

function handoverEta(handover?: Handover): string | undefined {
  return handover?.estimatedArrivalTime;
}

function requestedAt(handover?: Handover): string | undefined {
  return handover?.requestedAt;
}

function createOrderOverview(activeOrders: Order[]): OrderOverview[] {
  return activeOrders
    ?.filter((order) =>
      [
        OrderStatus.DRIVING_TO_HANDOVER,
        OrderStatus.WAITING_FOR_HANDOVER,
      ].includes(order.status),
    )
    .map((order) => {
      return <OrderOverview>{
        id: formatIds(order),
        created: formatCreated(order),
        pickupName: handoverName(order.handovers[0]),
        pickupEta: handoverEta(order.handovers[0]),
        dropoffName: handoverName(order.handovers[1]),
        dropoffEta: handoverEta(order.handovers[1]),
        status: orderStatusToString(order),
        compartmentId: order.assignedCompartmentId,
        pickupRequestedAt: requestedAt(order.handovers[0]),
        dropoffRequestedAt: requestedAt(order.handovers[1]),
      };
    });
}

@Component({
  selector: 'order-overlay-info',
  templateUrl: './order-overlay-info.component.html',
  styleUrl: './order-overlay-info.component.sass',
  imports: [PrettyTimePipe],
})
export class OrderOverlayInfoComponent {
  orderOverviews?: OrderOverview[];

  @Input()
  set order(orders: Order[] | undefined) {
    this.orderOverviews = createOrderOverview(orders ?? []);
  }
}
