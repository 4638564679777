<div class="container">
  @for (weekday of weekdays; track weekday) {
    @let weekdayRanges = weeklySchedule()[weekday];
    <div class="weekday-name">
      <span>{{ weekdayDisplayName(weekday) }}</span>
      @let totalHours = computeTotalHours(weekdayRanges);
      @if (totalHours !== undefined) {
        <span class="total-hours">
          {{ totalHours | number: '1.0-1' }} hours
        </span>
      }
    </div>
    @for (range of weekdayRanges; track $index) {
      <div class="weekday-range">
        <mat-form-field class="time-input">
          <mat-label>Start Time</mat-label>
          <input
            type="time"
            matInput
            [value]="minutesToTimeString(range.startMins)"
            (change)="setStartTime(weekday, $index, $any($event.target).value)"
          />
        </mat-form-field>
        <mat-form-field class="time-input">
          <mat-label>
            End Time
            {{ range.endMins === 24 * 60 ? '(end of day)' : null }}
          </mat-label>
          <input
            type="time"
            matInput
            [value]="minutesToTimeString(range.endMins)"
            (change)="setEndTime(weekday, $index, $any($event.target).value)"
          />
        </mat-form-field>
        <mat-form-field class="robot-count-input">
          <mat-label>Robot Count</mat-label>
          <input
            type="number"
            matInput
            step="1"
            [value]="range.expectedRobotsInOps"
            (change)="
              setExpectedRobotsInOps(weekday, $index, $any($event.target).value)
            "
            [id]="robotCountInputFocusId(weekday, $index)"
          />
        </mat-form-field>
        <button mat-icon-button (click)="removeRange(weekday, $index)">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    } @empty {
      <span class="no-operations">
        No Operations
        <mat-form-field class="hidden-input-for-keeping-the-same-size">
          <input type="time" matInput />
        </mat-form-field>
      </span>
    }
  }
  <div class="weekly-total">Weekly</div>
  <div class="weekly-total-value">
    {{ weeklyTotalHours() | number: '1.0-1' }} hours
  </div>
</div>

<app-weekly-calendar
  class="flex-grow"
  [timeZone]="timeZone()"
  [maxRobotsInOps]="maxRobotsInOps()"
  [weeklySchedule]="weeklySchedule()"
  (addRange)="addRange($event.weekday, $event.range)"
  (editRange)="editRange($event.weekday, $event.index, $event.range)"
  (focusRange)="focusRange($event.weekday, $event.index)"
  (removeRange)="removeRange($event.weekday, $event.index)"
/>
