import { sum } from '@/utils/sum';
import { DecimalPipe } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  inject,
  input,
  output,
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormField,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { produce } from 'immer';
import moment from 'moment';
import {
  computeTotalHours,
  minutesToTimeString,
  parseTimeStringToTotalMinutes,
  rangeIsValid,
} from './operation-time-range-utils';
import { WeeklyCalendarComponent } from './weekly-calendar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Weekday, weekdayDisplayName, weekdays } from '../weekday';
import { OperationTimeRange, WeeklySchedule } from '../operation';

@Component({
  standalone: true,
  selector: 'app-weekly-schedule-editor',
  templateUrl: './weekly-schedule-editor.component.html',
  styleUrl: './weekly-schedule-editor.component.sass',
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    MatIcon,
    MatIconButton,
    DecimalPipe,
    WeeklyCalendarComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ],
})
export class WeeklyScheduleEditorComponent {
  readonly weekdays = weekdays;
  readonly timeZones = moment.tz.names();
  readonly element = inject<ElementRef<HTMLElement>>(ElementRef);
  readonly snackbar = inject(MatSnackBar);

  timeZone = input.required<string>();
  maxRobotsInOps = input.required<number | null | undefined>();
  weeklySchedule = input.required<WeeklySchedule>();
  weeklyScheduleChange = output<WeeklySchedule>();

  weeklyTotalHours = computed(() =>
    sum(Object.values(this.weeklySchedule()).map(computeTotalHours)),
  );

  readonly computeTotalHours = computeTotalHours;
  readonly minutesToTimeString = minutesToTimeString;
  readonly weekdayDisplayName = weekdayDisplayName;

  addRange(weekday: Weekday, range: OperationTimeRange) {
    this.weeklyScheduleChange.emit(
      produce(this.weeklySchedule(), (draft) => {
        draft[weekday].push(range);
        draft[weekday].sort((a, b) => a.startMins - b.startMins);
      }),
    );

    // setTimeout to allow the element to render
    setTimeout(() => {
      this.focusRange(
        weekday,
        this.weeklySchedule()[weekday].findIndex((r) => r === range),
      );
    });
  }

  removeRange(weekday: Weekday, index: number) {
    this.weeklyScheduleChange.emit(
      produce(this.weeklySchedule(), (draft) => {
        draft[weekday].splice(index, 1);
      }),
    );
  }

  setStartTime(weekday: Weekday, index: number, start: string) {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      return;
    }
    const startMins = parseTimeStringToTotalMinutes(start, {
      isRangeEnd: false,
    });
    this.editRange(
      weekday,
      index,
      produce(range, (draft) => {
        draft.startMins = startMins;
      }),
    );
  }

  setEndTime(weekday: Weekday, index: number, end: string) {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      return;
    }
    const endMins = parseTimeStringToTotalMinutes(end, { isRangeEnd: true });
    this.editRange(
      weekday,
      index,
      produce(range, (draft) => {
        draft.endMins = endMins;
      }),
    );
  }

  setExpectedRobotsInOps(
    weekday: Weekday,
    index: number,
    expectedRobotsInOps: string,
  ) {
    const range = this.weeklySchedule()[weekday][index];
    if (!range) {
      return;
    }
    const expectedRobotsInOpsParsed = parseInt(expectedRobotsInOps);
    this.editRange(
      weekday,
      index,
      produce(range, (draft) => {
        draft.expectedRobotsInOps =
          Number.isFinite(expectedRobotsInOpsParsed) &&
          expectedRobotsInOpsParsed > 0
            ? expectedRobotsInOpsParsed
            : undefined;
      }),
    );
  }

  robotCountInputFocusId(weekday: Weekday, index: number) {
    return `${weekday}-${index}`;
  }

  focusRange(weekday: Weekday, index: number) {
    const input = this.element.nativeElement.querySelector(
      `#${this.robotCountInputFocusId(weekday, index)}`,
    );
    if (input instanceof HTMLInputElement) {
      input.focus();
      input.select();
    }
  }

  editRange(weekday: Weekday, index: number, range: OperationTimeRange) {
    if (!rangeIsValid(this.weeklySchedule()[weekday], range, index)) {
      this.snackbar.open(
        `Could not update a time range for ${this.weekdayDisplayName(weekday)}, it might be overlapping other ranges.`,
        'dismiss',
        { duration: 5000 },
      );
      return;
    }
    this.weeklyScheduleChange.emit(
      produce(this.weeklySchedule(), (draft) => {
        draft[weekday][index] = range;
      }),
    );
  }
}
