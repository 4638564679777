import {
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { MlDataService, PAGE_SIZE } from '../ml-data.service';
import { CollectionDto, FrameDto } from '../ml-data-types';
import { DataGridComponent } from '../shared/data-grid.component';
import { distinctBy } from '@/utils/distinct-by';

@Component({
  selector: 'app-collection-grid',
  templateUrl: './collection-grid.component.html',
  styleUrl: './collection-grid.component.sass',
  imports: [DataGridComponent],
})
export class CollectionGridComponent {
  collection = input.required<CollectionDto>();
  selectedFrameId = input.required<number | undefined>();
  itemClick = output<FrameDto>();

  nextPageIndex = signal(0);
  items = signal<FrameDto[]>([]);
  canFetchMore = computed(
    () => this.items().length >= this.nextPageIndex() * PAGE_SIZE,
  );

  constructor(private readonly mlDataService: MlDataService) {
    effect(() => {
      // reload items when collection changes
      this.collection();
      this.nextPage(0);
    });
  }

  async nextPage(pageNumber: number) {
    const items = await this.getItems(pageNumber);
    // if first page, replace items, else merge
    // we also need to deduplicate, because sometimes items repeat
    if (pageNumber === 0) {
      this.items.set(distinctBy(items, (item) => item.id));
    } else {
      this.items.update((prevItems) =>
        distinctBy([...prevItems, ...items], (item) => item.id),
      );
    }
    this.nextPageIndex.set(pageNumber + 1);
  }

  async getItems(pageNumber: number): Promise<FrameDto[]> {
    return await this.mlDataService.getCollectionsFrames(
      this.collection().id,
      pageNumber,
    );
  }
}
