import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelectChange, MatSelect } from '@angular/material/select';

import { Subject } from 'rxjs';

import Leaflet from 'leaflet';
import 'leaflet-providers';
import 'leaflet-rotate';
import {
  LeafletMapStateService,
  MAP_PROVIDERS,
  MapProvider,
} from './leaflet-map-state.service';

import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/core';

const DEFAULT_MAP_SETTINGS: Leaflet.MapOptions = {
  attributionControl: false, // hides the provider notice
  center: [0, 0],
  rotateControl: false, // We add this outside of Leaflet
  rotate: true,
  touchRotate: true,
  bearing: 0,
  zoom: 18,
  zoomSnap: 0.25,
  touchZoom: true,
  zoomControl: false, // We add this manually
};

const MAP_CANVAS_RESIZE_DELAY = 1000;

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrl: './leaflet-map.component.sass',
  imports: [MatFormField, MatSelect, MatOption],
})
export class LeafletMapComponent implements AfterViewInit, OnDestroy {
  private readonly mapStateService = inject(LeafletMapStateService);

  @Input()
  mapOptions: Partial<Leaflet.MapOptions> = {};

  @Input()
  shouldShowMapProviderSelection = true;

  @Input()
  shouldShowZoomControl = true;

  @Input()
  set refreshTrigger(_: unknown) {
    setTimeout(() => this.mapCanvas?.invalidateSize(), MAP_CANVAS_RESIZE_DELAY);
  }

  @Output()
  onPanning = new EventEmitter<void>();

  @Output()
  onDoubleClick = new EventEmitter<MouseEvent>();

  @Output()
  onMapReady = new EventEmitter<Leaflet.Map>();

  currentProvider: MapProvider = this.mapStateService.getMapProvider();
  readonly mapProviders = Object.keys(MAP_PROVIDERS);

  private readonly onDestroy$ = new Subject<boolean>();

  @ViewChild('mapCanvas') private canvasRef!: ElementRef<HTMLDivElement>;
  private mapCanvas?: Leaflet.Map;

  ngAfterViewInit(): void {
    this.initializeMapCanvas();
    this.setMapProvider(this.currentProvider);
  }

  private initializeMapCanvas(): void {
    this.mapCanvas = Leaflet.map(
      this.canvasRef.nativeElement,
      DEFAULT_MAP_SETTINGS,
    );
    if (this.shouldShowZoomControl === true) {
      Leaflet.control.zoom({ position: 'bottomright' }).addTo(this.mapCanvas);
    }
    this.setMapProvider(this.currentProvider);
    this.onMapReady.emit(this.mapCanvas);
  }

  switchProvider(event: MatSelectChange): void {
    const key = event.value as MapProvider;
    this.setMapProvider(key);
  }

  private setMapProvider(key: MapProvider): void {
    this.currentProvider = key;
    const providerLayer = Leaflet.tileLayer.provider(MAP_PROVIDERS[key]);
    this.mapCanvas?.addLayer(providerLayer);
    this.mapStateService.setMapProvider(key);
  }

  ngOnDestroy(): void {
    this.mapCanvas?.off();
    this.mapCanvas?.remove();
    this.onDestroy$.next(true);
  }

  panning() {
    this.onPanning.emit();
    this.mapCanvas?.invalidateSize();
  }

  doubleClick(event: MouseEvent) {
    this.onDoubleClick.emit(event);
    this.mapCanvas?.invalidateSize();
  }
}
