import { Component, Input } from '@angular/core';

import { MatIcon } from '@angular/material/icon';
import { DurationPipe } from '@/app/core/pipes/duration.pipe';

@Component({
  selector: 'app-robot-blocking',
  templateUrl: './robot-blocking.component.html',
  styleUrl: './robot-blocking.component.sass',
  imports: [MatIcon, DurationPipe],
})
export class RobotBlockingComponent {
  @Input()
  blockedByCurrentUserForMillis: number | undefined;
}
