import { Component } from '@angular/core';
import { AttentionDetectionService } from '../attention-detection.service';
import { RobotSupervisionComponent } from './robot-supervision.component';
import { RobotControlComponent } from '../../robots/robot-operation/robot-control/robot-control.component';
import { MiniMapComponent } from '../../core/mini-map/mini-map.component';
import { RobotControlPanelComponent } from '../../core/robot-control-panel/robot-control-panel.component';
import { MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { OptionalStatusTreeComponent } from '../optional-status-tree/optional-status-tree.component';

@Component({
  selector: 'app-focus-robot-supervision',
  templateUrl: './focus-robot-supervision.component.html',
  styleUrl: './focus-robot-supervision.component.sass',
  providers: [AttentionDetectionService],
  imports: [
    RobotControlComponent,
    MiniMapComponent,
    RobotControlPanelComponent,
    MatMiniFabButton,
    MatTooltip,
    MatIcon,
    OptionalStatusTreeComponent,
  ],
})
export class FocusRobotSupervisionComponent extends RobotSupervisionComponent {
  protected override isHighQualityVideo(): boolean {
    return true;
  }
}
