import { sum } from '@/utils/sum';
import { DAY_MINS, OperationTimeRange } from '../operation';
import { Moment } from 'moment';

const { floor, abs } = Math;

export function momentToTotalMinutes(momentDate: Moment) {
  return momentDate.hours() * 60 + momentDate.minutes();
}

/**
 * Converts `XX:XX` string into number of minutes, taking care
 * of the case when the string refers to end of range, in which
 * case `00:00` means 24h (end of day).
 */
export function parseTimeStringToTotalMinutes(
  timeString: string,
  { isRangeEnd }: { isRangeEnd: boolean },
): number {
  const [hoursString, minutesString] = timeString.split(':');
  const hours = parseInt(hoursString ?? '');
  const minutes = parseInt(minutesString ?? '');
  if (!Number.isFinite(hours) || !Number.isFinite(minutes)) {
    throw new Error(`Invalid time format encountered, got ${timeString}.`);
  }
  if (isRangeEnd && hours === 0 && minutes === 0) {
    // input type="time" can't represent 24:00 so we get 00:00 for end of day
    return DAY_MINS;
  }
  return hours * 60 + minutes;
}

export function minutesToTimeString(totalMinutes: number) {
  if (totalMinutes >= DAY_MINS) {
    // we can't send '24:00' into time input
    return '00:00';
  }
  const hours = floor(totalMinutes / 60);
  const minutes = floor(totalMinutes % 60);
  return `${hours.toFixed(0).padStart(2, '0')}:${minutes.toFixed(0).padStart(2, '0')}`;
}

function minutesIsValid(minutes: number) {
  return minutes >= 0 && minutes <= DAY_MINS;
}

function isOverlapping(a: OperationTimeRange, b: OperationTimeRange) {
  return a.startMins < b.endMins && a.endMins > b.startMins;
}

export function rangeIsValid(
  ranges: OperationTimeRange[],
  query: OperationTimeRange,
  ignoreIndex: number | undefined,
) {
  return (
    minutesIsValid(query.startMins) &&
    minutesIsValid(query.endMins) &&
    query.startMins < query.endMins &&
    !ranges.find((r, i) => i !== ignoreIndex && isOverlapping(r, query))
  );
}

export function computeTotalHours(
  ranges: OperationTimeRange[],
): number | undefined {
  const totalMinutes = sum(
    ranges.map((range) => range.endMins - range.startMins),
  );
  return totalMinutes / 60;
}

export function closestAvailableSlot(
  ranges: OperationTimeRange[],
  query: OperationTimeRange,
  ignoreIndex: number | undefined,
): OperationTimeRange | undefined {
  if (rangeIsValid(ranges, query, ignoreIndex)) {
    // query fits as is
    return query;
  }

  const candidates = ranges
    .flatMap((range, i) => {
      if (i === ignoreIndex) {
        return [];
      }
      const beforeDiff = range.startMins - query.endMins;
      const afterDiff = range.endMins - query.startMins;
      return [
        {
          diff: abs(beforeDiff),
          range: {
            ...query, // copy other props
            startMins: query.startMins + beforeDiff,
            endMins: query.endMins + beforeDiff,
          },
        },
        {
          diff: abs(afterDiff),
          range: {
            ...query, // copy other props
            startMins: query.startMins + afterDiff,
            endMins: query.endMins + afterDiff,
          },
        },
      ];
    })
    .filter(({ range }) => rangeIsValid(ranges, range, ignoreIndex))
    .sort((a, b) => a.diff - b.diff);

  return candidates.at(0)?.range;
}
