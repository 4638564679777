import { Component, Input } from '@angular/core';

import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-pick-icon',
  templateUrl: './pick-icon.component.html',
  styleUrl: './pick-icon.component.sass',
  imports: [MatIcon],
})
export class PickIconComponent {
  @Input()
  isPicked = false;
}
