import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-arrive-at-route-end',
  templateUrl: './arrive-at-route-end.component.html',
  styleUrl: './arrive-at-route-end.component.sass',
  imports: [MatIcon, DecimalPipe],
})
export class ArrivedAtRouteEndComponent {
  readonly arrivalDistanceThreshold = 10;

  @Input()
  distanceToEndOfRoute?: number;

  @Output() arrive = new EventEmitter<void>();

  triggerArrive() {
    if (
      this.distanceToEndOfRoute &&
      this.distanceToEndOfRoute < this.arrivalDistanceThreshold
    ) {
      this.arrive.emit();
    }
  }
}
