import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteCorridorConfirmationState } from './route-corridor-confirmation-state';
import { StopWatch } from '../../../../../utils/stop-watch';
import { DecimalPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

export type CorridorConfirmationEvent = {
  edgeId: number;
  confirmedAfterSeconds: number;
  distanceToCorridor: number;
};

const CONFIRMATION_LOOKAHEAD_DISTANCE = 50;

@Component({
  selector: 'app-corridor-confirmation',
  templateUrl: './corridor-confirmation.component.html',
  styleUrl: './corridor-confirmation.component.sass',
  imports: [MatIcon, DecimalPipe],
})
export class CorridorConfirmationComponent {
  private stopWatch = new StopWatch(true);

  distanceToConfirmation: number | undefined;
  edgeIdToConfirm: number | undefined;
  revokeConfirmationAvailable = false;

  private _state?: RouteCorridorConfirmationState;

  @Input()
  set state(inputState: RouteCorridorConfirmationState | undefined | null) {
    this._state = inputState ?? undefined;

    this.distanceToConfirmation =
      inputState?.distanceToConfirmation !== undefined &&
      inputState?.distanceToConfirmation < CONFIRMATION_LOOKAHEAD_DISTANCE
        ? inputState?.distanceToConfirmation
        : undefined;

    if (
      this.edgeIdToConfirm !== undefined &&
      this.edgeIdToConfirm !== inputState?.edgeIdToConfirm
    ) {
      this.stopWatch.start();
    }

    this.edgeIdToConfirm = inputState?.edgeIdToConfirm;
    this.revokeConfirmationAvailable =
      inputState?.revokeConfirmationAvailable ?? false;
  }
  get state(): RouteCorridorConfirmationState | undefined | null {
    return this._state;
  }

  @Output() confirmRouteCorridorEdgeId =
    new EventEmitter<CorridorConfirmationEvent>();
  @Output() revokeRouteCorridorConfirmation = new EventEmitter<number>();

  private lastConfirmedEdgeId: number | undefined;

  confirmCorridor() {
    if (this.edgeIdToConfirm !== undefined) {
      this.lastConfirmedEdgeId = this.edgeIdToConfirm;
      this.confirmRouteCorridorEdgeId.emit({
        edgeId: this.edgeIdToConfirm,
        confirmedAfterSeconds: (this.stopWatch.getMillisAndStop() ?? 0) / 1000,
        distanceToCorridor: this.distanceToConfirmation ?? 0,
      });
    }
  }

  revokeCorridor() {
    this.revokeRouteCorridorConfirmation.emit(this.lastConfirmedEdgeId);
  }
}
