import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { CreateOperationData, Operation, PinCodeStrategy } from '../operation';
import { MatDialog } from '@angular/material/dialog';
import {
  CreateOperationDialogComponent,
  CreateOperationDialogInput,
} from '../create-operation-dialog/create-operation-dialog.component';

import { Role } from '../../core/user';
import { UpdateOperation } from '../update-operation';
import { EditOperationDialogComponent } from '../edit-operation-dialog/edit-operation-dialog.component';
import { ToolbarComponent } from '../../core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';

import { MatChip, MatChipListbox } from '@angular/material/chips';
import { MatMiniFabButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { OperationsService } from '@/app/core/operations-service';

@Component({
  selector: 'app-operations-view',
  templateUrl: './operations-view.component.html',
  styleUrl: './operations-view.component.sass',
  imports: [
    ToolbarComponent,
    MatMenuItem,
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatChip,
    MatChipListbox,
    MatMiniFabButton,
    RouterLink,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
  ],
})
export class OperationsViewComponent implements OnInit {
  isAdmin = false;
  operations: Operation[] = [];

  availablePinCodeStrategies = Object.values(PinCodeStrategy);

  readonly columnsToDisplay = ['id', 'accessGroups', 'action-buttons'];

  constructor(
    private operationsService: OperationsService,
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.isAdmin = !!user?.roles?.includes(Role.ADMIN);
      if (user) {
        this.pullData();
      }
    });
  }

  pullData() {
    this.operationsService.getOperations().subscribe((operations) => {
      operations.sort((a, b) => a.id.localeCompare(b.id));
      this.operations = operations;
    });
  }

  private updateOperation(updatedOperation: Operation) {
    let operation = this.operations.find((op) => op.id === updatedOperation.id);
    if (!operation) {
      this.operations.push(updatedOperation);
    }
    operation = updatedOperation;
  }

  pushOperationChanges(id: string, updateOperation: UpdateOperation) {
    this.operationsService
      .updateOperation(id, updateOperation)
      .subscribe((operation: Operation) => this.updateOperation(operation));
  }

  createOperation() {
    const createOperationDialogRef = this.dialog.open<
      CreateOperationDialogComponent,
      CreateOperationDialogInput,
      CreateOperationData
    >(CreateOperationDialogComponent, {
      width: '230px',
      data: {} as CreateOperationDialogInput,
    });
    createOperationDialogRef.afterClosed().subscribe((createdOperation) => {
      if (!createdOperation) {
        return;
      }

      this.operationsService
        .createOperation(createdOperation)
        .subscribe((operation) => this.updateOperation(operation));
    });
  }

  editOperation(operation: Operation) {
    const editRobotDialogRef = this.dialog.open(EditOperationDialogComponent, {
      width: 'auto',
      data: { operation },
      minWidth: '80vh',
      autoFocus: false,
      maxHeight: '90vh',
    });
    editRobotDialogRef
      .afterClosed()
      .subscribe((updateOperation?: UpdateOperation) => {
        if (!updateOperation) {
          this.pullData();
          return;
        }
        this.pushOperationChanges(operation.id, updateOperation);
      });
  }
}
