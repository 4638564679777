import { MapChangesetConflict, MapElement } from '@cartken/map-types';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

export type RebaseChoice = 'LatestVersion' | 'ChangesetVersion';

export type ResolvedMapChangesetConflict = MapChangesetConflict & {
  choice: RebaseChoice | undefined;
};

export class RebaseManager {
  private resolvedConflicts: ResolvedMapChangesetConflict[];
  private currentConflictIndex = 0;

  private _currentConflict$ = new ReplaySubject<ResolvedMapChangesetConflict>(
    1,
  );
  private _previousConflictAvailable$ = new BehaviorSubject<boolean>(false);
  private _nextConflictAvailable$ = new BehaviorSubject<boolean>(false);
  private _allConflictsResolved$ = new BehaviorSubject<boolean>(false);

  currentConflict$ = this._currentConflict$.asObservable();
  previousConflictAvailable$ = this._previousConflictAvailable$.asObservable();
  nextConflictAvailable$ = this._nextConflictAvailable$.asObservable();
  allConflictsResolved$ = this._allConflictsResolved$.asObservable();

  constructor(rebaseConflicts: MapChangesetConflict[]) {
    this.resolvedConflicts = rebaseConflicts.map((conflict) => ({
      ...conflict,
      choice: undefined,
    }));
    this._previousConflictAvailable$.next(this.previousConflictAvailable());
    this._nextConflictAvailable$.next(this.nextConflictAvailable());
    this._allConflictsResolved$.next(this.allConflictsResolved());
    this.updateCurrentConflict();
  }

  previousConflictAvailable() {
    return this.currentConflictIndex > 0 && !!this.resolvedConflicts.length;
  }

  previousConflict() {
    if (this.previousConflictAvailable()) {
      --this.currentConflictIndex;
      this.updateCurrentConflict();
      this._previousConflictAvailable$.next(this.previousConflictAvailable());
      this._nextConflictAvailable$.next(this.nextConflictAvailable());
    }
  }

  nextConflictAvailable() {
    return this.currentConflictIndex < this.resolvedConflicts.length - 1;
  }

  nextConflict() {
    if (this.nextConflictAvailable()) {
      ++this.currentConflictIndex;
      this.updateCurrentConflict();
      this._previousConflictAvailable$.next(this.previousConflictAvailable());
      this._nextConflictAvailable$.next(this.nextConflictAvailable());
    }
  }

  private updateCurrentConflict() {
    const currentConflict = this.resolvedConflicts[this.currentConflictIndex];
    if (!currentConflict) {
      return;
    }
    this._currentConflict$.next(currentConflict);
  }

  allConflictsResolved() {
    return (
      this.resolvedConflicts.every((conflict) => !!conflict.choice) ?? false
    );
  }

  resolveCurrentConflict(choice: RebaseChoice) {
    if (choice === this.resolvedConflicts[this.currentConflictIndex]?.choice) {
      return;
    }
    this.resolvedConflicts[this.currentConflictIndex]!.choice = choice;
    this._currentConflict$.next(
      this.resolvedConflicts[this.currentConflictIndex]!,
    );
    this._allConflictsResolved$.next(this.allConflictsResolved());
  }

  getResolvedConflicts(): MapElement[] {
    return (
      this.resolvedConflicts.flatMap((conflict) => {
        if (!conflict.latestMapElement || !conflict.choice) {
          return [];
        }
        if (conflict.choice === 'LatestVersion') {
          return [conflict.latestMapElement];
        }
        return [
          {
            ...conflict.changesetMapElement,
            version: conflict.latestMapElement.version,
          },
        ];
      }) ?? []
    );
  }
}
