import { Pipe, PipeTransform } from '@angular/core';

const { abs, round } = Math;

const ONE_DAY_MIN = 24 * 60;
const ONE_HOUR_MIN = 60;

export function prettyTime(
  value?: Date | string | number,
  /** Which version to show if older than 1 day. */
  olderThanOneDay: 'time+date' | 'date' = 'time+date',
): string {
  if (value === undefined) {
    return 'UNKNOWN';
  }
  const date = new Date(value);
  const now = new Date();
  const minutesAgo = round((now.getTime() - date.getTime()) / 1000 / 60);

  if (abs(minutesAgo) > ONE_DAY_MIN) {
    // different day ==> show date only
    const dateString = `${date.getFullYear()}-${
      // getMonth counts from 0...
      date.getMonth() + 1
    }-${date.getDate()}`;
    return olderThanOneDay === 'time+date'
      ? `at ${date.toLocaleTimeString()} (${dateString})`
      : dateString;
  } else if (abs(minutesAgo) >= ONE_HOUR_MIN) {
    // same day ==> show time only
    return `at ${date.toLocaleTimeString()}`;
  } else if (minutesAgo < -1) {
    return `in ${-minutesAgo} minutes`;
  } else if (minutesAgo === -1) {
    return `in 1 minute`;
  } else if (minutesAgo === 0) {
    return 'just now';
  } else if (minutesAgo === 1) {
    return `1 minute ago`;
  } else {
    return `${minutesAgo} minutes ago`;
  }
}

@Pipe({ name: 'prettyTime' })
export class PrettyTimePipe implements PipeTransform {
  transform(...args: any[]): string {
    return prettyTime(...args);
  }
}
