import { Component, Input } from '@angular/core';
import { DataItem } from '@/app/ml-platform/data-viewer/data-viewer.service';
import { MlActionService } from '@/app/ml-platform/ml-action.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '@/app/core/error-system/error.service';
import {
  MassActionProgressReport,
  MassActionProgressBarComponent,
} from './mass-action-progress-bar.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-add-frame-metadata',
  templateUrl: './add-frame-metadata.component.html',
  styleUrl: './add-frame-metadata.component.sass',
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    MatOption,
    MatInput,
    MatButton,
    MassActionProgressBarComponent,
  ],
})
export class AddFrameMetadataComponent {
  @Input()
  frames: DataItem[] = [];

  progressReport: MassActionProgressReport | undefined;

  readonly dataTypeOptions = ['string', 'number', 'bool'] as const;
  selectedDataType: 'string' | 'number' | 'bool' = 'string';

  metadataKey = '';
  metadataValue = '';

  constructor(
    readonly mlActionService: MlActionService,
    readonly errorService: ErrorService,
  ) {}

  private parseMetadata(): Record<string, string | number | boolean> {
    if (this.metadataKey.length === 0) {
      throw new Error('Got metadataKey with lenght 0');
    }
    let value;
    if (this.selectedDataType === 'string') {
      value = this.metadataValue;
    } else if (this.selectedDataType === 'number') {
      value = parseFloat(this.metadataValue);
      if (isNaN(value)) {
        throw new Error(
          `Failed to parse metadataValue ${this.metadataValue} into float.`,
        );
      }
    } else if (this.selectedDataType === 'bool') {
      if (this.metadataValue === 'true') {
        value = true;
      } else if (this.metadataValue === 'false') {
        value = false;
      } else {
        throw new Error(
          `Couldnt parse metadataValue '${this.metadataValue}' into bool`,
        );
      }
    } else {
      throw new Error(`Got unexpected dataType: '${this.selectedDataType}'`);
    }

    const metadata: Record<string, string | number | boolean> = {};
    metadata[this.metadataKey] = value;
    return metadata;
  }

  async onAddMetadata() {
    let metadata;
    try {
      metadata = this.parseMetadata();
    } catch (e) {
      if (e instanceof Error) {
        this.errorService.reportError(e.message);
      } else {
        this.errorService.reportError('Failed to parse metadata');
      }
      return;
    }

    this.progressReport = {
      totalCount: this.frames.length,
      successCount: 0,
      failureCount: 0,
      errorMessages: [],
    };
    const frames = this.frames;
    for (const frame of frames) {
      try {
        await this.mlActionService.addMetadataToAsset(
          'frame',
          frame.id,
          metadata,
        );
        this.progressReport.successCount++;
      } catch (e) {
        console.error(e);
        this.progressReport.failureCount++;
        if (e instanceof HttpErrorResponse && 'error' in e.error) {
          this.progressReport.errorMessages.push(
            `Frame ${frame.id} - ${e.error.error}`,
          );
        }
      }
    }
  }
}
