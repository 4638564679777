import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardAvatar,
  MatCardTitle,
  MatCardSubtitle,
} from '@angular/material/card';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.sass',
  imports: [
    MatCard,
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    MatFormField,
    MatInput,
    RouterLink,
    MatCardHeader,
    MatCardAvatar,
    MatCardTitle,
    MatCardSubtitle,
    MatButton,
    AsyncPipe,
  ],
})
export class LoginComponent implements OnInit {
  requiredRoles$: Observable<string[] | undefined>;
  returnUrl$: Observable<string | undefined>;

  form!: FormGroup;
  loginFailed = false;

  constructor(
    public auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.requiredRoles$ = this.route.queryParams.pipe(
      map((queryParams): string[] => {
        const params = queryParams['requiredRoles'];
        if (Array.isArray(params)) {
          return params;
        }
        return [params];
      }),
    );
    this.returnUrl$ = this.route.queryParams.pipe(
      map((queryParams) => queryParams['returnUrl']),
    );

    // Navigate back to the sending page when requested role criteria are matched.
    combineLatest([
      this.auth.user$,
      this.returnUrl$,
      this.requiredRoles$,
    ]).subscribe(([user, returnUrl, requiredRoles]) => {
      if (user && returnUrl && requiredRoles) {
        const matchingRoles = requiredRoles.filter(
          (requiredRole) =>
            user.roles?.includes(requiredRole) || requiredRole == '*',
        );
        if (matchingRoles.length > 0) {
          this.router.navigate([returnUrl]);
        }
        return;
      }
    });
  }
  async ngOnInit() {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  async onSubmit() {
    this.loginFailed = false;
    if (this.form.valid) {
      try {
        const username = this.form.get('username')?.value;
        const password = this.form.get('password')?.value;
        await this.auth
          .signIn(username, password)
          .then((success) => (this.loginFailed = !success));
      } catch (err) {
        this.loginFailed = true;
      }
    }
  }

  async logout() {
    await this.auth.logout();
  }
}
