import { PolygonGeometry } from '@cartken/map-types';
import { v } from '@/valibot';

export enum PinCodeStrategy {
  NONE = 'None',
  FIXED = 'Fixed',
  RANDOM = 'Random',
}

export enum DisposalMode {
  NEVER = 'Never',
  ON_FAILURE = 'OnFailure',
  ALWAYS = 'Always',
}

export enum CorridorAlignmentPreference {
  CENTER = 'Center',
  LEFT = 'Left',
  RIGHT = 'Right',
}

export type CompartmentStrategy = v.InferOutput<typeof CompartmentStrategy>;
export const CompartmentStrategy = v.object({
  pinCodeStrategy: v.optional(v.enum(PinCodeStrategy)),
  pinCode: v.optional(v.string()),
  openCompartmentOnArrival: v.optional(v.boolean()),
});

export type TextMessageConfiguration = v.InferOutput<
  typeof TextMessageConfiguration
>;
export const TextMessageConfiguration = v.object({
  createBlock: v.optional(v.string()),
  waitingForDropoffBlock: v.nullish(v.string()),
});

export type RobotQueueEdgeHandover = v.InferOutput<
  typeof RobotQueueEdgeHandover
>;
export const RobotQueueEdgeHandover = v.object({
  name: v.string(),
  displayName: v.nullish(v.string()),
});

export type HandoverWaitingDurations = v.InferOutput<
  typeof HandoverWaitingDurations
>;
export const HandoverWaitingDurations = v.object({
  estimatedWaitingDuration: v.nullish(v.number()),
  maxWaitingDuration: v.nullish(v.number()),
  reminderDuration: v.nullish(v.number()),
  autoSuccessDuration: v.nullish(v.number()),
});

export enum OrderSchedulerMode {
  AS_SOON_AS_POSSIBLE = 'AsSoonAsPossible',
  MINIMIZE_MEAN_ORDER_DURATION = 'MinimizeMeanOrderDuration',
  MINIMIZE_DRIVING_DURATION = 'MinimizeDrivingDuration',
  MINIMIZE_MEAN_ORDER_AND_DRIVING_DURATION = 'MinimizeMeanOrderAndDrivingDuration',
  FIRST_IN_FIRST_OUT = 'FirstInFirstOut',
}

export type DisposalOptions = v.InferOutput<typeof DisposalOptions>;
export const DisposalOptions = v.object({
  disposalMode: v.optional(v.enum(DisposalMode)),
  disposeAtLocationId: v.nullish(v.string()),
});

const defaultHandoverWaitingDurations = {
  estimatedWaitingDuration: undefined,
  maxWaitingDuration: undefined,
  reminderDuration: undefined,
  autoSuccessDuration: undefined,
};

export type OrderOperationData = v.InferOutput<typeof OrderOperationData>;
export const OrderOperationData = v.looseObject({
  waitingQueues: v.optional(v.array(RobotQueueEdgeHandover), []),
  pickups: v.optional(v.array(RobotQueueEdgeHandover), []),
  dropoffs: v.optional(v.array(RobotQueueEdgeHandover), []),
  storageLocationId: v.optional(v.string()),
  surveyUrl: v.optional(v.string()),
  defaultWebhookUrl: v.optional(v.string()),
  pickupCompartmentStrategy: v.optional(CompartmentStrategy, {
    pinCodeStrategy: PinCodeStrategy.NONE,
  }),
  dropoffCompartmentStrategy: v.optional(CompartmentStrategy, {
    pinCodeStrategy: PinCodeStrategy.NONE,
  }),
  defaultPickupWaitingDurations: v.optional(
    HandoverWaitingDurations,
    defaultHandoverWaitingDurations,
  ),
  defaultDropoffWaitingDurations: v.optional(
    HandoverWaitingDurations,
    defaultHandoverWaitingDurations,
  ),
  textMessageConfiguration: v.optional(TextMessageConfiguration, {}),
  allowCustomPickupLocationsWithinBounds: v.optional(v.boolean()),
  allowCustomDropoffLocationsWithinBounds: v.optional(v.boolean()),
  maxPickupLocationRefinementDistance: v.nullish(v.number()),
  maxDropoffLocationRefinementDistance: v.nullish(v.number()),
  orderSchedulerMode: v.optional(v.enum(OrderSchedulerMode)),
  snapToLocationInRadius: v.nullish(v.number()),
  pickupHandoverCompletionDelay: v.nullish(v.number()),
  dropoffHandoverCompletionDelay: v.nullish(v.number()),
  preferredCountryCodes: v.optional(v.array(v.string())),
  maxUnsupervisedAutonomyDuration: v.nullish(v.number()),
  freezeInsertionBeforePickup: v.nullish(v.number()),
  pickupAutomaticallyFailOrdersAfterTimeout: v.optional(v.boolean(), false),
  dropoffAutomaticallyFailOrdersAfterTimeout: v.optional(v.boolean(), false),
  disposalOptions: v.nullish(DisposalOptions),
  corridorAlignmentPreference: v.optional(v.enum(CorridorAlignmentPreference)),
  rejectedOrderWarningThreshold: v.nullish(v.number()),
  reschedulingForbidden: v.optional(v.boolean(), false),
  returnToHandoverTimeoutMins: v.nullish(v.number()),
  pickupImmediatelyEnabled: v.optional(v.boolean(), false),
  maxDrivingSecondsToPickups: v.nullish(v.number()),
  maxDrivingSecondsToDropoffs: v.nullish(v.number()),
  enableAutoScheduler: v.optional(v.boolean(), false),
  supportPhoneNumber: v.nullish(v.string()),
  useOrderDetection: v.optional(v.boolean(), false),
});

export interface Station {
  robotQueueEdgeId: number;
  robotQueueEdgeName?: string;
  waitingDuration?: number;
}

export const DAY_MINS = 24 * 60;

const Minutes = v.pipe(
  v.number(),
  v.integer(),
  v.minValue(0),
  v.maxValue(DAY_MINS),
);

const IntegerOneOrMore = v.pipe(v.number(), v.integer(), v.minValue(1));

export type OperationTimeRange = v.InferOutput<typeof OperationTimeRange>;
export const OperationTimeRange = v.object({
  startMins: Minutes,
  endMins: Minutes,
  expectedRobotsInOps: v.optional(IntegerOneOrMore),
});

export type WeeklySchedule = v.InferOutput<typeof WeeklySchedule>;
const WeeklySchedule = v.object({
  monday: v.array(OperationTimeRange),
  tuesday: v.array(OperationTimeRange),
  wednesday: v.array(OperationTimeRange),
  thursday: v.array(OperationTimeRange),
  friday: v.array(OperationTimeRange),
  saturday: v.array(OperationTimeRange),
  sunday: v.array(OperationTimeRange),
});

const OperationBase = v.object({
  id: v.string(),
  accessGroups: v.optional(v.array(v.string()), []),
  displayName: v.optional(v.string()),
  deleted: v.optional(v.boolean(), false),
  operationRegion: v.optional(PolygonGeometry),
  timeZone: v.optional(v.string()),
  maxRobotsInOps: v.nullish(IntegerOneOrMore),
  weeklySchedule: v.optional(WeeklySchedule),
  automaticFleetUpdate: v.optional(v.boolean(), false),
  emergencyStopActive: v.optional(v.boolean(), false),
});

export enum OperationType {
  OrderOperation = 'OrderOperation',
}

export const OrderOperation = v.object({
  ...OperationBase.entries,
  operationType: v.literal(OperationType.OrderOperation),
  operationData: v.optional(OrderOperationData),
});

export type Operation = v.InferOutput<typeof Operation>;
export const Operation = v.variant('operationType', [OrderOperation]);

export const allOperationTypes = Operation.options.map(
  (op) => op.entries.operationType.literal,
);

export type CreateOperationData = {
  id: string;
  accessGroups: string[];
  operationType: OperationType;
  operationData: Partial<OrderOperationData>;
};
