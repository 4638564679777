import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

type MouseOrTouchEvent = TouchEvent | MouseEvent;

function getX(event: MouseOrTouchEvent) {
  // override warning for unchecked index access, touch event should have initial touch
  return 'clientX' in event ? event.clientX : event.touches[0]!.clientX;
}

@Component({
  selector: 'app-slide-to-action',
  templateUrl: './slide-to-action.component.html',
  styleUrl: './slide-to-action.component.sass',
})
export class SlideToActionComponent implements AfterViewInit {
  @Input() handleTitle!: string;
  @Input() mainTitle!: string;
  @Input() cooldownTimeout!: number;

  @Output() onCompleted = new EventEmitter<void>();

  @ViewChild('container') container!: ElementRef<HTMLDivElement>;
  @ViewChild('rail') rail!: ElementRef<HTMLDivElement>;
  @ViewChild('dragHandle') dragHandle!: ElementRef<HTMLDivElement>;

  private sliderRunwaySize = Infinity;
  private currentX = 0;
  private initialX?: number;

  ngAfterViewInit() {
    this.dragHandle.nativeElement.addEventListener(
      'touchstart',
      this.dragStart,
    );
    this.rail.nativeElement.addEventListener('touchend', this.dragEnd);
    this.rail.nativeElement.addEventListener('touchmove', this.drag);

    this.dragHandle.nativeElement.addEventListener('mousedown', this.dragStart);
    this.rail.nativeElement.addEventListener('mouseup', this.dragEnd);
    this.rail.nativeElement.addEventListener('mousemove', this.drag);

    this.container.nativeElement.addEventListener('mouseleave', this.dragEnd);
  }

  private dragStart = (event: MouseOrTouchEvent) => {
    this.sliderRunwaySize =
      this.rail.nativeElement.clientWidth -
      this.dragHandle.nativeElement.offsetWidth;
    this.initialX = getX(event);
  };

  private dragEnd = () => {
    const isSlidingFinished = this.currentX < this.sliderRunwaySize;

    if (isSlidingFinished) {
      this.setSliderPosition(0);
    } else {
      this.handleCompletion();
    }

    this.initialX = undefined;
    this.currentX = 0;
  };

  private drag = (event: MouseOrTouchEvent) => {
    if (this.initialX !== undefined) {
      this.currentX = getX(event) - this.initialX;
      this.setSliderPosition(this.currentX);
      event.preventDefault();
    }
  };

  private handleCompletion() {
    this.dragHandle.nativeElement.style.opacity = '0';
    this.onCompleted.emit();

    this.dragHandle.nativeElement.removeEventListener(
      'touchstart',
      this.dragStart,
    );
    this.rail.nativeElement.removeEventListener('touchend', this.dragEnd);
    this.rail.nativeElement.removeEventListener('touchmove', this.drag);

    this.dragHandle.nativeElement.removeEventListener(
      'mousedown',
      this.dragStart,
    );
    this.rail.nativeElement.removeEventListener('mouseup', this.dragEnd);
    this.rail.nativeElement.removeEventListener('mousemove', this.drag);

    this.container.nativeElement.removeEventListener(
      'mouseleave',
      this.dragEnd,
    );

    setTimeout(() => {
      this.dragHandle.nativeElement.style.opacity = '1';
      this.ngAfterViewInit();
      this.setSliderPosition(0);
    }, this.cooldownTimeout);
  }

  private setSliderPosition(xPos: number) {
    if (this.currentX >= 0 && xPos <= this.sliderRunwaySize) {
      this.dragHandle.nativeElement.style.transform =
        'translate3d(' + xPos + 'px, ' + 0 + 'px, 0)';
    }
  }
}
