import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth.guard';
import { LoginComponent } from './users/login/login.component';
import { RobotOperatorViewComponent } from './robots/robot-operation/robot-operator-view/robot-operator-view.component';
import {
  canDeactivateMapEditorGuard,
  MapEditorComponent,
} from './map-editor/map-editor.component';
import { OperationLiveViewComponent } from './operations/operation-live-view/operation-live-view.component';
import { RobotsViewComponent } from './robots/robots-view/robots-view.component';
import { OperationsViewComponent } from './operations/operations-view/operations-view.component';
import { UsersViewComponent } from './users/users-view/users-view.component';
import { TrackingViewComponent } from './order-tracking/tracking-view/tracking-view.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HandoverControlSelectionViewComponent } from './operations/handover-location-selection-view/handover-location-selection-view.component';
import { HandoverLocationViewComponent } from './operations/handover-location-view/handover-location-view.component';
import { OrdersViewComponent } from './orders/orders-view/orders-view.component';
import { MappingComponent } from './mapping/mapping.component';
import { SupervisedRobotsComponent } from './supervised-robots/supervised-robots.component';
import { OperationManagementComponent } from './operation-management/operation-management.component';
import { RobotOperatorOverviewComponent } from './robot-operator-overview/robot-operator-overview.component';
import { MlPlatformComponent } from './ml-platform/ml-platform.component';
import { RobotBlackboxComponent } from './robots/robot-blackbox/robot-blackbox.component';
import { CrossingMonitoringComponent } from './crossing-monitoring/crossing-monitoring.component';
import { SsoLoginComponent } from './users/sso-login/sso-login.component';
import { OperatorBlackboxAggregationViewComponent } from './robot-operator-overview/operator-blackbox-aggregation-view/operator-blackbox-aggregation-view.component';
import { FieldOpsViewComponent } from './field-ops/field-ops-view.component';

export type DisplayableRoute = Route & {
  /** Routes which have `displayName` will be shown on Landing Page in the same order as specified in `routes`. */
  displayName?: string;
  data?: { allowedRoles: string[] };
};

export const routes: DisplayableRoute[] = [
  // Home
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['*'] },
  },

  // Displayed routes in landing page
  {
    path: 'robots',
    component: RobotsViewComponent,
    canActivate: [AuthGuard],
    displayName: 'Robots',
    data: {
      allowedRoles: [
        'admin',
        'robot-operator',
        'operations-manager',
        'operations-user',
      ],
    },
  },
  {
    path: 'operations',
    component: OperationsViewComponent,
    canActivate: [AuthGuard],
    displayName: 'Operations',
    data: {
      allowedRoles: [
        'admin',
        'operations-manager',
        'operations-viewer',
        'operations-user',
      ],
    },
  },
  {
    path: 'users',
    component: UsersViewComponent,
    canActivate: [AuthGuard],
    displayName: 'Users',
    data: { allowedRoles: ['admin'] },
  },
  {
    path: 'map-editor',
    component: MapEditorComponent,
    canActivate: [AuthGuard],
    canDeactivate: [canDeactivateMapEditorGuard],
    displayName: 'Map Editor',
    data: { allowedRoles: ['admin', 'map-editor'] },
  },
  {
    path: 'orders',
    component: OrdersViewComponent,
    canActivate: [AuthGuard],
    displayName: 'Orders',
    data: {
      allowedRoles: [
        'admin',
        'operations-manager',
        'operations-viewer',
        'operations-user',
      ],
    },
  },
  {
    path: 'mapping',
    component: MappingComponent,
    canActivate: [AuthGuard],
    displayName: 'Mapping',
    data: { allowedRoles: ['admin', 'map-editor'] },
  },
  {
    path: 'supervise-robots',
    component: SupervisedRobotsComponent,
    canActivate: [AuthGuard],
    displayName: 'Supervise Robots',
    data: { allowedRoles: ['admin', 'robot-operator'] },
  },
  {
    path: 'operation-management',
    component: OperationManagementComponent,
    canActivate: [AuthGuard],
    displayName: 'Ops Management',
    data: { allowedRoles: ['admin', 'operations-manager'] },
  },
  {
    path: 'robot-operators-overview',
    component: RobotOperatorOverviewComponent,
    canActivate: [AuthGuard],
    displayName: 'Operators Overview',
    data: { allowedRoles: ['admin', 'operations-manager'] },
  },
  {
    path: 'ml-platform',
    component: MlPlatformComponent,
    canActivate: [AuthGuard],
    displayName: 'ML Platform',
    data: { allowedRoles: ['admin'] },
  },
  {
    path: 'crossing-monitoring',
    component: CrossingMonitoringComponent,
    canActivate: [AuthGuard],
    displayName: 'Crossings',
    data: { allowedRoles: ['admin', 'operations-manager'] },
  },
  {
    path: 'field-ops',
    component: FieldOpsViewComponent,
    canActivate: [AuthGuard],
    displayName: 'Field Ops',
    data: {
      allowedRoles: ['admin', 'robot-operator'],
    },
  },

  // Sub-routes
  {
    path: 'robots/supervise/:robotIds',
    component: RobotOperatorViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'robot-operator'] },
  },
  {
    path: 'robots/blackbox/:robot-id',
    component: RobotBlackboxComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin'] },
  },
  {
    path: 'operations/:operation-id',
    component: OperationLiveViewComponent,
    canActivate: [AuthGuard],
    data: {
      allowedRoles: [
        'admin',
        'operations-manager',
        'operations-viewer',
        'operations-user',
      ],
    },
  },
  {
    path: 'operations/:operation-id/handovers',
    component: HandoverControlSelectionViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'operations-manager', 'operations-user'] },
  },
  {
    path: 'operations/:operation-id/handovers/:location-id',
    component: HandoverLocationViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'operations-manager', 'operations-user'] },
  },
  {
    path: 'operations/:operation-id/:pickup-handover-alias',
    component: OperationLiveViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'operations-manager', 'operations-user'] },
  },
  {
    path: 'operator/blackbox/:user-id',
    component: OperatorBlackboxAggregationViewComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'operations-manager'] },
  },
  {
    path: 'operation-management/:operation-ids',
    component: OperationManagementComponent,
    canActivate: [AuthGuard],
    data: { allowedRoles: ['admin', 'operations-manager'] },
  },

  // No login necessary
  {
    path: 'orders/:tracking-code',
    component: TrackingViewComponent,
  },
  { path: 'login', component: LoginComponent },
  { path: 'login/sso', component: SsoLoginComponent },

  // Otherwise, redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  providers: [AuthGuard],
  imports: [RouterModule.forRoot(routes /*, {enableTracing: true}*/)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
