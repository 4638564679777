import { MapElementManager } from '../map-elements/map-element-manager';
import { CreateEdgeMode } from './create-edge-mode';
import { CreatePointFeatureMode } from './create-point-feature-mode';
import { DeleteMode } from './delete-mode';
import { SelectAndEditMode } from './select-and-edit-mode';
import { CreateOperationRegionMode } from './create-operation-region-mode';
import { DiffMode } from './diff-mode';
import { RebaseMode } from './rebase-mode';
import { VisualizationManager } from '../visualization/visualization-manager';
import { InteractiveMode } from '../visualization/interactive-mode';
import { ReachableEdgesMode } from './reachability/reachable-edges-mode';
import { RoutingMode } from './routing/routing-mode';
import { RoutesService } from '../../core/route-service';
import { signal } from '@angular/core';
import { SplitEdgeMode } from './split-edge-mode';
import { EditLocalizationTileMode } from './edit-tile/edit-tile-mode';

export type MapEditorMode =
  | 'select'
  | 'createEdge'
  | 'splitEdge'
  | 'createHandoverLocation'
  | 'createMutex'
  | 'createInfrastructure'
  | 'createOperationRegion'
  | 'delete'
  | 'diff'
  | 'rebase'
  | 'reachability'
  | 'routing'
  | 'editTile';

export class ModeManager {
  readonly modes;
  readonly currentModeId = signal<MapEditorMode>('select');
  readonly modeChangesEnabled = signal(true);

  currentMode() {
    return this.modes[this.currentModeId()];
  }

  constructor(
    visualizationManager: VisualizationManager,
    mapElementManager: MapElementManager,
    routesService: RoutesService,
  ) {
    const pointFeatureMode = new CreatePointFeatureMode(mapElementManager);
    this.modes = {
      select: new SelectAndEditMode(mapElementManager),
      createEdge: new CreateEdgeMode(mapElementManager),
      splitEdge: new SplitEdgeMode(mapElementManager),
      createHandoverLocation: pointFeatureMode,
      createMutex: pointFeatureMode,
      createInfrastructure: pointFeatureMode,
      createOperationRegion: new CreateOperationRegionMode(
        mapElementManager,
        visualizationManager,
      ),
      diff: new DiffMode(mapElementManager, visualizationManager),
      delete: new DeleteMode(mapElementManager),
      rebase: new RebaseMode(visualizationManager),
      reachability: new ReachableEdgesMode(
        mapElementManager,
        visualizationManager,
      ),
      routing: new RoutingMode(visualizationManager, routesService),
      editTile: new EditLocalizationTileMode(
        mapElementManager,
        visualizationManager,
      ),
    } satisfies Record<MapEditorMode, InteractiveMode>;
  }

  setMapEditorMode(newModeId: MapEditorMode) {
    const currentModeId = this.currentModeId();
    if (!this.modeChangesEnabled() || newModeId === currentModeId) {
      return;
    }
    this.modes[currentModeId].setActive(false);
    this.modes[newModeId].setActive(true, newModeId);
    this.currentModeId.set(newModeId);
  }

  enableMapEditorModeChanges(enabled: boolean) {
    this.modeChangesEnabled.set(enabled);
  }
}
