export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

export function hasAtLeastOneElement<T>(array: T[]): array is [T, ...T[]] {
  return array.length >= 1;
}

export function hasExactlyOneElement<T>(array: T[]): array is [T] {
  return array.length === 1;
}

export function hasAtLeastTwoElements<T>(array: T[]): array is [T, T, ...T[]] {
  return array.length >= 2;
}

export function hasAtLeastThreeElements<T>(
  array: T[],
): array is [T, T, T, ...T[]] {
  return array.length >= 3;
}
