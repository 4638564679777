import { Component, Input } from '@angular/core';
import { RobotIssue } from '../robot-issue.types';
import { robotStatusFromIssueSeverity } from '../robot-issues.utils';

import { DefaultPipe } from '@/app/core/pipes/default.pipe';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';

type PopoverPosition = 'top' | 'bottom' | 'left' | 'right';

@Component({
  selector: 'app-robot-issues-popover',
  templateUrl: './robot-issues-popover.component.html',
  styleUrl: './robot-issues-popover.component.sass',
  imports: [DefaultPipe],
})
export class RobotIssuesPopoverComponent {
  @Input() robot!: Robot;
  @Input() issues!: RobotIssue[];
  @Input() position: PopoverPosition = 'right';

  formatIssueName(name: string): string {
    return name.replace(`Cart ${this.robot.serialNumber}:`, '');
  }

  extractIssueStatus(issue: RobotIssue): string {
    return robotStatusFromIssueSeverity(issue.severity);
  }
}
