import { Component, inject } from '@angular/core';
import { CollectionDto, FrameDto } from './ml-data-types';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';
import { MatMenuItem } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';

import { DataViewerComponent } from './data-viewer/data-viewer.component';
import { DataViewerSelectionComponent } from './data-viewer/data-viewer-selection.component';
import { CAsyncPipe } from '@/utils/c-async-pipe';
import { DataViewerService } from './data-viewer/data-viewer.service';
import { CollectionSelectionComponent } from './collection-viewer/collection-selection.component';
import { CollectionGridComponent } from './collection-viewer/collection-grid.component';

@Component({
  selector: 'app-ml-platform',
  templateUrl: './ml-platform.component.html',
  styleUrl: './ml-platform.component.sass',
  imports: [
    ToolbarComponent,
    MatMenuItem,
    RouterLink,
    LeftSidebarComponent,
    DataViewerComponent,
    DataViewerSelectionComponent,
    CAsyncPipe,
    CollectionSelectionComponent,
    CollectionGridComponent,
  ],
})
export class MlPlatformComponent {
  dataViewerService = inject(DataViewerService);
  selectedCollection: CollectionDto | null = null;
  selectedFrame?: FrameDto;

  selectFrame(frame: FrameDto): void {
    if (this.selectedFrame === frame) {
      this.selectedFrame = undefined;
      return;
    }
    this.selectedFrame = frame;
  }

  setCollection(collection: CollectionDto | null) {
    this.selectedFrame = undefined;
    this.selectedCollection = collection;
  }
}
