import { ViewContainerRef } from '@angular/core';
import { MapElementManager } from '../../map-elements/map-element-manager';
import { InteractiveMode } from '../../visualization/interactive-mode';
import { EditTileComponent } from './edit-tile.component';
import { VisualizationManager } from '../../visualization/visualization-manager';
import {
  Color,
  NonInteractiveFeatureCollection,
} from '../../visualization/types';
import { isTileElement } from '@cartken/map-types';
import { SELECTED_COLOR } from '../../visualization/visualization-styles';

const SELECTED_COLOR_FILL: Color = [
  SELECTED_COLOR[0],
  SELECTED_COLOR[1],
  SELECTED_COLOR[2],
  20,
];

export class EditLocalizationTileMode extends InteractiveMode {
  constructor(
    private readonly mapElementManager: MapElementManager,
    private readonly visualizationManager: VisualizationManager,
  ) {
    super();
  }

  override shouldRenderSidebar(): boolean {
    return true;
  }

  override getNonInteractiveFeatures(): NonInteractiveFeatureCollection {
    const element = this.mapElementManager.selectedMapElement();
    if (!element || !isTileElement(element)) {
      return { type: 'FeatureCollection', features: [] };
    }
    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: element.geometry,
          properties: {
            fillColor: SELECTED_COLOR_FILL,
            lineColor: SELECTED_COLOR,
            lineWidth: 5,
          },
        },
      ],
    };
  }

  override renderSidebar(ref: ViewContainerRef) {
    const componentRef = ref.createComponent(EditTileComponent);
    componentRef.instance.mapElementManager = this.mapElementManager;
    componentRef.instance.visualizationManager = this.visualizationManager;
    return componentRef;
  }

  override getCursor(): string {
    return 'grab';
  }
}
