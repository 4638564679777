import { Component } from '@angular/core';

import { DesktopOperationLiveViewComponent } from './desktop-operation-live-view.component';
import { MobileOperationLiveViewComponent } from './mobile-operation-live-view.component';

@Component({
  selector: 'app-operation-live-view',
  templateUrl: './operation-live-view.component.html',
  styleUrl: './operation-live-view.component.sass',
  imports: [
    DesktopOperationLiveViewComponent,
    MobileOperationLiveViewComponent,
  ],
})
export class OperationLiveViewComponent {
  isMobile = window.matchMedia('(max-width: 600px)').matches;
}
