import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HandoverType, Order, OrderStatus } from '../core/order/order';
import { orderStatusToString } from '../operations/utils';
import { OrderDetailsDialogComponent } from '../orders/order-details-dialog/order-details-dialog.component';
import { isOrderDelayed } from './is-order-delayed';
import {
  formatCreated,
  formatHandoverString,
  formatIds,
} from '../core/order/order-utils';
import { orderLateness, isOrderLate } from './is-order-late';
import { isWaitingDurationExceeded } from './is-waiting-duration-exceeded';
import { MatChipSet, MatChipOption } from '@angular/material/chips';
import { DecimalPipe } from '@angular/common';
import {
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
} from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { PrettyTimePipe } from '../core/pipes/pretty-time.pipe';

enum SelectedOrders {
  None = '',
  Active = 'active',
  Delayed = 'delayed',
  Late = 'late',
  Rejected = 'rejected',
  Fulfilled = 'fulfilled',
  Failed = 'failed',
  WaitingDurationExceeded = 'waiting-duration-exceeded',
}

export type OrderTableRow = {
  order: Order;
  robot: string;
  id: string;
  created: Date;
  pickup: string;
  dropoff: string;
  status: string;
  testOrder: boolean;
  pickupLateness?: number;
  dropoffLateness?: number;
  pickupLate?: boolean;
  dropoffLate?: boolean;
};

function orderToTableRow(order: Order): OrderTableRow {
  const pickupLatenessSigned = orderLateness(order, HandoverType.PICKUP);
  const dropoffLatenessSigned = orderLateness(order, HandoverType.DROPOFF);
  return {
    order,
    robot: order.assignedRobotName ?? '',
    created: formatCreated(order),
    id: formatIds(order),
    pickup: formatHandoverString(order.handovers[0]),
    dropoff: formatHandoverString(order.handovers[1]),
    status: orderStatusToString(order),
    testOrder: !!order.testOrder,
    pickupLateness: pickupLatenessSigned
      ? Math.abs(pickupLatenessSigned)
      : undefined,
    dropoffLateness: dropoffLatenessSigned
      ? Math.abs(dropoffLatenessSigned)
      : undefined,
    pickupLate:
      pickupLatenessSigned !== undefined ? pickupLatenessSigned > 0 : false,
    dropoffLate:
      dropoffLatenessSigned !== undefined ? dropoffLatenessSigned > 0 : false,
  };
}
@Component({
  selector: 'app-operation-orders-stats',
  templateUrl: './operation-orders-stats.component.html',
  styleUrl: './operation-orders-stats.component.sass',
  imports: [
    MatChipSet,
    MatChipOption,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatIcon,
    MatIconButton,
    MatTooltip,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    DecimalPipe,
    PrettyTimePipe,
  ],
})
export class OperationOrdersStatsComponent {
  activeOrders: Order[] = [];
  delayedOrders: Order[] = [];
  rejectedOrders: Order[] = [];
  fulfilledOrders: Order[] = [];
  failedOrders: Order[] = [];
  lateOrders: Order[] = [];
  waitingDurationExceededOrders: Order[] = [];

  displayedOrders: OrderTableRow[] = [];

  selected = SelectedOrders.None;

  displayedColumnsBase: string[] = [
    'robot',
    'id',
    'created',
    'testOrder',
    'pickup',
    'dropoff',
    'status',
    // TODO(jonas): implement cancel and rescheduling buttons
    //'icons',
  ];

  displayedColumns: string[] = [...this.displayedColumnsBase];

  @Input()
  rejectedOrdersWarningThreshold!: number;

  @Input()
  set orders(orders: Order[]) {
    this.activeOrders = orders;
    this.delayedOrders = orders.filter(isOrderDelayed);
    this.lateOrders = orders.filter(isOrderLate);
    this.waitingDurationExceededOrders = orders.filter(
      isWaitingDurationExceeded,
    );

    this.updateOrders();
  }

  @Input()
  set finishedOrders(orders: Order[]) {
    this.rejectedOrders = orders.filter(
      (order) => order.status === OrderStatus.REJECTED,
    );
    this.fulfilledOrders = orders.filter(
      (order) => order.status === OrderStatus.FULFILLED,
    );
    this.failedOrders = orders.filter(
      (order) =>
        order.status === OrderStatus.CANCELED ||
        order.status === OrderStatus.FAILED,
    );
    this.updateOrders();
  }

  showRejectionWarning = false;

  constructor(private readonly dialog: MatDialog) {}

  updateOrders() {
    switch (this.selected) {
      case SelectedOrders.None:
        this.displayedOrders = [];
        break;

      case SelectedOrders.Active:
        this.displayedOrders = this.activeOrders.map((order) =>
          orderToTableRow(order),
        );
        break;

      case SelectedOrders.Delayed:
        this.displayedOrders = this.delayedOrders.map((order) =>
          orderToTableRow(order),
        );
        break;

      case SelectedOrders.Rejected:
        this.displayedOrders = this.rejectedOrders.map((order) =>
          orderToTableRow(order),
        );
        break;

      case SelectedOrders.Fulfilled:
        this.displayedOrders = this.fulfilledOrders.map((order) =>
          orderToTableRow(order),
        );
        break;

      case SelectedOrders.Failed:
        this.displayedOrders = this.failedOrders.map((order) =>
          orderToTableRow(order),
        );
        break;
      case SelectedOrders.Late:
        this.displayedOrders = this.lateOrders.map((order) =>
          orderToTableRow(order),
        );
        break;
      case SelectedOrders.WaitingDurationExceeded:
        this.displayedOrders = this.waitingDurationExceededOrders.map((order) =>
          orderToTableRow(order),
        );
        break;
    }

    this.showRejectionWarning =
      this.activeOrders.length * this.rejectedOrdersWarningThreshold <
      this.rejectedOrders.length;
    const hasNoLaterThanConstraints = this.activeOrders.some((o) =>
      o.handovers.some((handover) => !!handover.noLaterThan),
    );
    this.displayedColumns = hasNoLaterThanConstraints
      ? [...this.displayedColumnsBase, 'pickupLateness', 'dropoffLateness']
      : [...this.displayedColumnsBase];
  }

  toggleSelection(selectedOrders: string) {
    const newlySelectedOption = selectedOrders as SelectedOrders;
    if (newlySelectedOption === this.selected) {
      this.selected = SelectedOrders.None;
    } else {
      this.selected = newlySelectedOption;
    }
    this.updateOrders();
  }

  // TODO(jonas): implement these
  cancelOrder(order: Order) {}

  editOrder(order: Order) {}

  showOrderDetails(order: Order) {
    this.dialog.open(OrderDetailsDialogComponent, {
      data: order,
    });
  }
}
