type DragHandlers = {
  onMove: (event: PointerEvent) => void;
  onDone: () => void;
};

/**
 * Helper for creating drag-type gestures.
 *
 * In component:
 * ```
 * const handler = createDragHandler((initialEvent) => {
 *   // what to do when user clicks down
 *   // ...
 *   return {
 *     onMove() {
 *       // what to do when the pointer moves
 *     },
 *     onDone() {
 *       // what to do when manipulation is done
 *     }
 *   };
 * })
 * ```
 *
 * Then in template:
 * ```html
 * <div (pointerdown)="handler($event)"></div>
 * ```
 */
export function createDragHandler<T = never>(
  onPointerDown: (ev: PointerEvent, data: T) => DragHandlers | undefined,
) {
  return (pointerDownEvent: PointerEvent, data: T) => {
    const target = pointerDownEvent.target;
    if (!(target instanceof HTMLElement)) {
      return;
    }
    const handlers = onPointerDown(pointerDownEvent, data);
    if (!handlers) {
      return;
    }
    const { onMove, onDone } = handlers;
    pointerDownEvent.preventDefault();
    pointerDownEvent.stopImmediatePropagation();
    target.setPointerCapture(pointerDownEvent.pointerId);

    const onPointerMove = (event: PointerEvent) => {
      onMove(event);
    };

    const onPointerEnd = () => {
      onDone();
      target.releasePointerCapture(pointerDownEvent.pointerId); // Release pointer capture
      window.removeEventListener('pointermove', onPointerMove);
      window.removeEventListener('pointerup', onPointerEnd);
      window.removeEventListener('pointercancel', onPointerEnd);
    };

    window.addEventListener('pointermove', onPointerMove);
    window.addEventListener('pointerup', onPointerEnd);
    window.addEventListener('pointercancel', onPointerEnd);
  };
}
