import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import {
  hasOrderManagementAccess,
  OrderManagementDialog,
} from '../../operations/order-management-dialog/order-management-dialog.component';
import { FINAL_ORDER_STATUSES, Order } from '../../core/order/order';
import { AsyncPipe } from '@angular/common';
import { MatFabButton, MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-order-details-dialog',
  templateUrl: 'order-details-dialog.component.html',
  styleUrl: './order-details-dialog.component.sass',
  imports: [
    MatDialogTitle,
    MatFabButton,
    MatTooltip,
    MatIcon,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    AsyncPipe,
  ],
})
export class OrderDetailsDialogComponent implements OnInit {
  prettyJson: string;
  private order: Order;
  canEditOrderStatus$!: Observable<boolean>;
  assignedRobotId?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
    this.prettyJson = JSON.stringify(data, null, 2);
    this.order = data;
    this.assignedRobotId = this.order.assignedRobotId;
  }

  ngOnInit(): void {
    this.canEditOrderStatus$ = this.authService.user$.pipe(
      map(hasOrderManagementAccess),
    );
  }

  updateOrder() {
    this.dialog.open(OrderManagementDialog, { data: { order: this.order } });
  }

  openBlackbox() {
    if (!this.assignedRobotId) {
      return;
    }
    window.open(`robots/blackbox/${this.assignedRobotId}`, '_blank');
  }

  openElasticRobotLog() {
    if (!this.assignedRobotId) {
      return;
    }
    const robotId = `%20%22${this.assignedRobotId}%22`;
    const fromDate = this.order.statusLog[0]?.timestamp || 'now-15m';
    const toDate = FINAL_ORDER_STATUSES.includes(this.order.status)
      ? this.order.statusLog.at(-1)?.timestamp || 'now'
      : 'now';
    const url = `https://robot-logs.kb.europe-west3.gcp.cloud.es.io:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'${fromDate}',to:'${toDate}'))&_a=(columns:!(),filters:!(),index:'23c15690-cfca-11ed-8cb8-0dc8a99b65a1',interval:auto,query:(language:kuery,query:'robotId.keyword%20:%20${robotId}'),sort:!(!('@timestamp',desc)))`;
    window.open(url, '_blank');
  }
}
