<div class="controls-container">
  <div class="map-and-control-grid">
    <app-robot-control
      [isKeyboardEnabled]="true"
      [robotCommunication]="robotCommunication"
      [activeControl]="true"
      [highResolution]="true"
    />
    <app-mini-map
      [isCustomGpsClickEnabled]="false"
      [isDirectionsClickEnabled]="false"
      [robotCommunication]="robotCommunication"
    />
  </div>

  <app-robot-control-panel
    [robotCommunication]="robotCommunication"
    [isClaimControlEnabled]="false"
    [isManualControlEnabled]="false"
    [autonomyControlType]="'PARKING_MODE'"
    [hazardLightControlType]="'HAZARD_LIGHT_TOGGLE'"
    [isNavigationToMaintenanceEnabled]="true"
    [isCurbClimbingControlEnabled]="true"
    [isReadyForOrderControlEnabled]="false"
    [isIssueReportingEnabled]="true"
    [active]="true"
    [isRelocalizeEnabled]="true"
    [isCalibrateEndstopEnabled]="true"
    [isNetworkInterfaceControlEnabled]="true"
    [isBlockageReportEnabled]="true"
    [isOverlayMapControlEnabled]="false"
    [isHandlingSnapshotHotkeyEnabled]="true"
  >
    <button
      mat-mini-fab
      color="primary"
      (click)="emitFocusChange()"
      [matTooltip]="'Control multiple robots'"
    >
      <mat-icon>grid_view</mat-icon>
    </button>
  </app-robot-control-panel>
</div>

<app-optional-status-tree [robotCommunication]="robotCommunication" />
