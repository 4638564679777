import {
  EdgeElement,
  ElementType,
  MapElement,
  NodeElement,
  TileElement,
} from './map-element';

const edgeTypes = EdgeElement.options.map<ElementType>(
  (opt) => opt.entries.elementType.literal,
);

export function isEdge(mapElement: MapElement): mapElement is EdgeElement {
  return edgeTypes.includes(mapElement.elementType);
}

export function isNode(mapElement: MapElement): mapElement is NodeElement {
  return mapElement.elementType === ElementType.NODE;
}

export function isTileElement(element: MapElement): element is TileElement {
  return (
    element.elementType === ElementType.LOCALIZATION_MAP_TILES ||
    element.elementType === ElementType.SLIPPY_TILES
  );
}
