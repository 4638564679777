import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { SupervisionSettingsService } from '../supervision-settings/supervision-settings.service';
import { UserSessionEventTrackingService } from '@/app/core/user-session/user-session-event-tracking.service';
import { UserSessionInteractionEventName } from '@/app/core/user-session/user-session-interaction-events';
import { PlaceholderComponent } from '@/app/core/placeholder/placeholder.component';

const ICONS_ANIMATION_ORDER = [
  'hourglass_empty',
  'hourglass_top',
  'hourglass_bottom',
  'update',
];

const ANIMATION_TICK_DURATION_MILLIS = 500;

@Component({
  selector: 'app-robot-placeholder',
  templateUrl: './robot-placeholder.component.html',
  styleUrl: './robot-placeholder.component.sass',
  imports: [PlaceholderComponent],
})
export class RobotPlaceholderComponent implements OnChanges {
  @Input()
  isEnabled!: boolean;

  @Input()
  lastUpdateTime: Date | undefined = new Date(0);

  @Input()
  slotId!: number;

  iconName = 'update';

  constructor(
    private readonly supervisionSettingsService: SupervisionSettingsService,
    private readonly userInteractionsTrackingService: UserSessionEventTrackingService,
  ) {}

  toggleSlotState() {
    this.onGoingUpdateIntervalSubscription?.unsubscribe();
    this.onGoingUpdateIntervalSubscription = undefined;

    this.lastUpdateTime = new Date();
    this.iconName = 'rotate_right';
    this.supervisionSettingsService.setRobotSlotsByIndex(
      !this.isEnabled,
      this.slotId,
    );
    this.userInteractionsTrackingService.trackInteractionEvent(
      UserSessionInteractionEventName.SUPERVISION_ROBOT_SLOT_CHANGED,
    );
  }

  private onGoingUpdateIntervalSubscription?: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    const isEnabled = changes['isEnabled']?.currentValue ?? this.isEnabled;

    if (!isEnabled) {
      this.onGoingUpdateIntervalSubscription?.unsubscribe();
      this.onGoingUpdateIntervalSubscription = undefined;
      this.iconName = 'update_disabled';
      return;
    }

    this.iconName = 'hourglass_empty';

    if (this.onGoingUpdateIntervalSubscription === undefined) {
      this.onGoingUpdateIntervalSubscription = timer(
        0,
        ANIMATION_TICK_DURATION_MILLIS,
      ).subscribe(() => {
        const now = Date.now();
        const timeElapsed = now - (this.lastUpdateTime?.getTime() ?? now);
        const lastFrameIndex = ICONS_ANIMATION_ORDER.length - 1;
        const animationFrame = Math.min(
          Math.floor(timeElapsed / ANIMATION_TICK_DURATION_MILLIS),
          lastFrameIndex,
        );
        this.iconName = ICONS_ANIMATION_ORDER[animationFrame]!;
        if (animationFrame === lastFrameIndex) {
          this.onGoingUpdateIntervalSubscription?.unsubscribe();
          this.onGoingUpdateIntervalSubscription = undefined;
        }
      });
    }
  }
}
