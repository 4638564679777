import { Observable, merge, of } from 'rxjs';
import { map, takeUntil, take, filter } from 'rxjs/operators';
import { StatusTreeNode } from '../../../robots/robot-operation/status-tree/status-tree.component';

import { Finalizable } from '../../../../utils/finalizable';
import { HazardLightsState } from '../backend/robot.dto';
import { RobotSystemStatus } from '../backend/types';
import { GlobalPose } from './types';
import { RouteCorridorConfirmationState } from '../../../../app/robots/robot-operation/common/corridor-confirmation/route-corridor-confirmation-state';
import { RtcStreamManager } from './rtc-stream-manager';
import { isDefined } from '../../../../utils/typeGuards';

export class RtcEvents extends Finalizable {
  readonly enableArrivedButton$!: Observable<boolean>;
  readonly hazardLightsState$!: Observable<HazardLightsState>;
  readonly useLegacyRouteCorridorConfirmation$: Observable<boolean>;
  readonly robotSystemStatuses$: Observable<RobotSystemStatus[]>;
  readonly globalPose$: Observable<GlobalPose>;
  readonly triggerServiceConfig$: Observable<any>;
  readonly robotAttentionReport$: Observable<string | undefined>;
  readonly freeRobotDiskSpace$: Observable<number>;
  readonly mapDataPreprocessorState$: Observable<string | undefined>;
  readonly routeCorridorConfirmationState$: Observable<RouteCorridorConfirmationState>;
  readonly robotStatusTree$: Observable<StatusTreeNode[]>;

  constructor(readonly rtcStreams: RtcStreamManager) {
    super();
    this.routeCorridorConfirmationState$ = this.rtcStreams
      .getJsonStream<RouteCorridorConfirmationState>(
        'routeCorridorConfirmationState',
      )
      .pipe(takeUntil(this.finalized$));
    this.useLegacyRouteCorridorConfirmation$ = merge(
      of(true),
      this.rtcStreams.getJsonStream('routeCorridorConfirmationState').pipe(
        take(1),
        map(() => false),
      ),
    ).pipe(takeUntil(this.finalized$));
    this.globalPose$ = this.rtcStreams
      .getJsonStream<GlobalPose | undefined>('globalPose')
      .pipe(takeUntil(this.finalized$), filter(isDefined));
    this.triggerServiceConfig$ = this.rtcStreams
      .getJsonStream('triggerServiceConfig')
      .pipe(takeUntil(this.finalized$));
    this.freeRobotDiskSpace$ = this.rtcStreams
      .getJsonStream<number>('freeDiskSpace')
      .pipe(takeUntil(this.finalized$));
    this.mapDataPreprocessorState$ = this.rtcStreams
      .getJsonStream<string | string[]>('mapDataPreprocessorState')
      .pipe(
        // Robots are sending `string[]` and we would like to go towards `string`.
        // Once they switch, this `map` can be removed
        map((maybeString) =>
          Array.isArray(maybeString) ? maybeString[0] : maybeString,
        ),
        takeUntil(this.finalized$),
      );
    this.robotSystemStatuses$ = this.rtcStreams
      .getJsonStream<RobotSystemStatus[]>('attentionStatus')
      .pipe(takeUntil(this.finalized$));
    this.robotAttentionReport$ = this.rtcStreams
      .getJsonStream<string>('robotAttentionReport')
      .pipe(takeUntil(this.finalized$));
    this.robotStatusTree$ = this.rtcStreams
      .getJsonStream<StatusTreeNode[]>('robotStatus')
      .pipe(takeUntil(this.finalized$));
  }

  protected async onFinalize(): Promise<void> {
    // fulfil an interface
  }
}
