import { Component, inject, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import {
  FormArray,
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { RobotIssueService, TemplateType } from '../robot-issue.service';
import { CreateRobotIssue, RobotIssueField } from '../robot-issue.types';
import { filter, switchMap, toArray } from 'rxjs/operators';
import { from } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export type CreateRobotIssueDialogData = {
  robotId?: string;
  robotIds?: string[];
  skippable?: boolean;
  template?: TemplateType;
};

@Component({
  selector: 'app-create-robot-issue-dialog',
  templateUrl: './create-robot-issue-dialog.component.html',
  styleUrl: './create-robot-issue-dialog.component.sass',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatFormField,
    MatLabel,
    MatInput,
    MatSelect,
    MatOption,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatProgressSpinner,
    AsyncPipe,
  ],
})
export class CreateRobotIssueDialogComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly issues = inject(RobotIssueService);
  public readonly data: CreateRobotIssueDialogData = inject(MAT_DIALOG_DATA);

  template$ = this.issues.fetchTemplate(this.data?.template ?? 'generic');
  fields$ = this.template$.pipe(
    switchMap((template) => from(Object.values(template.fields))),
    filter(
      (field): field is RobotIssueField<unknown> => field?.required ?? false,
    ),
    toArray(),
  );

  form = this.formBuilder.group({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    name: ['', [Validators.required]],
    description: '',
    customFields: this.formBuilder.array([]),
  });

  constructor(
    private readonly dialogRef: MatDialogRef<CreateRobotIssueDialogComponent>,
  ) {}

  onCustomFieldChange(index: number, id: string, value: string): void {
    const customFields = this.form.controls['customFields'] as FormArray;

    const field = this.formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      id: [id, [Validators.required]],
      value: [value],
    });

    customFields.removeAt(index);
    customFields.insert(index, field);
  }

  onSubmit(): void {
    const data = this.form.value as CreateRobotIssue;
    if (this.data.robotId) {
      this.dialogRef.close(
        this.issues.create(this.data.robotId, data).toPromise(),
      );
      return;
    }

    if (this.data.robotIds && this.data.robotIds.length > 0) {
      const issuePromises = this.issues
        .createMany(this.data.robotIds, data)
        .toPromise();
      this.dialogRef.close(issuePromises);
      return;
    }

    this.dialogRef.close();
  }
}
