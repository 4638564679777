import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { MapChangeset, MapChangesetInfo } from '@cartken/map-types';
import { CdkScrollable } from '@angular/cdk/scrolling';
import {
  MatSelectionList,
  MatListOption,
  MatListItemTitle,
} from '@angular/material/list';
import { FormsModule } from '@angular/forms';

import { MatButton } from '@angular/material/button';

export interface ChangeMapVersionDialogData {
  currentVersion?: number;
  latestVersion?: number;
  deployedVersion?: number;
  changesets: MapChangesetInfo[];
}

@Component({
  selector: 'app-change-map-version-dialog',
  templateUrl: 'change-map-version-dialog.component.html',
  styleUrl: './change-map-version-dialog.component.sass',
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatSelectionList,
    FormsModule,
    MatListOption,
    MatListItemTitle,
    MatDialogActions,
    MatButton,
    MatDialogClose,
  ],
})
export class ChangeMapVersionDialogComponent {
  selectedVersions: number[] = [];

  constructor(
    public dialogRef: MatDialogRef<ChangeMapVersionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeMapVersionDialogData,
  ) {
    if (data.currentVersion !== undefined) {
      this.selectedVersions = [data.currentVersion];
    }
    data.changesets = data.changesets
      .filter(
        (
          changeset,
        ): changeset is MapChangeset & { committedAsVersion: number } =>
          changeset.committedAsVersion !== undefined,
      )
      .sort((a, b) => b.committedAsVersion - a.committedAsVersion);
  }
}
