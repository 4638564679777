<div onselectstart="return false" class="main-style">
  <p class="main-style" style="font-size: larger; font-weight: bold">
    Confirm or adjust meeting point
  </p>
  <p class="main-style" style="font-size: smaller">
    If necessary adjust desired location by dragging the marker
  </p>

  <app-leaflet-map
    class="map-component"
    (onMapReady)="onMap($event)"
    [shouldShowZoomControl]="false"
  />
  <br />
  <p style="font-weight: bold">Special needs to keep in mind:</p>
  <p>
    Our robot can not climb stairs nor steps. <br />
    Doors or gates need to be open.
  </p>
  <div style="justify-content: space-around">
    <button mat-flat-button class="confirm-button" (click)="onConfirmClick()">
      Confirm
    </button>
  </div>
</div>
