import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BackendService } from './backend.service';
import { Operation, CreateOperationData } from '../operations/operation';
import { vParsePretty } from '@/utils/valibot-parse-pretty';
import { v } from '@/valibot';
import { UpdateOperation } from '../operations/update-operation';

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  constructor(private backendService: BackendService) {}

  getOperations(): Observable<Operation[]> {
    return this.backendService
      .get('/operations')
      .pipe(
        map((operationsRaw) => vParsePretty(v.array(Operation), operationsRaw)),
      );
  }

  getOperationById(id: string): Observable<Operation> {
    return this.backendService
      .get(`/operations/${id}`)
      .pipe(map((operationRaw) => vParsePretty(Operation, operationRaw)));
  }

  createOperation({ id, ...data }: CreateOperationData): Observable<Operation> {
    return this.backendService
      .put(`/operations/${id}`, data)
      .pipe(map((operationRaw) => vParsePretty(Operation, operationRaw)));
  }

  updateOperation(id: string, update: UpdateOperation): Observable<Operation> {
    return this.backendService
      .patch(`/operations/${id}`, update)
      .pipe(map((operationRaw) => vParsePretty(Operation, operationRaw)));
  }

  getAccessGroups(): Observable<string[]> {
    return this.backendService
      .get('/operations/access-groups')
      .pipe(
        map((accessGroupsRaw) =>
          vParsePretty(v.array(v.string()), accessGroupsRaw),
        ),
      );
  }
}
