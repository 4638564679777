import { Component, OnDestroy } from '@angular/core';
import { ExpressionBrackets, needClosingBracket } from './bracket-balancing';
import { DataViewerTabName } from './data-viewer-state.service';
import { Subject, takeUntil } from 'rxjs';
import { DataViewerService } from './data-viewer.service';

import { MatInput } from '@angular/material/input';
import {
  MatAutocompleteTrigger,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';

const framePropertyNames: readonly string[] = [
  'frames.id',
  'frames.creation_timestamp',
  'frames.url',
  'frames.timestamp',
  'frames.sensor',
  'frames.location',
  'frames.snippet_id',
  'frames.metadata',
];

const snippetPropertyNames: readonly string[] = [
  'snippets.id',
  'snippets.creation_timestamp',
  'snippets.snippet_state',
  'snippets.data_split',
  'snippets.start_date',
  'snippets.end_date',
  'snippets.local_hour_of_day',
  'snippets.traveled_distance_meters',
  'snippets.movement_duration_seconds',
  'snippets.obstacle_override_duration_seconds',
  'snippets.at_intersection',
  'snippets.trajectory',
  'snippets.bag_name',
  'snippets.bag_id',
  'snippets.bag_url',
  'snippets.bag_data_version',
  'snippets.trigger_name',
  'snippets.trigger_service_info_version',
  'snippets.trigger_operator_snapshot_user_id',
  'snippets.robot_serial_number',
  'snippets.operation_id',
  'snippets.camera_intrinsics',
  'snippets.robot_jetson_container_version',
  'snippets.tile_url',
  'snippets.video_url',
  'snippets.ingress_version',
  'snippets.metadata',
];

const collectionPropertyNames: readonly string[] = [
  'collections.id',
  'collections.name',
];

const comparators: readonly string[] = ['=', '!=', '>', '<', '>=', '<='];
const logicalOperators: readonly string[] = ['AND', 'OR'];

enum ExpectedInputType {
  StartExpression,
  PropertyName,
  Comparator,
  LogicalOperator,
  LogicalOperatorClosingBracket,
  Value,
  Error,
}

function detectExpectedInput(
  tokens: string[],
  allowedPropertyNames: string[],
): ExpectedInputType {
  const lastToken = tokens.at(-1);

  if (lastToken === undefined) {
    return ExpectedInputType.StartExpression;
  }

  if (lastToken === 'NOT') {
    return ExpectedInputType.StartExpression;
  }

  if (lastToken === '(') {
    return ExpectedInputType.PropertyName;
  }

  if (allowedPropertyNames.includes(lastToken)) {
    return ExpectedInputType.Comparator;
  }

  if (comparators.includes(lastToken)) {
    return ExpectedInputType.Value;
  }

  if (logicalOperators.includes(lastToken)) {
    return ExpectedInputType.StartExpression;
  }

  const expressionBracketBalance = needClosingBracket(tokens);

  switch (expressionBracketBalance) {
    case ExpressionBrackets.Balanced:
      return ExpectedInputType.LogicalOperator;
    case ExpressionBrackets.Unbalanced:
      return ExpectedInputType.LogicalOperatorClosingBracket;
    case ExpressionBrackets.Invalid:
      return ExpectedInputType.Error;
  }
}

export function validateInput(
  input: string,
  expectedInputType: ExpectedInputType,
  allowedPropertyNames: string[],
) {
  switch (expectedInputType) {
    case ExpectedInputType.StartExpression:
      return (
        allowedPropertyNames.includes(input) || input === '(' || input === 'NOT'
      );
    case ExpectedInputType.PropertyName:
      return allowedPropertyNames.includes(input);
    case ExpectedInputType.Comparator:
      return comparators.includes(input);
    case ExpectedInputType.Value:
      return !(
        logicalOperators.includes(input) ||
        input === ')' ||
        input === '(' ||
        allowedPropertyNames.includes(input)
      );
    case ExpectedInputType.LogicalOperator:
      return logicalOperators.includes(input);
    case ExpectedInputType.LogicalOperatorClosingBracket:
      return logicalOperators.includes(input) || input === ')';
    case ExpectedInputType.Error:
      return true;
  }
}

@Component({
  selector: 'app-filter-selector',
  templateUrl: './filter-selector.component.html',
  styleUrl: './filter-selector.component.sass',
  imports: [MatInput, MatAutocompleteTrigger, MatAutocomplete, MatOption],
})
export class FilterSelectorComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  autocompleteOptions: string[] = [];
  tokens: string[] = [];

  currentInput = '';

  placeholder = 'Enter property name';

  errorMessage?: string = undefined;

  allowedPropertyNames: string[] = [];

  constructor(private dataViewerService: DataViewerService) {
    this.dataViewerService.selectedDataViewerTab$
      .pipe(takeUntil(this._destroy$))
      .subscribe((tabName) => {
        this.selectAllowedPropertyNames(tabName);
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  private selectAllowedPropertyNames(tabNameSelected: DataViewerTabName) {
    switch (tabNameSelected) {
      case 'frames':
        this.allowedPropertyNames = [
          ...snippetPropertyNames,
          ...framePropertyNames,
          ...collectionPropertyNames,
        ];
        break;
      case 'snippets':
        this.allowedPropertyNames = [...snippetPropertyNames];
        break;
    }
    this.tokens = [];
    this.currentInput = '';
    this.dataViewerService.setSearchString('');
  }

  onInput(newValue: string) {
    setTimeout(() => {
      this.currentInput = newValue;
    });

    const expectedInputType = detectExpectedInput(
      this.tokens,
      this.allowedPropertyNames,
    );

    const startWith = (input: string) => {
      return newValue === '' || input.startsWith(newValue);
    };

    this.errorMessage = undefined;

    switch (expectedInputType) {
      case ExpectedInputType.PropertyName:
        this.autocompleteOptions = this.allowedPropertyNames.filter(
          startWith,
          this.allowedPropertyNames,
        );
        this.placeholder = 'Enter property name';
        this.dataViewerService.setSearchString(this.tokens.join(' '));
        break;
      case ExpectedInputType.Comparator:
        this.autocompleteOptions = comparators.filter(startWith);
        this.placeholder = 'Enter comparator';
        break;
      case ExpectedInputType.Value:
        this.autocompleteOptions = [];
        this.placeholder = 'Enter value';
        break;
      case ExpectedInputType.LogicalOperator:
        this.autocompleteOptions = [...logicalOperators];
        this.placeholder = 'Enter logical operator';
        this.dataViewerService.setSearchString(this.tokens.join(' '));
        break;
      case ExpectedInputType.LogicalOperatorClosingBracket:
        this.autocompleteOptions = [...logicalOperators, ')'];
        this.placeholder = 'Enter logical operator or closing bracket';
        break;
      case ExpectedInputType.Error:
        this.autocompleteOptions = [];
        this.placeholder = 'Error';
        this.errorMessage = 'Invalid input';
        break;
      case ExpectedInputType.StartExpression:
        this.autocompleteOptions = [
          '(',
          'NOT',
          ...this.allowedPropertyNames,
        ].filter(startWith);
        this.placeholder = 'Enter property name or opening bracket';
        break;
    }
  }

  applyInput() {
    if (this.currentInput === '') {
      this.dataViewerService.setSearchString(this.tokens.join(' '));
      return;
    }

    const expectedInputType = detectExpectedInput(
      this.tokens,
      this.allowedPropertyNames,
    );

    if (
      validateInput(
        this.currentInput,
        expectedInputType,
        this.allowedPropertyNames,
      )
    ) {
      if (expectedInputType === ExpectedInputType.Value) {
        this.tokens.push(`'${this.currentInput}'`);
      } else {
        this.tokens.push(this.currentInput);
      }
      this.onInput('');
    } else {
      switch (expectedInputType) {
        case ExpectedInputType.PropertyName:
          this.errorMessage = 'Invalid input, expected property name';
          break;
        case ExpectedInputType.Comparator:
          this.errorMessage = 'Invalid input, expected comparator';
          break;
        case ExpectedInputType.Value:
          this.errorMessage = 'Invalid input, expected value';
          break;
        case ExpectedInputType.LogicalOperator:
          this.errorMessage = 'Invalid input, expected logical operator';
          break;
        case ExpectedInputType.LogicalOperatorClosingBracket:
          this.errorMessage =
            'Invalid input, expected logical operator or closing bracket';
          break;
        case ExpectedInputType.Error:
          this.errorMessage = 'Invalid input, expected input';
          break;
        case ExpectedInputType.StartExpression:
          this.errorMessage =
            'Invalid input, expected property name or opening bracket';
          break;
      }
    }
  }

  removeLastToken() {
    if (this.currentInput.length === 0) {
      const lestToken = this.tokens.pop();
      this.currentInput = lestToken ?? '';
      this.tokens = [...this.tokens];
    }
  }
}
