import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BackendService } from '@/app/core/backend.service';
import {
  ComponentVersion,
  Robot,
} from '@/app/core/robots-service/backend/robot.dto';
import { firstValueFrom, merge, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CreateRobotDialogComponent } from '../create-robot-dialog/create-robot-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@/app/core/auth.service';
import { Role } from '@/app/core/user';
import { EditRobotDialogComponent } from '../edit-robot-dialog/edit-robot-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
  MatFooterCellDef,
  MatFooterCell,
  MatFooterRowDef,
  MatFooterRow,
} from '@angular/material/table';
import { UpdateRobot } from '../edit-robot-dialog/update-robot';
import { visiblePageTimer } from '@/app/../utils/page-visibility';
import {
  MassActionData,
  MassActionDialogComponent,
} from './mass-action-dialog.component';
import {
  ConfirmationDialogData,
  ConfirmationDialog,
} from '@/app/core/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { robotStatusFromIssueSeverity } from '@/app/core/robot-issues/robot-issues.utils';
import {
  compareIssueSeverity,
  RobotIssueSeverity,
} from '@/app/core/robot-issues/robot-issue.types';
import { ClaimRobotDialogComponent } from './claim-robot-dialog/claim-robot-dialog.component';
import { RobotUpdateHistoryDialogComponent } from './robot-update-history-dialog/robot-update-history-dialog.component';
import {
  DrivingFilter,
  Filter,
  ReadyFilter,
  UpdatingFilter,
  RobotFiltersComponent,
} from './robot-filters.component';
import { prettyTime, PrettyTimePipe } from '@/app/core/pipes/pretty-time.pipe';
import { ToolbarComponent } from '@/app/core/toolbar/toolbar.component';
import { NgStyle } from '@angular/common';
import { MatMenuItem } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  MatButton,
  MatIconButton,
  MatIconAnchor,
} from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatCheckbox } from '@angular/material/checkbox';
import { RobotIssuesPopoverComponent } from '@/app/core/robot-issues/robot-issues-popover/robot-issues-popover.component';
import { BatteryStatusComponent } from '@/app/core/battery-status/battery-status.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { ConnectivityStatusComponent } from '@/app/core/connectivity-status/connectivity-status.component';
import { LengthPipe } from '@/app/core/pipes/length.pipe';
import { isOnline } from '@/app/robots/robot-operation/robot-operator-view/robot-quick-add-dialog.component';
import { RobotsBackendService } from '@/app/core/robots-service/robots-backend.service';
import { OperationsService } from '@/app/core/operations-service';

function buildVersionString({
  majorVersion: major = 0,
  minorVersion: minor = 0,
  patchVersion: patch = 0,
  versionAppendix: appendix,
}: ComponentVersion): string {
  return `${major}.${minor}.${patch}${appendix ? `-${appendix}` : ''}`;
}

function getUpdateIconName(
  robot: Robot,
): 'downloading' | 'download_done' | 'error' | undefined {
  if (robot.awxUpdateActive) {
    return 'downloading';
  }
  if (robot.lastAwxUpdateSuccessful === undefined) {
    return undefined;
  }
  return robot.lastAwxUpdateSuccessful ? 'download_done' : 'error';
}

function awxUpdateSortOrder(robot: Robot) {
  if (robot.awxUpdateActive) {
    return 0;
  }
  if (robot.lastAwxUpdateSuccessful === undefined) {
    return 3;
  }
  return robot.lastAwxUpdateSuccessful ? 1 : 2;
}

function compareRobotsByAwxUpdate(a: Robot, b: Robot) {
  return awxUpdateSortOrder(a) - awxUpdateSortOrder(b);
}

function compareRobotsByIssueSeverity(a: Robot, b: Robot) {
  const severityCmp = compareIssueSeverity(
    a.highestIssueSeverity,
    b.highestIssueSeverity,
  );
  const aIssueCount = a.issues?.length ?? 0;
  const bIssueCount = b.issues?.length ?? 0;
  const issuesCmp = aIssueCount - bIssueCount;
  const comparison = severityCmp !== 0 ? severityCmp : issuesCmp;
  return comparison;
}

const ERROR_SNACK_BAR_DURATION = 2000;

const defaultColumnsToDisplay = [
  'select',
  'picture',
  'robotView',
  'status',
  'batteryPercentage',
  'connectivity',
  'readyForOrders',
  'serialNumber',
  'assignedOperationId',
  'updatedAt',
];

const adminColumnsToDisplay = [
  ...defaultColumnsToDisplay,
  'awxUpdate',
  'containerVersions',
  'action-buttons',
];

@Component({
  selector: 'app-robots-view',
  templateUrl: './robots-view.component.html',
  styleUrl: './robots-view.component.sass',
  imports: [
    ToolbarComponent,
    MatMenuItem,
    MatProgressSpinner,
    RobotFiltersComponent,
    MatButton,
    MatIcon,
    MatTooltip,
    MatPaginator,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCheckbox,
    MatCellDef,
    MatCell,
    NgStyle,
    MatSortHeader,
    RobotIssuesPopoverComponent,
    BatteryStatusComponent,
    MatSlideToggle,
    FormsModule,
    MatIconButton,
    ConnectivityStatusComponent,
    MatIconAnchor,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatFooterCellDef,
    MatFooterCell,
    MatFooterRowDef,
    MatFooterRow,
    PrettyTimePipe,
    LengthPipe,
  ],
})
export class RobotsViewComponent implements AfterViewInit, OnDestroy {
  isAdmin = false;
  canSuperviseRobots = false;
  dataSource = new MatTableDataSource<Robot>([]);
  private destroyed$ = new Subject<void>();
  operationIds: string[] = [];
  accessGroups: string[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('tableScrollContainer') tableScrollContainer!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;

  private refresh$ = new Subject<void>();

  columnsToDisplay = defaultColumnsToDisplay;
  selection = new SelectionModel<string>(true, []);

  constructor(
    private backendService: BackendService,
    private operationsService: OperationsService,
    private robotsBackendService: RobotsBackendService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private router: Router,
  ) {
    this.auth.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.isAdmin = user?.roles.includes(Role.ADMIN) ?? false;
      this.canSuperviseRobots =
        (this.isAdmin || user?.roles.includes(Role.ROBOT_OPERATOR)) ?? false;
      this.columnsToDisplay = this.isAdmin
        ? adminColumnsToDisplay
        : defaultColumnsToDisplay;
    });
    this.operationsService
      .getOperations()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((operations) => {
        this.operationIds = operations.map((operation) => operation.id);
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const superSortData = this.dataSource.sortData;

    this.dataSource.sortData = (data, sort) => {
      const dir = sort.direction === 'asc' ? 1 : -1;
      if (sort.active === 'status') {
        return data.sort((a, b) => dir * compareRobotsByIssueSeverity(a, b));
      }
      if (sort.active === 'awxUpdate') {
        return data.sort((a, b) => dir * compareRobotsByAwxUpdate(a, b));
      }
      return superSortData(data, sort);
    };

    const matches = (value: string | undefined, pattern: string): boolean =>
      !!value && value.trim().toLowerCase().includes(pattern);

    this.dataSource.filterPredicate = (
      robot: Robot,
      filterJson: string,
    ): boolean => {
      const filter = JSON.parse(filterJson) as Filter;
      const textFilterMatch =
        matches(`${robot.serialNumber}`, filter.text) ||
        matches(robot.shortName, filter.text) ||
        matches(robot.assignedOperationId, filter.text) ||
        this.getContainerVersionsString(robot.componentVersions).some((s) =>
          matches(s, filter.text),
        );
      const chargeFilterMatch =
        !filter.charging ||
        (filter.charging === 'charging') === robot.isCharging;
      const robotOnline = isOnline(Date.now())(robot);
      const onlineFilterMatch =
        !filter.online || (filter.online === 'online') === robotOnline;
      const isRobotReady: ReadyFilter =
        robot.readyForOrders === true ? 'ready' : 'not-ready';
      const isReadyFilterMatch = !filter.ready || isRobotReady === filter.ready;
      const isRobotDriving: DrivingFilter =
        robot.arrivedAtStop === false ? 'driving' : 'arrived';
      const isDrivingFilterMatch =
        !filter.driving || isRobotDriving === filter.driving;
      const isUpdating: UpdatingFilter =
        robot.awxUpdateActive === true ? 'updating' : 'not-updating';
      const isUpdatingFilterMatch =
        !filter.updating || isUpdating === filter.updating;
      const isOperationIdMatch =
        !filter.operationId || robot.assignedOperationId === filter.operationId;
      const isAccessGroupMatch =
        !filter.accessGroup || robot.accessGroups.includes(filter.accessGroup);
      const isSeverityMatch =
        !filter.severity ||
        robot.highestIssueSeverity === filter.severity ||
        (filter.severity === 'NotBreaking' &&
          robot.highestIssueSeverity !== RobotIssueSeverity.BREAKING);

      return (
        textFilterMatch &&
        chargeFilterMatch &&
        onlineFilterMatch &&
        isReadyFilterMatch &&
        isDrivingFilterMatch &&
        isUpdatingFilterMatch &&
        isOperationIdMatch &&
        isAccessGroupMatch &&
        isSeverityMatch
      );
    };

    merge(visiblePageTimer(0, 10000), this.refresh$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async () => await this.pullData());
  }

  ngOnDestroy() {
    this.destroyed$.next(undefined);
  }

  async pullData() {
    const robots = await firstValueFrom(this.robotsBackendService.getRobots());

    // Sort the currently online robots by serial number to stop constant
    // reordering. This is done by assigning "now" - displayName-sorted
    // array index position to updatedAt.
    robots.sort((a, b) => a.serialNumber - b.serialNumber);

    const accessGroupSet = new Set<string>(
      robots.flatMap((robot) => robot.accessGroups ?? ['']),
    );
    this.accessGroups = Array.from(accessGroupSet.values());

    const now = new Date();
    this.dataSource.data = robots.map((robot, index) => {
      let updatedAt = robot.updatedAt;
      // keep sorting stable for robots which are "online"
      if (isOnline(now.getTime())(robot)) {
        updatedAt = new Date(now.getTime() - index);
      }
      const serialNumberString = String(robot.serialNumber);
      return {
        ...robot,
        renderedName:
          robot.shortName === undefined ||
          serialNumberString === robot.shortName
            ? serialNumberString
            : `${serialNumberString} (${robot.shortName})`,
        updatedAt,
        updatedAtTooltip:
          // only show tooltip if 'time+date' version differs from 'date'
          prettyTime(updatedAt, 'time+date') !== prettyTime(updatedAt, 'date')
            ? prettyTime(updatedAt, 'time+date')
            : undefined,
        isClaimed:
          robot.userClaim &&
          robot.userClaim.claimedBy &&
          (!robot.userClaim.claimedUntil ||
            new Date(robot.userClaim.claimedUntil) > now),
        updateIcon: getUpdateIconName(robot),
      };
    });

    const operations = await firstValueFrom(
      this.operationsService.getOperations(),
    );

    this.operationIds = operations
      .map((operation) => operation.id)
      .filter(
        (operationId) =>
          robots.findIndex(
            (robot) => robot.assignedOperationId === operationId,
          ) !== -1,
      );
  }

  transform(image: Buffer) {
    return this.sanitizer.bypassSecurityTrustUrl(
      'data:image/jpeg;base64,' + image.toString('base64'),
    );
  }

  pushRobotChanges(robotId: string, updateRobot: UpdateRobot) {
    this.backendService
      .patch(`/robots/${robotId}`, updateRobot)
      .subscribe(() => this.pullData());
  }

  createRobot(): void {
    this.dialog
      .open(CreateRobotDialogComponent, {
        data: {
          type: 'cart',
        },
      })
      .afterClosed()
      .subscribe(async (newRobot?: Promise<Robot>) => {
        if (!newRobot) return;
        await newRobot;
        this.pullData();
      });
  }

  createVirtualRobot(): void {
    this.dialog
      .open(CreateRobotDialogComponent, {
        data: {
          type: 'virtual',
        },
      })
      .afterClosed()
      .subscribe(async (newRobot?: Promise<Robot>) => {
        if (!newRobot) return;
        await newRobot;
        this.pullData();
      });
  }

  editRobot(robot: Robot) {
    this.operationsService.getOperations().subscribe((operations) => {
      const editRobotDialogRef = this.dialog.open(EditRobotDialogComponent, {
        minWidth: '80vh',
        data: {
          robot: structuredClone(robot),
          operations,
        },
      });
      editRobotDialogRef
        .afterClosed()
        .subscribe((updateRobot?: UpdateRobot) => {
          if (!updateRobot) {
            this.pullData();
            return;
          }
          this.pushRobotChanges(robot.id, updateRobot);
        });
    });
  }

  showRobotBlackbox(robot: Robot) {
    this.router.navigate(['robots/blackbox', robot.id]);
  }

  isAllFilteredSelected() {
    if (!this.dataSource.filteredData.length) {
      return false;
    }
    return this.dataSource.filteredData.every((robot) =>
      this.selection.selected.includes(robot.id),
    );
  }

  filteredAndSelectedRobot(): Robot[] {
    const selectedRobots = new Set(this.selection.selected);
    return this.dataSource.filteredData.filter((robot) => {
      return selectedRobots.has(robot.id);
    });
  }

  toggleFilteredSelection() {
    const allFilteredSelected = this.isAllFilteredSelected();
    this.selection.clear();

    if (allFilteredSelected) {
      return;
    }
    this.selection.select(
      ...this.dataSource.filteredData.map((robot) => robot.id),
    );
  }

  async superviseRobots() {
    const robotIds = this.filteredAndSelectedRobot().map((robot) => robot.id);

    if (robotIds.length === 1) {
      const robotId = robotIds[0]!;
      await this.router.navigateByUrl(
        `/robots/supervise/${robotId}?active=${robotId}`,
      );
    } else {
      const robotIdsStr = robotIds.join(',');
      await this.router.navigate(['/robots/supervise/', robotIdsStr]);
    }
  }

  applyFilter(filter: Filter) {
    this.dataSource.filter = JSON.stringify(filter);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
      this.scrollRobotTableUp();
    }
  }

  extractRobotStatus(robot: Robot): string {
    return robotStatusFromIssueSeverity(robot.highestIssueSeverity);
  }

  scrollRobotTableUp() {
    this.tableScrollContainer.nativeElement.scrollTo({ top: 0 });
  }

  getFrontendVersionUrlPart(): string {
    const currentUrl = document.location.href;

    if (currentUrl.includes('staging-ops')) {
      return 'staging-';
    } else if (currentUrl.includes('sandbox-ops')) {
      return 'sandbox-';
    } else {
      return '';
    }
  }

  openMassAction(): void {
    this.dialog.open<MassActionDialogComponent, MassActionData>(
      MassActionDialogComponent,
      {
        data: {
          selectedRobots: this.filteredAndSelectedRobot(),
          updateState: () => {
            this.refresh$.next(undefined);
          },
        },
      },
    );
  }

  toggleRobotReadyForOrder(robot: Robot) {
    const isReady = !robot.readyForOrders;
    const robotName = robot.serialNumber;
    const confirmationDialogMessage = isReady
      ? `Set robot ${robotName} ready for order`
      : `Set robot ${robotName} not ready for order`;

    this.dialog
      .open<ConfirmationDialog, ConfirmationDialogData>(ConfirmationDialog, {
        data: {
          message: confirmationDialogMessage,
        },
      })
      .afterClosed()
      .subscribe(async (isConfirmed) => {
        if (isConfirmed === true) {
          try {
            await firstValueFrom(
              this.backendService.post(
                `/robots/${robot.id}/set-ready-for-orders`,
                {
                  readyForOrders: isReady,
                },
              ),
            );
          } catch (e) {
            const errorMessage = `Failed to set ${robotName} ready for orders state to '${isReady}'`;
            console.error(errorMessage, e);
            this.snackBar.open(errorMessage, undefined, {
              duration: ERROR_SNACK_BAR_DURATION,
            });
            this.refresh$.next();
          }
        } else {
          this.refresh$.next();
        }
      });
  }

  openClaimRobotDialog(robot: Robot) {
    const handle = this.dialog.open<ClaimRobotDialogComponent>(
      ClaimRobotDialogComponent,
      {
        data: {
          robot,
        },
      },
    );

    handle.afterClosed().subscribe(async () => {
      this.refresh$.next();
    });
  }

  openRobotAwxUpdateHistory(robot: Robot) {
    const handle = this.dialog.open<RobotUpdateHistoryDialogComponent>(
      RobotUpdateHistoryDialogComponent,
      {
        data: {
          robotId: robot.id,
        },

        autoFocus: false,
        height: '80vh',
        maxHeight: '80vh',
      },
    );

    handle.afterClosed().subscribe(async () => {
      this.refresh$.next();
    });
  }

  getContainerVersionsString(componentVersions: ComponentVersion[]): string[] {
    const containerVersions = componentVersions.filter((componentVersion) =>
      componentVersion.componentName?.includes('container'),
    );

    const containerVersionsToComponentNamesMap = new Map<string, string[]>();
    for (const containerVersion of containerVersions) {
      const versionString = buildVersionString(containerVersion);

      const componentNamesForVersion =
        containerVersionsToComponentNamesMap.get(versionString);
      if (componentNamesForVersion !== undefined) {
        componentNamesForVersion.push(containerVersion.componentName ?? '');
      } else {
        containerVersionsToComponentNamesMap.set(versionString, [
          containerVersion.componentName ?? '',
        ]);
      }
    }

    // Only visualize version if:
    // - more than two different container versions have been reported
    // - all of the containers are using the same version
    // - no devel container is running
    if (containerVersionsToComponentNamesMap.size === 1) {
      const [containerComponentNames] =
        containerVersionsToComponentNamesMap.values();
      if (
        containerComponentNames &&
        !containerComponentNames.some((name) => name.includes('devel'))
      ) {
        return Array.from(containerVersionsToComponentNamesMap.keys()); //only one entry
      }
    }

    const containerVersionStrings: string[] = [];
    Array.from(containerVersionsToComponentNamesMap.entries()).forEach(
      ([versionString, componentNames]) =>
        componentNames.forEach((componentName) =>
          containerVersionStrings.push(
            `${componentName ?? ''}: ${versionString ?? ''}`,
          ),
        ),
    );
    return containerVersionStrings.sort((a, b) => a.localeCompare(b));
  }

  trackByRobot(index: number, robot: Robot) {
    return robot.id;
  }

  getElasticRobotLogLink(serialNumber: number) {
    const displayNameUrl = `%20%22Cart%20${serialNumber}%22`;
    return `https://robot-logs.kb.europe-west3.gcp.cloud.es.io:9243/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&_a=(columns:!(),filters:!(),index:'23c15690-cfca-11ed-8cb8-0dc8a99b65a1',interval:auto,query:(language:kuery,query:'robotDisplayName.keyword%20:%20${displayNameUrl}'),sort:!(!('@timestamp',desc)))`;
  }
}
