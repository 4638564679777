import {
  Component,
  computed,
  effect,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';

import { ManualRobotControl } from '@/app/core/robots-service/webrtc/types';
import { RobotCanvas } from './robot-canvas';
import { Robot } from '@/app/core/robots-service/backend/robot.dto';
import { vec2 } from '@tlaukkan/tsm';
import {
  MouseCanvasDriveEventManager,
  MouseDriveEvent,
} from '../common/mouse-canvas-events';
import { RobotSystemStatus } from '@/app/core/robots-service/backend/types';
import { onAnimationFrame } from '@/utils/on-animation-frame';

function onMouseMove(fn: (mouseEvent: MouseEvent) => void) {
  effect((onCleanup) => {
    document.addEventListener('mousemove', fn);
    onCleanup(() => {
      document.removeEventListener('mousemove', fn);
    });
  });
}

export type PathCorridor = {
  action: 'add' | 'reset';
  groundPoint: [number, number];
  width: number;
};

@Component({
  selector: 'app-robot-video',
  templateUrl: './robot-video.component.html',
  styleUrl: './robot-video.component.sass',
  host: {
    '[style.--aspect-ratio]': 'videoAspectRatio()',
  },
})
export class RobotVideoComponent {
  readonly active = input.required<boolean | undefined>();
  readonly manualMouseControl = input.required<boolean | undefined>();
  readonly attentionStatusList = input.required<RobotSystemStatus[]>();
  readonly manualControl = input.required<ManualRobotControl | undefined>();
  readonly robotState = input.required<Robot | undefined>();
  readonly robotAttentionReport = input.required<string | undefined>();
  readonly video = input.required<HTMLVideoElement>();

  videoAspectRatio() {
    const { videoWidth, videoHeight } = this.video();
    if (videoWidth * videoHeight === 0) {
      return `unset`;
    }
    return `${videoWidth.toFixed(0)} / ${videoHeight.toFixed(0)}`;
  }

  readonly mouseDriveEvent = output<MouseDriveEvent>();
  readonly lastMouseRelativePosition = output<vec2>();

  private canvasElement = viewChild<ElementRef<HTMLCanvasElement>>('canvas');
  private robotCanvas = computed(() => {
    const canvas = this.canvasElement()?.nativeElement;
    return canvas && new RobotCanvas(canvas, this.video());
  });

  private mouseCanvasDriveEvent = computed(() => {
    const canvas = this.canvasElement()?.nativeElement;
    return (
      canvas && new MouseCanvasDriveEventManager(canvas, this.mouseDriveEvent)
    );
  });

  constructor() {
    this.subscribeToEvents();
    this.startRenderingLoop();

    effect((onCleanup) => {
      const robotCanvas = this.robotCanvas();
      onCleanup(() => {
        robotCanvas?.destroy();
      });
    });

    effect((onCleanup) => {
      const mouseCanvasDriveEvent = this.mouseCanvasDriveEvent();
      onCleanup(() => {
        mouseCanvasDriveEvent?.destroy();
      });
    });
  }

  private subscribeToEvents() {
    onMouseMove((mouseEvent: MouseEvent) => {
      const canvas = this.canvasElement()?.nativeElement;
      if (!canvas) {
        return;
      }
      const boundingRect = canvas.getBoundingClientRect();

      this.lastMouseRelativePosition.emit(
        new vec2([
          (mouseEvent.clientX - boundingRect.x) / boundingRect.width,
          (mouseEvent.clientY - boundingRect.y) / boundingRect.height,
        ]),
      );
    });
  }

  private startRenderingLoop() {
    onAnimationFrame(() => {
      const name = `Cart ${this.robotState()?.serialNumber}`;
      const attentionStatuses = this.attentionStatusList
        ? [...this.attentionStatusList()]
        : [];
      const attentionReport = this.robotAttentionReport();
      if (attentionReport) {
        attentionStatuses.push({
          name,
          message: attentionReport,
        });
      }
      this.updateCanvas();
    });
  }

  private updateCanvas() {
    const video = this.video();
    const mouseCanvasDriveEvent = this.mouseCanvasDriveEvent();
    const robotCanvas = this.robotCanvas();
    if (!robotCanvas || !video || !mouseCanvasDriveEvent) {
      return;
    }

    robotCanvas.draw(
      this.active() ?? false,
      this.manualMouseControl() ?? false,
      mouseCanvasDriveEvent.mouseMove,
    );
  }
}
