import { Component, EventEmitter, Output } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-collection-name-input',
  templateUrl: './collection-name-input.component.html',
  styleUrl: './collection-name-input.component.sass',
  imports: [MatInput, MatIcon],
})
export class CollectionNameInputComponent {
  @Output()
  closeInput = new EventEmitter<void>();

  @Output()
  createCollection = new EventEmitter<string>();

  private inputCollectionName = '';

  onInput(value: string) {
    this.inputCollectionName = value;
  }

  emitCreateCollection() {
    this.createCollection.emit(this.inputCollectionName);
  }
}
