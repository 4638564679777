import { Pipe, PipeTransform } from '@angular/core';
import { ViewName } from '../core/user-session/user-session.utils';

@Pipe({ name: 'viewName' })
export class ViewNamePipe implements PipeTransform {
  transform(value: ViewName): string {
    switch (value) {
      case ViewName.MANAGED_SUPERVISION:
        return 'Supervision';
      case ViewName.CLASSIC_SUPERVISION:
        return 'Maintenance';
      case ViewName.FOCUSED_SUPERVISION:
        return 'Focused';
      default:
        return '';
    }
  }
}
