import { Component } from '@angular/core';
import { OperationType, RobotQueueEdgeHandover } from '../operation';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { sortHandovers } from '../utils';
import { MatToolbar } from '@angular/material/toolbar';
import { HandoverLocationTitleSplitterComponent } from '../handover-location-view/handover-location-title-splitter.component';
import { MatButton } from '@angular/material/button';
import { OperationsService } from '@/app/core/operations-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-handover-location-selection-view',
  templateUrl: './handover-location-selection-view.component.html',
  styleUrl: './handover-location-selection-view.component.sass',
  imports: [
    MatToolbar,
    HandoverLocationTitleSplitterComponent,
    MatButton,
    RouterLink,
  ],
})
export class HandoverControlSelectionViewComponent {
  operationDisplayName?: string;
  operationId?: string;

  pickups: RobotQueueEdgeHandover[] = [];

  dropoffs: RobotQueueEdgeHandover[] = [];

  constructor(
    private operationsService: OperationsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {
    this.route.paramMap.subscribe((params) => {
      const operationId = params.get('operation-id');
      if (!operationId) {
        console.warn(`Unexpected missing operation-id in route params.`);
        return;
      }
      this.refreshOperation(operationId);
    });
  }

  private refreshOperation(operationId: string) {
    this.operationId = operationId;
    this.operationsService.getOperationById(operationId).subscribe({
      next: (operation) => {
        if (operation.operationType !== OperationType.OrderOperation) {
          this.snackBar.open(
            `Operation with name '${operationId}' is not an order operation, redirecting.`,
            undefined,
            { duration: 4000 },
          );
          this.router.navigate(['/operations/']);
          return;
        }
        this.operationDisplayName = operation.displayName ?? operation.id;
        this.pickups = (operation.operationData?.pickups || [])
          .sort(sortHandovers)
          .filter((p) => p.displayName ?? p.name);
        this.dropoffs = (operation.operationData?.dropoffs || [])
          .sort(sortHandovers)
          .filter((p) => p.displayName ?? p.name);
      },
      error: () => {
        console.warn(
          `Operation with name '${operationId}' could not be retrieved, redirecting.`,
        );
        this.router.navigate(['']);
      },
    });
  }
}
