// order important, this is how they appear in UI
export const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export type Weekday = (typeof weekdays)[number];

export function weekdayDisplayName(weekday: Weekday) {
  return weekday[0]!.toUpperCase() + weekday.slice(1);
}

const dayIndexToWeekday: Record<number, Weekday> = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export function getWeekday(day: number) {
  const weekday = dayIndexToWeekday[day];
  if (!weekday) {
    throw new Error(`Day index was incorrect, got ${day}.`);
  }
  return weekday;
}
