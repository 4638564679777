import { Component, computed, input } from '@angular/core';
import { RobotIssue, RobotIssueSeverity } from '../robot-issue.types';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-robot-issues-widget',
  templateUrl: './robot-issues-widget.component.html',
  styleUrl: './robot-issues-widget.component.sass',
  imports: [MatIcon],
})
export class RobotIssuesWidgetComponent {
  issues = input.required<RobotIssue[]>();
  breakingIssues = computed(() =>
    this.issues().filter((i) => i.severity === RobotIssueSeverity.BREAKING),
  );
}
